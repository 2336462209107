import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type MoneyBlackProps = React.HTMLAttributes<HTMLDivElement> & {};

export const MoneyBlack: React.FC<MoneyBlackProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/money_black.svg'} alt='Alarm' />
        </div>
    )
}