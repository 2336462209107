import React from 'react';
import './ProgressCircle.css';

interface ProgressCircleProps {
    sqSize: number; // Tamaño del cuadrado que contiene el círculo
    size?: number; // Tamaño del círculo
    strokeWidth?: number; // Ancho del trazo del círculo
    gradientId?: string; // ID del gradiente
    backgroundColor?: string; // Color de fondo del círculo
    percentage: number; // Porcentaje de progreso
    textPercentage: number; // Texto que se muestra en el centro del círculo
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({
    sqSize,
    size,
    strokeWidth = 10,
    percentage = 25,
    textPercentage = 10,
    gradientId = 'progress-gradient',
    backgroundColor = '#e0e0e0'
}) => {
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (sqSize - strokeWidth) / 2;
    // Enclose circle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - (dashArray * percentage) / 100;

    return (
      <svg width={'100%'} height={'100%'} viewBox={viewBox} style={{maxWidth:sqSize, maxHeight:sqSize}}>
        <defs>
          <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="56.54%" stopColor="#F7E246" />
            <stop offset="74.51%" stopColor="#FFC525" />
          </linearGradient>
        </defs>
        <circle
          className="circle-background"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          stroke={backgroundColor}
        />
        <circle
          className="circle-progress"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          stroke={`url(#${gradientId})`}
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset,
          }}
        />
        <text
          className="big-subheader circle-text"
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle"
        >
          {`${textPercentage}%`}
        </text>
      </svg>
    );
};

export default ProgressCircle;