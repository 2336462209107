import React from "react";

/** importando componentes */
import { Dropdown } from "../Dropdown/Dropdown";
import { UploadText } from "../UploadFile/UpdateText/UpdateText";

/** importando iconos */

import { DocsFileValidation, SpreadSheetFileValidation, PDFFileValidation, ImageFileValidation } from "../../Consts/FilesValidation";

/** importando interfaces */
import { TransportInterface } from "../../interfaces/TransportInterface";

/** importando utilidades */
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";

/** importando estilos */
import './QuoteTransportData.css';
import { TextField } from "../TextField/TextField";
import { Textarea } from "../Textarea/Textarea";
import { TextAreaInput } from "../TextAreaInput/TextAreaInput";


interface QuoteTransportDataProps {
    transport: TransportInterface | undefined,
    fileValue: any[];
    handleFileValue: React.Dispatch<React.SetStateAction<any[]>>;
    isCourier?: boolean;
}

export const QuoteTransportData: React.FC<QuoteTransportDataProps> = ({
    transport={
        origin: {
            city: '',
            country: '',
            port: '',
            alpha2Code: '',
            imgFlag: '',
            name: '',
            portIsoCode: '',
            airport:'',
            type:''
        },
        type: ['air', 'sea', 'land'],
        destination: {
            city: '',
            country: '',
            port: '',
            alpha2Code: '',
            imgFlag: '',
            name: '',
            airport:'',
            type:'',
            portIsoCode: ''
        },
        finalDelivery: '',
        initDelivery: '',
        typeContainer: {
            lcl: false,
            fcl: false,
            lclAir: false
        },
        lcl: {
            totalWeight: 0,
            unitWeight: '',
            totalVolume: 0,
            unitVolume: '',
            cargoDescription: '',
            stackable: '',
            packingList: {
                name: '',
                url: ''
            },
            packages: [],
        },
        lclAir: {
            cargoDescription: "",
            weight: undefined,
            unitWeight: "kg",
            volume: undefined,
            unitVolume: "Metros cúbicos",
            packages: [],
            packingType: ""
        },
        fcl: [],
        warehouseOrigin: "",
        warehouseDestination: ""
    },
    fileValue,
    handleFileValue,
    isCourier=false
}) => {

    return (
        <div role='quoteTransportData-role' className="new-quote-transport">
            <div className="smalltext-header new-quote-transport-title">Transporte</div>
            <div className="new-quote-transport-row__2">
                <TextField 
                    title="Medio de transporte" 
                    placeholder=" " 
                    value={transport.type === 'sea' ? 'Marítimo' : transport.type === 'air' ? 'Aéreo' : 'Terrestre'} 
                    disabled 
                />
                <TextAreaInput 
                    title={isCourier ? "Almacén de origen" : (transport.origin?.type === "PORT" ? "Puerto" : transport.origin?.type === "AIRPORT" ? "Aeropuerto" : "Ciudad")+" de origen"}
                    value={isCourier ? transport.warehouseOrigin : (transport.origin?.type === "PORT" ? transport.origin.name + " - " + transport.origin.portIsoCode+'': transport.origin?.type === "AIRPORT" ? transport.origin.airport : transport.origin?.city+", "+transport.origin?.country)}
                />
                <TextAreaInput 
                   title={isCourier ? "Almacén de destino" : (transport.destination?.type === "PORT" ? "Puerto" : transport.destination?.type === "AIRPORT" ? "Aeropuerto" : "Ciudad")+" de destino"}
                   value={isCourier ? transport.warehouseOrigin : (transport.destination?.type === "PORT" ? transport.destination.name + " - " + transport.destination.portIsoCode+'': transport.destination?.type === "AIRPORT" ? transport.destination.airport : transport.destination?.city+", "+transport.destination?.country)}
                     
                />
            </div>

            
            {transport.initDelivery &&
            <div className="new-quote-transport-row__2">
                { transport.initDelivery && 
                    <TextAreaInput
                        title="Dirección de recojo"
                        value={transport.initDelivery?transport.initDelivery:''}
                    
                />}

                { transport.finalDelivery && 
                    <TextAreaInput 
                        title="Dirección de entrega"
                        value={transport.finalDelivery?transport.finalDelivery:''} 
                />}
            </div>}


            {transport.typeContainer?.lcl && <div className="smalltext-header new-quote-transport-title new-quote-transport-title__LCL">LCL</div>}
            {/* {transport.typeContainer?.lcl&& <div className="new-quote-transport-row__2">
               
                {/* <Dropdown title="¿Es apilable?" 
                          value={transport.lcl?.stackable+""} 
                          elements={[transport.lcl?.stackable+""]} 
                          setValue={()=>{}}
                          disabled/> */}
            {/* </div> */}

            {transport.typeContainer?.lcl && <div className="new-quote-transport-row__">
                <div className="new-quote-transport-row__dropdown">
                    <Dropdown 
                        title={'Tipo de embalaje'}
                        value={transport.lcl?.cargoDescription+""} 
                        elements={[transport.lcl?.cargoDescription+""]} 
                        setValue={()=>{}}
                        disabled
                    />
                </div>

                <TextField 
                    title="Peso total" 
                    placeholder=" "  
                    value={ConverMoneyFormat(Number(transport.lcl?.totalWeight).toString()+'')} 
                    disabled 
                />
                <Dropdown 
                    title="Unidad" 
                    value={transport.lcl?.unitWeight+""} 
                    elements={[transport.lcl?.unitWeight+""]} 
                    setValue={()=>{}}
                    disabled
                />
                <TextField 
                    title="Volumen total" 
                    placeholder=" "  
                    value={ConverMoneyFormat(Number(transport.lcl?.totalVolume).toString()+"")} 
                    disabled 
                />
                <Dropdown title="Unidad" 
                          value={transport.lcl?.unitVolume+""} 
                          elements={[transport.lcl?.unitVolume+""]} 
                          setValue={()=>{}}
                          disabled/>
            </div>}

            {transport.typeContainer?.lcl &&  <div className="new-quote-transport-row__m">
                <TextField 
                    title="Peso total" 
                    placeholder=" " 
                    value={ConverMoneyFormat(Number(transport.lcl?.totalWeight).toString()+"")} 
                    disabled 
                />
                <Dropdown 
                    title="Unidad" 
                    value={transport.lcl?.unitWeight+""} 
                    elements={[transport.lcl?.unitWeight+""]} 
                    setValue={()=>{}}
                    disabled
                />
            </div>}

            {transport.typeContainer?.lcl && <div className="new-quote-transport-row__m">
                <TextField 
                    title="Volumen total" 
                    placeholder=" " 
                    value={ConverMoneyFormat(Number(transport.lcl?.totalVolume).toString()+"")} 
                    disabled 
                />
                <Dropdown title="Unidad" 
                          value={transport.lcl?.unitVolume+""} 
                          elements={[transport.lcl?.unitVolume+""]} 
                          setValue={()=>{}}
                          disabled/>
            </div>}

            {transport.lcl && transport.lcl.packages && transport.lcl.packages.length> 0 && transport.lcl.packages.map((p, idx) => (
                <>
                    <div className="smalltext-header new-quote-transport-title new-quote-transport-title__LCL">Paquete No {idx+1}</div>
                    <div className="new-quote-customs-data-row__">
                        <div className="new-quote-transport-row__dropdown">
                            <Dropdown
                                elements={[p.type]}
                                value={p.type}
                                setValue={()=>{}}
                                title='Tipo'
                                disabled
                            />
                        </div>    
                        <TextField 
                            title="Cantidad" 
                            placeholder=" "  
                            value={p.quantity} 
                            disable 
                        />     
                        <TextField 
                            title="Ancho" 
                            placeholder=" "  
                            value={p.width} 
                            disable 
                        />
                        <TextField 
                            title="Largo" 
                            placeholder=" "  
                            value={p.tall} 
                            disable 
                        />
                       <TextField 
                            title="Alto" 
                            placeholder=" "  
                            value={p.height} 
                            disable 
                        />
                        <div className="new-quote-transport-row__dropdown">
                            <Dropdown
                                elements={[p.unitMeasurement]}
                                value={p.unitMeasurement}
                                setValue={()=>{}}
                                title='Unidad'
                                disabled
                            />   
                        </div>                          
                    </div>
                </>
            ))}

            {transport.typeContainer?.lcl &&  
                <div className="new-quote-transport-row__m new-quote-transport-row__dropdown"> 
                    <Dropdown 
                        title={'Tipo de embalaje'}
                        value={transport.lcl?.cargoDescription+""} 
                        elements={[transport.lcl?.cargoDescription+""]} 
                        setValue={()=>{}}
                        disabled/>
                </div>
            }


            { transport.lcl?.packingList?.name && transport.lcl.packingList.url &&
                <div className="new-quote-transport-upload">
                    <div className="paragraph-header new-quote-transport-upload-text">Lista de empaque</div>
                    <UploadText fileValue={fileValue}
                                fileName={transport.lcl?.packingList?.name}
                                fileUrl={transport.lcl?.packingList?.url}
                                handleFileValue={handleFileValue}
                                />
                </div>
            }

            {/* LCLAir Consolidado */}
            {transport.typeContainer?.lclAir && <div className="smalltext-header">LCL</div>}
            
            {transport.typeContainer?.lclAir &&   <div className="new-quote-transport-row__">
                <div className="new-quote-transport-row__dropdown">
                    <Dropdown 
                        title={'Tipo de embalaje'}
                        value={transport.lclAir?.cargoDescription+""} 
                        elements={[transport.lclAir?.cargoDescription+""]} 
                        setValue={()=>{}}
                        disabled
                    />
                </div>

                <TextField 
                    title="Peso total" 
                    placeholder=" "  
                    value={ConverMoneyFormat(Number(transport.lclAir?.weight).toString()+'')} 
                    disabled 
                />
                <Dropdown 
                    title="Unidad" 
                    value={transport.lclAir?.unitWeight+""} 
                    elements={[transport.lclAir?.unitWeight+""]} 
                    setValue={()=>{}}
                    disabled
                />
                <TextField 
                    title="Volumen total" 
                    placeholder=" "  
                    value={ConverMoneyFormat(Number(transport.lclAir?.volume).toString()+"")} 
                    disabled 
                />
                <Dropdown title="Unidad" 
                        value={transport.lclAir?.unitVolume+""} 
                        elements={[transport.lclAir?.unitVolume+""]} 
                        setValue={()=>{}}
                        disabled/>
            </div>}

           {transport.typeContainer?.lclAir &&  <div className="new-quote-transport-row__m">
                <TextField 
                    title="Peso total" 
                    placeholder=" " 
                    value={ConverMoneyFormat(Number(transport.lclAir?.weight).toString()+"")} 
                    disabled 
                />
                <Dropdown 
                    title="Unidad" 
                    value={transport.lclAir?.unitWeight || ""} 
                    elements={[transport.lclAir?.unitWeight+""]} 
                    setValue={()=>{}}
                    disabled
                />
            </div>}

            {transport.typeContainer?.lclAir && <div className="new-quote-transport-row__m">
                <TextField 
                    title="Volumen total" 
                    placeholder=" " 
                    value={ConverMoneyFormat(Number(transport.lclAir?.volume).toString()+"")} 
                    disabled 
                />
                <Dropdown title="Unidad" 
                          value={transport.lclAir?.unitVolume+""} 
                          elements={[transport.lclAir?.unitVolume+""]} 
                          setValue={()=>{}}
                          disabled/>
            </div>}

            {transport.lclAir && transport.lclAir.packages && transport.lclAir.packages.length> 0 && transport.lclAir.packages.map((air, idx) => (
                <>
                    <div className="smalltext-header new-quote-transport-title new-quote-transport-title__LCL">Paquete No {idx+1}</div>
                    <div className="new-quote-customs-data-row__"> 
                        <div className="new-quote-transport-row__dropdown">
                            <Dropdown
                                elements={[air.cargoDescription]}
                                value={air.cargoDescription}
                                setValue={()=>{}}
                                title='Tipo'
                                disabled
                            />
                        </div> 
                        <TextField 
                            title="Cantidad" 
                            placeholder=" "  
                            value={air.numBulto} 
                            disable 
                        />     
                        <TextField 
                            title="Ancho" 
                            placeholder=" "  
                            value={air.width} 
                            disable 
                        />
                        <TextField 
                            title="Largo" 
                            placeholder=" "  
                            value={air.length} 
                            disable 
                        />
                       <TextField 
                            title="Alto" 
                            placeholder=" "  
                            value={air.tall} 
                            disable 
                        />
                        <div className="new-quote-transport-row__dropdown">
                            <Dropdown
                                elements={[air.unit]}
                                value={air.unit}
                                setValue={()=>{}}
                                title='Unidad'
                                disabled
                            />   
                        </div>                          
                    </div>
                </>
            ))}






            
            {/** Aqui comienza FCL */}

            {transport.typeContainer?.fcl && <div className="smalltext-header">FCL</div>}

            {transport.typeContainer?.fcl && transport.fcl && transport.fcl.length>0 && transport.fcl.map((f, idx) => (
                <React.Fragment key={idx}>
                    <div className="new-quote-transport-row__1 new-quote-transport-row__1__" key={idx}>
                        <Dropdown title={window.innerWidth<= 600 ? 'Tipo' : "Tipo de contenedor" }
                                value={f.type?f.type:''} 
                                elements={[]} 
                                setValue={()=>{}}
                                disabled />
                        <Dropdown title="Tamaño" 
                                value={f.size} 
                                elements={[]} 
                                setValue={()=>{}}
                                disabled/>
                        <Dropdown title="Cantidad" 
                                value={f.quantity+""} 
                                elements={[]} 
                                setValue={()=>{}}
                                disabled/>
                    </div>
                    <div className="new-quote-transport-row__1 new-quote-transport-row__1___">
                        <Dropdown 
                            title={window.innerWidth<= 600 ? 'Tipo' : "Tipo de contenedor" }
                            value={f.type?f.type:''} 
                            elements={[]} 
                            setValue={()=>{}}
                            disabled />
                        <Dropdown 
                            title="Tamaño" 
                            value={f.size} 
                            elements={[]} 
                            setValue={()=>{}}
                            disabled/>
                    </div>

                    <div className="new-quote-transport-row__1 new-quote-transport-row__1___">
                        <Dropdown 
                            title="Cantidad" 
                            value={f.quantity+""} 
                            elements={[]} 
                            setValue={()=>{}}
                            disabled/>
                    </div>
                </React.Fragment>
            ))}

            
        </div>
    )
}