import React, { useRef, useState } from "react";

/** import components */
import { ServiceOptios } from '../../@types/serviceTypes';
import { selectService } from "../../utils/selectService";

/** import interface */
import { ServiceTypeInterface } from "../../interfaces/Service";

/** import styles */
import './Services.css';
import { Hover } from "../Hover/Hover";

/** services props */
type ServiceProps = {
    active?: boolean, // status if service is actived
    name: string, // type of service going to render
    urlIcon?: any,
    onClickService?: (e:any) => void | React.MouseEventHandler<HTMLDivElement>; // event when clicked on service
    hover?: boolean;
}

/** component to rendered service in card */
export const Services: React.FC<ServiceProps> = ({
    active=false,
    /*typeService='Agenciamiento',*/
    name,
    urlIcon = '',
    onClickService,
    hover = false,
    ...props
}) => {
    /*const Icon = selectService(typeService)[0];
    const title = selectService(typeService)[1]*/

    const [isHover, setIsHover] = useState(false);

    const handleMouseOver = () => {
        setIsHover(true);
    };
    
        const handleMouseOut = () => {
        setIsHover(false);
    };

    return (
        <div className="services">
            {hover? <Hover
                title=""
                content="Pronto disponible."
                show={isHover}
                setShow={setIsHover}
              >
                <div 
                    role='servicesRole' 
                    className={'services-main'+(active?' services-active':'')}
                    onClick={hover ? ()=>{} : onClickService}
                    onMouseOver={handleMouseOver} 
                    onMouseOut={handleMouseOut}>
                    
                    {urlIcon.length > 0 && <img className='services-icon' src={urlIcon}/>}

                    <div className='service-title paragraph-header '>{name}</div>

                </div>
            </Hover> : 
            <div 
                role='servicesRole' 
                className={'services-main'+(active?' services-active':'')}
                onClick={onClickService}>
                
                {urlIcon.length > 0 && <img className='services-icon' src={urlIcon}/>}

                <div className='service-title paragraph-header '>{name}</div>

            </div>
            }
        </div>
        
    )
}

