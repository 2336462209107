import React, { useEffect, useState } from "react";

import { Dropdown } from "../Dropdown/Dropdown";
import { Trash } from "../../icons/outline/Trash";

import './ProductInvoceTranslation.css';
import { TextField } from "../TextField/TextField";
import { PhotoUnit } from "../PhotoUnit/PhotoUnit";
import { Loader } from "../Loader/Loader";
import { LinearLoader } from "../LinearLoader/LinearLoader";

interface ProductInvoceTranslationProps {
    number: number
    id: number;
    name: string;
    brand: string;
    model: string;
    status: string;
    description: string;
    functionPrincipal: string;
    // amount: number | undefined;
    // typeAmount: string;
    // countryOrigin: CountryInterface;
    // delivery: CountryInterface;
    first?: boolean;
    countryList: {country: string; alpha2Code: string, phoneCode: string }[];
    handleNameChange: (id: number, value: string, first: boolean, providerId?: number) => void;
    handleBrandChange: (id: number, value: string, first: boolean,  providerId?: number) => void;
    handleModelChange: (id: number, value: string, first: boolean,  providerId?: number) => void;
    handleStatusChange: (id: number, value: string, first: boolean,  providerId?: number) => void;
    handleDescription: (id: number, value: string, first: boolean, providerId?: number) => void;
    handleFunctionPrincipalChange: (id: number, value: string, first: boolean,  providerId?: number) => void;
    // handleAmount: (id: number, value: number, first: boolean,  providerId?: number) => void;
    // handleTypeAmount: (id: number, value: string, first: boolean, providerId?: number) => void;
    // handleCountryorigin: (id: number, value: CountryInterface, first: boolean, providerId?: number) => void;
    // handleDeliveryChange: (id: number, value: CountryInterface, first: boolean, providerId?: number) => void;
    // handleDeleteClick: (id: number, providerId?: number) => void;
    loading: boolean;
    disabled: boolean;
    providerId?: number;
    setProviderId?: React.Dispatch<React.SetStateAction<number>>;
    isNew?: boolean;
    providerList?: string[];
    handleMoveProduct: (i: number, provider: string) => void;
    photos: {name: string, url: string}[];
    handleChangePhoto: (idx: number, photo: {name: string, url: string} , first: boolean, photoIdx: number, provideridx?: number) => void;
}

export const ProductInvoceTranslation: React.FC<ProductInvoceTranslationProps> = ( {
    id,
    number,
    name,
    brand,
    model,
    status,
    description,
    functionPrincipal,
    // amount,
    // typeAmount,
    // countryOrigin,
    // delivery,
    first=false,
    // countryList,
    handleNameChange,
    handleBrandChange,
    handleModelChange,
    handleStatusChange,
    handleDescription,
    handleFunctionPrincipalChange,
    // handleAmount, 
    // handleTypeAmount,
    // handleCountryorigin,
    // handleDeliveryChange,
    // handleDeleteClick,
    disabled=false,
    providerId=-1,
    setProviderId=()=>{},
    isNew=false,
    providerList=[],
    handleMoveProduct,
    photos=[],
    handleChangePhoto,
    loading = false
}) => {

    // const [photoArray, setPhotoArray] = useState<any[]>([]);

    const [desFunciton, setDescFuntion] = useState<string>(functionPrincipal);

    const handlePhotoArrayChange = (idx: number, dataImg: string | ArrayBuffer | null, name: string) => {
        // console.log(idx, dataImg, name)
        handleChangePhoto(id, {
            name: name,
            url: dataImg as string
        }, first, idx, providerId);
    }   
 


    useEffect(() => {
        setProviderId(providerId);
    }, []);

    return (
        <div role='productInvoceTranslationRole' className='productInvoceTranslation-main'>
            {/* <div className='productInvoceTranslation-text smalltext-header'>Producto {number+1}</div> */}
            <div className='productInvoceTranslation-row-product product-4'>
                <div className="productInvoceTranslation-row-product product-2">
                    <TextField
                        title='Nombre comercial'
                        placeholder=' '
                        // className='productInvoceTranslation-product-text'
                        value={name}
                        onChange={(e: any) => handleNameChange(id, e.target.value, first, providerId)}
                        disabled={disabled}/>
                    <TextField
                        title='Marca'
                        placeholder=' '
                        // className='productInvoceTranslation-product-text'
                        value={brand}
                        onChange={(e: any) => handleBrandChange(id, e.target.value, first, providerId)}
                        disabled={disabled} />
                </div>
                <div className="productInvoceTranslation-row-product productInvoceTranslation-row-product-m product-2">
                    <TextField
                        title='Modelo'
                        placeholder=' '
                        // className='productInvoceTranslation-product-text'
                        value={model}
                        onChange={(e: any) => handleModelChange(id, e.target.value, first, providerId)}
                        disabled={disabled}/>
                    <Dropdown 
                        title='Estado'
                        elements={['Nuevo', 'Usado']}
                        setValue={(e: any)=> e !== status ? handleStatusChange(id, e, first): {}}
                        value={status}
                        disabled={disabled}/>
                </div>
            </div>
            
            <div className='productInvoceTranslation-row-product product-1'>
                <TextField
                    title='Nombre comercial'
                    placeholder=' '
                    // classNameText='productInvoceTranslation-product-text'
                    value={name}
                    onChange={(e: any) => handleNameChange(id, e.target.value, first, providerId)}
                    disabled={disabled}/>
            </div>

         
            
            <div className='productInvoceTranslation-row-product'>
                <TextField
                    title='Descripciones mínimas'
                    placeholder=' '
                    // className='productInvoceTranslation-field'
                    value={description}
                    onChange={(e: any) => handleDescription(id, e.target.value, first, providerId)}
                    disabled={disabled} />
                <TextField
                    title='Uso o función principal'
                    placeholder=' '
                    // className='productInvoceTranslation-field'
                    value={desFunciton}
                    onChange={(e: any) => setDescFuntion(e.target.value)}
                    onBlur={(e: any) => handleFunctionPrincipalChange(id, desFunciton, first, providerId)}
                    disabled={disabled}/>
            </div>
            <div className='productInvoceTranslation-photos'>
                {!loading ? photos.map((photo, idx) => (
                    <PhotoUnit 
                        idx={idx} 
                        key={idx} 
                        setImageData={handlePhotoArrayChange} 
                        imageSrc={photo.url}
                        // disabled={disabledImages} 
                    />
                     
                )) : <LinearLoader/>}
            </div>

            {(isNew)&&  <div className="productInvoceTranslation-row-product productInvoceTranslation-move">
                <Dropdown 
                    title='Mover a'
                    elements={providerList}
                    setValue={(e: any)=> handleMoveProduct(id, e)}
                    value={''}
                    className='productInvoceTranslation-sizeText'
                    disabled={disabled}
                />
            </div>}

            {/* <div className='productInvoceTranslation-trashIcon'>
                <Trash role='productInvoceTranslationDeleteButton' onClick={()=>handleDeleteClick(id, providerId)}/>
            </div> */}
        </div>
    )
}