import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type HelpCircleProps = React.HTMLAttributes<HTMLDivElement> & {};

export const HelpCircle: React.FC<HelpCircleProps> =({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+"/images/svg/help_circle_outline.svg"} alt="help_circle" />
        </div>
    )
}
