import React from "react";

import { ServiceChargeStatus } from "../../@types/serviceChargeStatus";
import { SelectChargeStatus } from "../../utils/selectChargeStatus";

import './CargoStatus.css';

interface CargoStatusProps {
    phase: ServiceChargeStatus;
    date?:string;
    role?: string;
    onClick?: ()=>void
}

export const CargoStatus: React.FC<CargoStatusProps> = ({
    phase,
    date,
    role,
    onClick= ()=>{}
}) => {

    const Icon = SelectChargeStatus(phase)[0];
    const title = SelectChargeStatus(phase)[1]

    return (
        <div className="cargoStatus-container" role={role} onClick={onClick}>
            <Icon className="cargoStatus-icon" /> 
            <div className="cargoStatus-text-column">
                <div className="cargoStatus-text">
                    <div className="tinytext cargoStatus-color">Mi carga está en</div>
                    <div className="smalltext-header cargoStatus-color cargoStatus-category-text">{title.toString()}</div>
                </div>
                 <div className="smalltext-header cargoStatus-color-date ">{date}</div>
            </div>
           
        </div>
    )
}