import React from "react";

import { ElementRow } from "./Row/ElementRow";

import './DashboardElementsRow.css';

interface DashboardElementsRowProps {
    elementsRow: any[],
    title: string,
    msg?:string,
    handleClick: () => void;
}

export const DashboardElementsRow: React.FC<DashboardElementsRowProps> = ({
    elementsRow,
    handleClick,
    title,
    msg = "",
}) => {
    return (
        <div className="dashboardElementsRow-container" role='dashboard-elements-row' onClick={()=>handleClick()} >
         { elementsRow.length>0 && elementsRow.map((e, idx) => {
            return (
                <ElementRow key={idx}
                            style={{backgroundColor: idx%2!==0?'var(--white-25)': 'transparent'}}
                            title={title}
                            msg={msg}
                            content={e}/> 
                )
         }) }
        </div>
    )
}