import React from "react";

import { Routes, Route } from 'react-router-dom';

import { ProtectedRoute } from "../components/ProtectedRouter/ProtectedRouter";

/** importando paginas User */
import { AdminPage } from "../pages/User/Admin/AdminPage";
import { MainServicePage } from "../pages/User/ServicePage/Main/MainServicePage";
import { ServiceDetailPage } from "../pages/User/ServicePage/Detail/ServiceDetailPage";
import { ChatPage } from "../pages/User/ChatPage/ChatPage";
import { ChatDetailPage } from "../pages/User/ChatPage/ChatDetailPage";
import { OrdersPage } from "../pages/User/Orders/Orders";
import { OrdersQuotationPage } from "../pages/User/Orders/Quotation/OrdersQuotation";
import { OrdersQuotationDetailPage } from "../pages/User/Orders/Quotation/Detail/OrdersQuotationDetailPage";
import { CustomsAgencyPage } from "../pages/User/CustomsAgency/CustomsAgency";
import { ProfileProvider } from "../pages/User/Profile/ProfileProvider";
import { Profile } from "../pages/User/Profile/MyProfile/Profile";
import { Business } from "../pages/User/Profile/Business/Business";
import { DetailProfile } from "../pages/User/Profile/DetailProfile/DetailProfile";
import { Login } from "../pages/Login/Login";
import { CompareQuotationPage } from "../pages/User/ServicePage/Compare/CompareQuotationPage";

/** importando paginas Proveedor*/
import { OrderProvider } from "../pages/Supplier/Orders/OrderProvider";
import { Quote } from "../pages/Supplier/Quote/Quote";
import { ProcessPage } from "../pages/Supplier/Orders/Process/ProcessPage";
import { ServiceProviderPage } from "../pages/Supplier/Service/ServiceProviderPage";
import { Home } from "../pages/Home/Home";
import { NotFound } from "../pages/NotFound/NotFound";
import { Tools } from "../pages/User/Tools/Tools";
import { PlanPage } from "../pages/User/PlanPage/PlanPage";
import { FrequentlyQuestions } from "../pages/User/FrequentlyQuestions/FrequentlyQuestions";
import { ProfilePage } from "../pages/User/Profile/ProfilePage";
import { UserPlanPage } from "../pages/User/UserPlanPage/UserPlanPage";
import { SuscriptionPage } from "../pages/User/Profile/SuscriptionPage/SuscriptionPage";
import { Receipts } from "../components/Receipts/Receipts";

export const RoutesMain: React.FC<{}> = () => { 

    return (    
        <Routes> {/** bloque de ruta */}
            <Route path="/iniciar-sesion" element={<Login />} />

            
            {/* <Route path="arkabia" element={<Home/>}/> */}
            <Route path="/" element={<AdminPage />}> {/** pagina principal del user */}
                <Route path="herramientas" element={<Tools />} />
                <Route path="precios" element={<PlanPage />} />
                <Route path="preguntas-frecuentes" element={<FrequentlyQuestions />} />

                <Route path="/404" element={<NotFound/>}/>
                <Route path="" element={<Home />} /> {/** pagina principal de servicio */}
                <Route path="servicios" element={<MainServicePage />} /> {/** pagina principal de servicio */}
                <Route path="servicios/:serviceType" element={<MainServicePage />} /> {/** pagina principal de servicio */}
                <Route path="servicios/:serviceType/:serviceId/" element={<ServiceDetailPage />} /> {/** pagina principal de servicio */}
                {/** seccion de operaciones */}
                <Route path="operaciones" element={
                    <ProtectedRoute userType={['user']}>
                        <OrdersPage />
                    </ProtectedRoute>
                }/>

                <Route path="cambio-plan" element={
                    <ProtectedRoute userType={['user']}>
                        <UserPlanPage />
                    </ProtectedRoute>
                }/>

                {/** cotizaciones */}
                {/** Detalle de la solicitud de cotizacion */}
                <Route path="operaciones/solicitud-cotizacion/:operationId" element={
                    <ProtectedRoute userType={['user']}>
                        <OrdersQuotationPage />
                    </ProtectedRoute>
                }/> 
                {/** Detalle de cotizacion */}
                <Route path="operaciones/:operationId/:requestQuoteId/cotizaciones/:quotationId" element={
                    <ProtectedRoute userType={['user']}>
                        <OrdersQuotationDetailPage />
                    </ProtectedRoute>
                } />
                {/* Comparar cotizaciones  */}
                <Route path="operaciones/:operationId/comparar" element={   
                    <ProtectedRoute userType={['user']}>
                        <CompareQuotationPage/>
                    </ProtectedRoute>
                }/>

                {/** Orden */}
                {/** proceso de agenciamiento de aduana */}
                <Route path="operaciones/:operationId/orden/:orderId/:numPhase" element={
                    <ProtectedRoute userType={['user']}>
                        <CustomsAgencyPage />
                    </ProtectedRoute>
                }/> 
                {/* <Route path="operaciones/orden/resumen/:orderId" element={<OrderSummary />} /> * Detalle del agenciamiento de aduanas */}
                
                {/* <Route path="mobile/agenciamiento/cotizaciones/:quotationId" element={<QuotationMobileCustomAgency />} /> */}
                {/** chat */}
                <Route path="mensajes" element={ 
                    <ProtectedRoute userType={['user', 'provider']}>
                        <ChatPage />
                    </ProtectedRoute>
                } />

                {/** Mobile */}
                <Route path="mensajes/detalle/:chatId" element={
                    <ProtectedRoute userType={['user', 'provider']}>
                        <ChatDetailPage />
                    </ProtectedRoute>
                } />

                {/* * Profile */}
                <Route path="perfil" element={
                    <ProtectedRoute userType={['user', 'provider']}>
                        <ProfilePage />
                    </ProtectedRoute>
                } /> {/*  pagina de perfil del proveedor */}

                {/** vistart para dispositivos moviles del perfil*/}
                {window.innerWidth <=768 && <Route path="mobile/perfil" element={<DetailProfile />} />}
                {window.innerWidth <=768 && <Route path="mobile/mi-suscripcion" element={<SuscriptionPage/>} />}
                {window.innerWidth <=768 && <Route path="mobile/mis-recibos" element={<Receipts/>} />}
                {/*{window.innerWidth <=768 && <Route path="mobile/contacto" element={<ContactsProfile />} />}
                {window.innerWidth <=768 && <Route path="mobile/facturacion" element={<Invoice />} />}
                {window.innerWidth <=768 && <Route path="mobile/mi-resena" element={<ReviewProfile />} />}
                {window.innerWidth <=768 && <Route path="mobile/mis-referencias" element={<Reference handleInfluencer={() => {}}
                                                                                                    influencer={true} />} />}
                {window.innerWidth <=768 && <Route path="mobile/mi-saldo" element={<Balance />} />} */}

                {/** Usuario Proveedor */}
                <Route path="proveedor">
                    <Route path="" element={
                        <ProtectedRoute userType={['provider']}>
                            <OrderProvider />
                        </ProtectedRoute>
                    }/>
                    <Route path="operaciones" element={
                        <ProtectedRoute userType={['provider']}>
                            <OrderProvider />
                        </ProtectedRoute>
                    }/>
                    <Route path="operaciones/solicitud-cotizacion/:requestQuoteId" element={
                        <ProtectedRoute userType={['provider']}>
                            <Quote/>
                        </ProtectedRoute>
                    }/>  

                    {/* order */}
                    {/** procedo de agenciamiento de aduana */}
                    <Route path="operaciones/:operationId/orden/:orderId/:numPhase" element={
                        <ProtectedRoute userType={['provider']}>
                            <ProcessPage />
                        </ProtectedRoute>} 
                    /> 

                    <Route path="servicios-proveedor" element={
                        <ProtectedRoute userType={['provider']}>
                            <ServiceProviderPage/>
                        </ProtectedRoute>}/>
                    {/*<Route path="servicios-proveedor/nuevo-agenciamiento" element={<NewCustomsPage/>}/> */}
                </Route>         
            </Route>
        </Routes>
    )
}