import React, {useState, useEffect} from "react";

/** importando componentes */
import { RowTaxes } from "../../../components/Row/RowTaxes/RowTaxes";
import { TextField } from "../../../components";
import { RowGroupTaxesSupplier } from "../../Row/RowGroupTaxesSupplier/RowGroupTaxesSupplier";
import { ButtonEditChange } from "../../ButtonEditChange/ButtonEditChange";
import { SwitchToggle } from "../../SwitchToggle/SwitchToggle";
import { UploadFile } from "../../UploadFile/UploadFile";

import { GetExchangeRate } from "../../../Services/Sunat.service";
import { ArrivalControlChannel, DutiesTaxes, TaxableBase, Taxes } from "../../../interfaces/ArrivalControlChannelInterface";

/** importando interface */
// import { PhaseSeven_Base } from "../../../interfaces/PhasesSupplier";
// import { Taxes } from "../../../interfaces/Quotation";

/** importando estilos */
import './RowPhaseSeven.css';
import { UploadFileArray } from "../../UploadFile/UploadFileArray/UploadFileArray";
import { DocsFileValidation, ImageFileValidation, PDFFileValidation, SpreadSheetFileValidation } from "../../../Consts/FilesValidation";
import { Loader } from "../../Loader/Loader";
import StoreService from "../../../Services/Store.service";
import { toast } from "react-toastify";



/** declarando variables de interface */
interface RowPhaseSevenProps {
    operationId: string;
    taxableBase: TaxableBase;
    dutiesTaxes: DutiesTaxes;
    taxes: Taxes;
    setArrivalControlChannel: React.Dispatch<React.SetStateAction<ArrivalControlChannel>>;
    // base: PhaseSeven_Base;
    // setBase: React.Dispatch<React.SetStateAction<PhaseSeven_Base>>

    // total: number;
    // setTotal: React.Dispatch<React.SetStateAction<number>>;
    // taxes: Taxes;
    // setTaxes: React.Dispatch<React.SetStateAction<Taxes>>;
    // taxesValue: Taxes;
    // setTaxesValue: React.Dispatch<React.SetStateAction<Taxes>>;
    onClickSave: () =>void;
    role?: string
    handleClickComplete?: ()=>void;
    showEdit?: boolean;
    setShowEdit?: React.Dispatch<React.SetStateAction<boolean>>,
    exchageRate: number,
    comercialInvoice: {name: string, url: string}[];
}

export const RowPhaseSeven: React.FC<RowPhaseSevenProps> = ({
    operationId,
    // base,
    // setBase,
    // total,
    // setTotal,
    // taxes,
    // setTaxes,
    // taxesValue,
    // setTaxesValue,
    taxableBase,
    dutiesTaxes,
    taxes,
    setArrivalControlChannel,
    onClickSave,
    role,
    handleClickComplete=()=>{},
    showEdit=false,
    setShowEdit=()=>{},
    exchageRate,
    comercialInvoice
}) => {
    
    let [checked, setChecked] = useState(false);

    const [comercialInvoiceLocal, setComercialInvoiceLocal] = useState<any>(undefined);

    const [loading, setLoading] = useState<boolean>(false);

    // const [ showEdit, setShowEdit ] = useState(false);


    // const handleEdit = () => {
    //     setShowEdit(true)
    // };

    // const handleClickCancel = () => {
    //     setShowEdit(false)
    // }

    /* Funcion para mostrar editar cuando los campos se encuentran llenos
       caso contrario se muestran los botones guardar y cancelar */
    //    const checkFields = () => {
    //     /** Se coloca los value */
    //     if( base.ipm.value === 0 || base.adValorem.value=== 0 || base.igv.value=== 0 || base.igv.value=== 0 
    //         || taxesValue.adValorem.value  === 0 || taxesValue.igv.value  === 0 || taxesValue.ipm.value  === 0 
    //         || taxesValue.perception.value  === 0) { // los campos estan vacios 
    //       setShowEdit(true);
    //     } else {
    //       setShowEdit(false); // Campos llenos 
    //     }
    // }

    // useEffect(()=> {
    //     checkFields();
    // }, [])

    const handleExchangechange = (value: string) => {
        setArrivalControlChannel((prev)=> ({
            ...prev,
            dutiesTaxes: {
                ...prev.dutiesTaxes,
                exchangeRate: Number(value)
            }
        }))
    }

    const getExchangeRate = async () => {
        const response: any = await GetExchangeRate();
        setArrivalControlChannel((prev)=> ({
            ...prev,
            dutiesTaxes: {
                ...prev.dutiesTaxes,
                exchangeRate: Number(response.sale)
            }
        }))
    }

     /** Subiendo archivos de factura comercial */
     const handleComercialInvoiceChange = async (d:any) => {
        if(d .target.files[0]) {
            // toast("Subiendo Archivos...");
            try {
                setLoading(true)
                const responseFile = await StoreService.UploadFile(d.target.files[0], operationId+`/ArrivalControl/ProofPayment/`+d.target.files[0].name)
                console.log("🚀 ~ file: ProcessPage.tsx ~ line 577 ~ handleDocumentationChange ~ responseFile", responseFile)
               
                if(responseFile) {
                    let tmpFile = {
                        name: d.target.files[0].name,
                        url: responseFile.toString(),
                    }

                    setArrivalControlChannel((prev)=> ({
                        ...prev,
                        dutiesTaxes: {
                            ...prev.dutiesTaxes,
                            file: [...prev.dutiesTaxes.file, tmpFile]
                        }
                    }));
        
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ file: FilesFLC.tsx ~ line 120 ~ handleComercialInvoiceChange ~ error", error)
                toast.error("Hubo un error al subir el archivo a Facturacion comercial");
            }
        } else {
            toast.info("Por favor suba un archivo");
        }
    }

     /** Eliminando archivos de factura comercial */
     const handleDeleteComercialInvoice = async (dd: number) => {
        // try {
        //     setLoading(true);
        //     let tmpDocumentation: DocumentationInterface = {
        //         ...documentation,
        //         comercialInvoice: documentation.comercialInvoice.filter((_, idx) => idx !== dd),
        //         updatedAt: new Date(),
        //     }
        //     setDocumentation(tmpDocumentation);
        //     const response = await UpdateDocumentation(tmpDocumentation);
        //     setLoading(false);
        //     console.log("🚀 ~ file: CustomsAgency.tsx ~ line 534 ~ handleDeleteDocument ~ response", response)
        //     toast.success("¡Se elimino con éxito el archivo!");
        // } catch (error) {
        //     console.log("🚀 ~ file: FilesFLC.tsx ~ line 388 ~ handleDeleteComercialInvoice ~ error", error)
        //     setLoading(false);
        //     toast.error("Hubo un error al eliminar la Factura comercial");
        // }
    }

    
    useEffect(() => {
        getExchangeRate();
    }, []);

    return (
        <div className="rowPhaseSeven-main box-shadow-card" role={role}>
            <h1 className="paragraph-header">Tributos</h1>
            <p className="paragraph">Los tributos son los derechos e impuestos que se tienen que pagar para liberar tu carga.</p>
            <div className='rowPhaseSeven-tableQuotation-container'>
                <div className="paragraph-header rowPhaseSeven-tableQuotation-title">Base Imponible</div>
                <RowTaxes open={true}
                          disabled= {!showEdit}
                          onChangeText={()=>{}}
                          taxableBase={taxableBase}
                          setArrivalControlChannel={setArrivalControlChannel}/>
            </div>
            
            <div className="rowPhaseSeven-change">
                <div className="paragraph-header phaseSeven_white">Tasa de cambio USD/PEN</div>
                <TextField title="" 
                           value={exchageRate} 
                           onChange={(e:any) => handleExchangechange(e.target.value)} 
                           placeholder=" "
                           type='number' 
                           disabled= {!showEdit} />
            </div>

            <div className='rowPhaseSeven-tableQuotation-container'>
                <div className="paragraph-header rowPhaseSeven-tableQuotation-title">
                    <div>Impuestos </div>
                    <div className="rowPhaseSeven-row__">
                        <div className="paragraph-header rowPhaseSeven_text___">USD</div>
                        <SwitchToggle id="change"
                                      small
                                      disabled={!showEdit}
                                      checked={checked}
                                      onChange={setChecked}
                                      color="green-2"/>
                        <div className="paragraph-header">PEN</div>
                    </div>
                </div>
                <RowGroupTaxesSupplier 
                    taxes={taxes}
                    setArrivalControlChannel={setArrivalControlChannel}
                    disabled={!showEdit}/>
            </div>
            {/* <div className={'phaseSevenSupplier-footer'}>
            {disabled &&
                <ButtonEditChange onClickEdit={()=>handleEdit()}
                                  edit={showEdit}
                                  setEdit={setShowEdit}
                                  add={showEdit}
                                  onClickCancel={()=>handleClickCancel()}
                                  onClickComplete={()=>handleClickComplete()}
                                  onClickSave={onClickSave}/>}
            </div> */}
            
            <UploadFile 
                fileValue={comercialInvoice} 
                fileName={""}
                handleFileValue={handleComercialInvoiceChange}
                buttonContent="Subir comprobante de pago"
                title=""
                subtitle=""
                acceptType={DocsFileValidation+","+SpreadSheetFileValidation+","+PDFFileValidation}
                disabled={!showEdit}
            />
           {/* <>
            {!loading ? <UploadFileArray 
                    title=""
                    subtitle=""
                    buttonContent="Subir comprobante de pago"
                    fileValue={comercialInvoice}
                    file={comercialInvoiceLocal}
                    handleFileValue={handleComercialInvoiceChange}
                    acceptType={DocsFileValidation+","+SpreadSheetFileValidation+","+PDFFileValidation+","+ImageFileValidation}
                    handleDelete={handleDeleteComercialInvoice}
                /> : <Loader />}
            </> */}
        </div>
    )
}   