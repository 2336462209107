import axios from "axios";
import { FUNCTIONS_API, FUNCTIONS_API_COMPARE_PLAN } from "../Consts/BaseUrl";
import { ComparePlansInterface } from "../interfaces/ComparePlanInterface";

const { 
    REACT_APP_BASIC_AUTH
  } = process.env;

// method to get compare table
export const getCompareTablePlans = async (): Promise<ComparePlansInterface>=> {
    let config = {
        method: 'get',
        url: FUNCTIONS_API+FUNCTIONS_API_COMPARE_PLAN,
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Basic '+REACT_APP_BASIC_AUTH,
        }
    };
    return axios.request(config)
        .then((response) => {
            return response.data;
        }).catch((error) => {
            console.log(error);
            throw error;
        });
}