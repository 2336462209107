import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type CreditCardWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const CreditCardWhite: React.FC<CreditCardWhiteProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/credit_card_black.svg'} alt='Alarm' />
        </div>
    ) 
}