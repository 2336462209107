import React, { useState } from 'react';

import './ThridSeccion.css';
import { CardHoverExpand } from '../../CardHoverExpand/CardHoverExpand';
import { CardHoverExpandConst } from '../../../Consts/CardHoverExpandConst';

interface ThridSeccionProps {
}

export const ThridSeccion: React.FC<ThridSeccionProps> = ({

}) => {

  return (
    <div className="third-section">
        <div className="third-section-header">
            <div className='third-section-text'>
                No importa cuál sea tu industria, 
            </div>
            <div className='third-section-text'>
                puedes crecer tu negocio con 
                <b className='third-section-text-grandient'> Arkabia</b>
            </div>
        </div>

        <div className="third-section-header-mob">
            <div className='third-section-text'>
                No importa cuál sea tu  
            </div>
            <div className='third-section-text'>
                industria, puedes crecer tu
            </div>
            <div className='third-section-text'>
                negocio con 
                <b className='third-section-text-grandient'> Arkabia</b>
            </div>
        </div>

        <div className='third-section-animation'>
            {CardHoverExpandConst.map((item, index) => (
                <CardHoverExpand
                    key={index}
                    itemsHover={item}
                />
            ))}
        </div>
    </div>
  );
};