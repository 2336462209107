import React, { useState } from "react";

/** Importando estilos */
import './HeaderElementsMobile.css';

interface HeaderElementsMobileProps { 
    // user: string;
    aduana: string;
    // nameUser: string;
}
export const HeaderElementsMobile: React.FC<HeaderElementsMobileProps> = ({
    // nameUser,
    aduana,
    // user,
}) => {

    return (
        <>
            <div className="dashboardElements-mobile-content-header">
                <div className="smalltext-header dashboardElements-mobile-title"> {aduana} </div>
                {/* <div className="dashboardElements-mobile-content--imgTag">
                    <img src={user} />
                    <div className="smalltext-header dashboardElements-mobile-content-header-name">{nameUser}</div>
                </div> */}
            </div>
        </>
        )
}