import React from "react";
import './CompanyList.css';

// importando componentes
// import { FilterSelectorExtended } from "../FilterSelector/FilterSelectorExtended/FilterSelectorExtended";
import { ServiceMiniature } from "../ServiceMiniature/ServiceMiniature";

// importando interfaces
import { ServiceInterface } from "../../interfaces/Service";

// importando icon
// import { Button } from "../Button/Button";

// import const
// import { FilterByRelevance } from "../../Consts/FilterRelevance";
// import { GetAllServicesKeyboardsActive } from "../../Services/ServiceKeyboards.service";
// import { ServiceKeyboards } from "../../interfaces/ServiceKeyboards";
// import { CompanyMiniature } from "../CompanyMiniature/CompanyMiniature";
// import { Verify } from "../Verify/Verify";


interface CompanyListProps {
    serviceData: ServiceInterface[]; // lista de servicios
    chooseAgency: boolean ; // status to choose agency to quotation
    requestQuotes: any[]; // array orderes in quotations
    requestSelected?: any[]; // array orderes in seleccionadas para enviar
    onClickAddQuoteAgency: (e: any) => void; 
    onClickService: (serviceId: string | undefined, serviceType: string)=>void // metodo al hacer click en servicio
    onClickWsp?: (id: string)=>void;
    showButtonWsp?: boolean; // se muestra el modal
    hiddenVerify?: boolean;

}

export const CompanyList: React.FC<CompanyListProps> = ({
    serviceData,
    chooseAgency = false,
    requestQuotes,
    requestSelected = [],
    onClickAddQuoteAgency,
    onClickService,
    onClickWsp=()=>{},
    showButtonWsp = false,
    hiddenVerify = false
}) => {
    
    // const [serviceLeaked, setServiceLeaked] = useState<ServiceInterface[]>([]);
    // const [serviceKeyboards, setServiceKeyboards] = useState<ServiceKeyboards[]>([]);

    const getQuoteSended = (companyId: string | undefined) => {  
        let id = requestQuotes.findIndex((rq) => {
            return rq.companyId === companyId
        });
        return id !== -1 ? true:false       
    }

    const getQuoteSelected= (companyId: string | undefined) => {  
        let id = requestSelected.findIndex((rq) => {
            return rq.companyId === companyId
        });
        return id !== -1 ? true:false       
    }

    return (
        <div className='companyList-main'>
            <div className={serviceData.length >= 3 ? 'companyList-list' : 'companyList-min-list'}>
                {serviceData && serviceData.length> 0 ? serviceData.map((service: ServiceInterface, idx) => {
                    return (
                        <ServiceMiniature
                            key={service.id}
                            service={service}
                            choose={chooseAgency}
                            edit={false}
                            quoteSended={getQuoteSended(service.companyId)}
                            quoteSelected={getQuoteSelected(service.companyId)}
                            onClickChoose ={()=>onClickAddQuoteAgency({ 
                                ...service,
                                serviceRating: service.rating,
                            })}
                            onClickService={onClickService}
                            // onClickScroll={ScrollTop}
                            onClickWsp={()=>onClickWsp(service.companyId)}
                            showButtonWsp={showButtonWsp}
                            verify={service.verify}
                            numberVerify={service.numberVerify}
                            hiddenVerify={hiddenVerify}
                        />
                    )
                }) : <div className="companyList-NotFound">
                        {/* <Boat_NotFound/> */}
                        <div className="paragraph-header companyList-NotFound-text">
                            Lo sentimos, no se encontró el servicio.
                        </div>
                    </div> }
            </div>
        </div>
    )
}