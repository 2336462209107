import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type DocumentsProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Documents: React.FC<DocumentsProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/documents.svg'} alt='Documents' />
        </div>
    )
}