import React from "react";

import './SectionNavbar.css';
import { RightArrow } from "../../icons/outline/RightArrow";

interface SectionNavbarProps {
    icon?: React.ReactNode;
    imgSrc?: string;
    section: string;
    handleSection:  ()=>void;
    direction?: boolean;
    iconDirection?: string
}

export const SectionNavbar: React.FC<SectionNavbarProps> = ({
    icon,
    imgSrc,
    section,
    handleSection,
    direction= false,
    iconDirection 
}) => {
    return (
        <div className="sectionNavbar" onClick={handleSection}>
            <div className="sectionNavbar-left">
                {imgSrc ? 
                    <img src={imgSrc} className="sectionNavbar-icon"/> :
                    icon}
                <div className="paragraph-header">{section}</div>
            </div>
            {direction ? <img src={iconDirection}/> : <RightArrow className="sectionNavbar-right-arrow"/>}
        </div>
    )
}

