import React, { useState } from "react";

/** importando componentes */
import { Modal } from "../Modal/Modal";
import { Dropdown } from "../../Dropdown/Dropdown";
import { TextField } from "../../TextField/TextField";
import { Textarea } from "../../Textarea/Textarea";
import { Checkbox } from "../../Checkbox/Checkbox";
import { ButtonEditChange } from "../../ButtonEditChange/ButtonEditChange";

/** importando interface */

/** importando iconos */

/** importando estilos */
import './ModalInfluencer.css';

interface ModalInfluencerProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handleClickCancel: ()=>void;
    handleClickComplete: ()=>void;
    category?: string
    setCategory?: React.Dispatch<React.SetStateAction<string>>;
    type?: string;
    setType?: React.Dispatch<React.SetStateAction<string>>;
    aboutYou?: string;
    setAboutYou?: React.Dispatch<React.SetStateAction<string>>;
    whyRecommendUs?: string;
    setWhyRecommendUs?: React.Dispatch<React.SetStateAction<string>>;
    links?: string;
    setLiks?: React.Dispatch<React.SetStateAction<string>>;

}

export const ModalInfluencer: React.FC<ModalInfluencerProps> = ({
    show,
    setShow,
    handleClickCancel,
    handleClickComplete,
    category='',
    setCategory=()=>{},
    type='',
    setType=()=>{},
    aboutYou='',
    setAboutYou=()=>{},
    whyRecommendUs='',
    setWhyRecommendUs=()=>{},
    links='',
    setLiks=()=>{},
}) => {

    const [ check, setCheck] = useState<boolean>(false);

    return (
        <div role='modalInfluencerRole' className="modal-influencer">
            <Modal disableToast title="Regístrate cómo influencer Arkabia" show={show} setShow={setShow} >
                <div className="modal-influencer-content">
                    <div className="paragraph">
                        Como influencer Arkabia, si alguien se registra con tu link, esa persona recibirá 10 tokens gratis.
                        Cuando esa persona pague su primera contratación de agencia de aduana tú ganarás 10 USD a tu cuenta bancaria.
                    </div>
                    <div className="modal-influencer__row">
                        <Dropdown title="Categoría de influencia"
                                  elements={['Comercio exterior', 'Comercio interior']}
                                  value={category}
                                  setValue={(e: any) =>setCategory(e)} 
                                  roleText="categoryRole"
                                  />
                        
                        <Dropdown title="¿Tipo de influencer?"
                                  elements={['Youtuber', 'otros']}
                                  value={type}
                                  setValue={(e: any) =>setType(e)}
                                    roleText="typeRole"
                                />
                    </div>

                    <div className="modal-influencer__row">
                        <TextField title='Cuéntanos de ti y por qué eres influencer'
                                   placeholder=' '
                                   value={aboutYou}
                                   onChange={(e:any) => setAboutYou(e.target.value)}
                                    role="aboutYouRole"
                                />
                    </div>

                    <div className="modal-influencer__row">
                        <TextField title='¿Por qué te gustaría recomendar Arkabia?'
                                   placeholder=' '
                                   value={whyRecommendUs}
                                   onChange={(e:any) => setWhyRecommendUs(e.target.value)}
                                    role="whyRecommendUsRole"
                                />
                    </div>

                    <div className="modal-influencer__row">
                        <Textarea rows={1}
                                title="Links"
                                value={links}
                                onChange={(e:any) => setLiks(e.target.value)}
                                placeholder=' '
                                role="linksRole"
                            />
                    </div>

                    <div className="modal-influencer___check">
                        <Checkbox checked={check} setChecked={setCheck}/>
                        <div className="smalltext-header">¿Representas a una empresa?</div>
                    </div>

                    { check &&
                        <>
                            <div className="modal-influencer__row modal-influencer__row__3">
                                <Dropdown title="País"
                                        elements={['Perú', 'China']}
                                        value={'Perú'}
                                        setValue={(e: any) =>(e)} />

                                <Dropdown title="Identificación fiscal"
                                        elements={['RUC', 'Otros']}
                                        value={'RUC'}
                                        setValue={(e: any) =>(e)} />
                                
                                <TextField title='Número de RUC'
                                        placeholder=' '
                                        value={'20763534262'}
                                        onChange={(e:any) => (e.target.value)}/>
                            </div>

                            <div className="modal-influencer__row modal-influencer__row__2">
                                <Dropdown title="País"
                                        elements={['Perú', 'China']}
                                        value={'Perú'}
                                        setValue={(e: any) =>(e)} />

                                <Dropdown title="Identificación fiscal"
                                        elements={['RUC', 'Otros']}
                                        value={'RUC'}
                                        setValue={(e: any) =>(e)} />
                            </div>
                            <div className="modal-influencer__row modal-influencer__row__1">
                                <TextField title='Número de RUC'
                                            placeholder=' '
                                            value={'20763534262'}
                                            onChange={(e:any) => (e.target.value)}/>
                            </div>

                            <div className="modal-influencer__row">
                                <TextField title='Nombre de la empresa'
                                           placeholder=' '
                                           value={'Jaengas S.A.C.'}
                                           onChange={(e:any) => (e.target.value)}/>

                                <TextField title='Cargo en la empresa'
                                           placeholder=' '
                                           value={'Gerente General'}
                                           onChange={(e:any) => (e.target.value)}/>    
                            </div>

                            <div className="modal-influencer__row">
                                <TextField title='Correo electrónico de facturación'
                                           placeholder=' '
                                           value={'facturación@jaengas.com'}
                                           onChange={(e:any) => (e.target.value)}/>

                                <TextField title='Teléfono de trabajo'
                                           placeholder=' '
                                           value={'976616514'}
                                           onChange={(e:any) => (e.target.value)}/>    
                            </div>
                        </>
                    }

                    <div className="smalltext-header modal-influencer-terms" >
                        Al completar, aceptas los <a  className="modal-influencer-link">términos y condiciones</a> de Arkabia.
                    </div>

                    <ButtonEditChange add={true} 
                                      setEdit={()=>{}} 
                                      edit={true} 
                                      onClickCancel={handleClickCancel} 
                                      onClickComplete={handleClickComplete} 
                                      onClickEdit={()=>{}}
                                      onClickSave={()=>{}}/>
                </div>
            </Modal>
        </div>
    )
}
