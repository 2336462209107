import React, { useState, useEffect } from 'react';

import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// importando firebase 
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// importando componentes
import { useDispatch, useSelector } from "react-redux";

import { GetUserByUID } from '../../Slices/User';

// store
import type { AppDispatch } from "../../Store/Store";
import { setHistoryLocation } from '../../Slices/HistoryLocation';
import { setAuth } from '../../Slices/Auth';
import { AUTH_LOGIN, AUTH_MODAL, AUTH_REGISTER } from '../../Consts/BaseUrl';
import { GetPlanId } from '../../Slices/Plan';
import { GetSubscriptionId } from '../../Slices/Subscription';

interface ProtectedRouteProps {
    permissions?: string[]; // array de permisos
    userType?: string[]; // user type (user or provider)
    redirectPath?: string; // path a redireccionar
    children: React.ReactNode; 
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    permissions,
    userType='',
    redirectPath = `?${AUTH_MODAL}=${AUTH_LOGIN}`,
    children,
    ...props
}) => {

    const dispatch = useDispatch<AppDispatch>();    

    const { userDetail } = useSelector((state: any) => state.user);

    const navigation = useNavigate();
    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();
    

    const [loading, setLoading] = useState<boolean>(true);

    const [isLoggin, setIsLoggin] = useState<boolean>(false);

    const [user, setUser] = useState(() => {
        const user = getAuth().currentUser;
        return user;
    });

    const getUserDetail = (UID: string) => {
        setLoading(true)
        dispatch(GetUserByUID(UID))
        .unwrap()
        .then((response) => {
            getPlanById(response.planId);
            getBuscriptionyId(response.subscriptionId);
            // setUserDetail(response);
            // dispatch(setAuth({
            //     isLoggedIn: true,
            //     userId: response.id,
            //     uid: response.uid,
            //     displayName: response.name+" "+response.lastName,
            //     imgProfile: response.photoUrl,
            // }));
            setIsLoggin(true);
            setTimeout(() => {
                setLoading(false);
            }, 300);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    // method to get plan 
    const getPlanById = (id: string) => {
        setLoading(true)
        dispatch(GetPlanId(id))
        .unwrap()
        .then((response) => {
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    // method to get plan 
    const getBuscriptionyId = (id: string) => {
        setLoading(true)
        dispatch(GetSubscriptionId(id))
        .unwrap()
        .then((response) => {
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }


    const compareUserRole = () => {
        if(!userDetail) return false;

        return userDetail?.userType?.some((u: any) => userType.includes(u))
    }

    // permite redireccionar al home segun el tipo de usuario
    const goToUserHome = () => {
        if(userDetail &&  userDetail?.userType && userDetail?.userType.includes("user")) {
            return navigation('/operaciones', {replace: true})
        } else if(userDetail &&  userDetail?.userType && userDetail?.userType.includes("provider")) {
            // console.log("provider")
            return navigation('/proveedor',  {replace: true})
        }
    }

    const getAccessUrl = () => {
        // dispatch(setAuth({
        //     isProtectedRoute: false,
        // }));
        if(userDetail && userDetail?.userType && compareUserRole()){
            searchParams.delete(AUTH_MODAL);
            // setSearchParams(searchParams);

            return children;
        } else {
            goToUserHome()
        }
    }

    const rediretToLogin = () => {
        // const response = await authService.LogOutUser();
        if(isLoggin) return;

        dispatch(setHistoryLocation(location.pathname));
        if(!searchParams.get(AUTH_MODAL) && (!searchParams.get(AUTH_MODAL)?.includes(AUTH_LOGIN) || !searchParams.get(AUTH_MODAL)?.includes(AUTH_REGISTER) )) {
            dispatch(setAuth({
                isLoggedIn: false,
                displayName: "",
                imgProfile: "",
                userId: "",
                uid: "",
                isProtectedRoute: true
            }));
            return navigation(location.pathname+redirectPath, {replace: true});
        }
        
    }
    
    useEffect(() => {
        const auth = getAuth();
        setLoading(true);
        onAuthStateChanged(auth, (userCurrent) => {
            if(userCurrent) {
                setUser(userCurrent);
                getUserDetail(userCurrent.uid);
            } else {
                setLoading(false);
            }
        });
    }, []);


    return (
        <>
            {!loading ?  (
                (user && isLoggin) ? 
                getAccessUrl(): 
                rediretToLogin()
            ) : null}
        </>
    )
}