import React, { useCallback, useEffect, useRef, useState } from "react";

import { MessageChat } from "./Message/MessageChat";
import { InputChat } from "./Input/InputChat";

import { AttachChat, MessageGroupInterface, MessageInterface } from "../../interfaces/MessageInterface";

import './Chat.css';
import { getMessagesById, pushMessage, sendPushNotifications } from "../../Services/Message.service";
import { MessageType } from "../../@types/messageType";
import StoreService from "../../Services/Store.service";
import { GetAttachIcon } from "../../utils/GetAttachIcon";
import debounce from "lodash.debounce";
import {  UpdateChat } from "../../Services/Chat.service";
import { MemberChat } from "../../interfaces/ChatInterface";

import { toast } from "react-toastify";

import { formatISO, formatDistanceToNowStrict } from "date-fns";

 
interface ChatProps {
    chatId: string; // id chat active
    userId: string; // id user 
    userName: string; // name of user
    disabledInputs?: boolean;

    messageData?: MessageInterface;
    isGroup?: boolean | undefined;
    searchText?: string; // search text
    timeChat?: Date
    messagesUnRead?: number;
    members?: MemberChat[];
    sendWhatsappNotification: () => void;


    paramHeight: number;
}

export const Chat: React.FC<ChatProps> = ({
    chatId,
    userId,
    userName,
    disabledInputs=false,
    timeChat= new Date(),
    isGroup= false,
    searchText='',
    messagesUnRead = 0,
    members = [],
    sendWhatsappNotification,

    paramHeight
}) => {
    // chat ref
    const chatRef = useRef<HTMLDivElement>(null);

    // useState message data
    const [messages, setMessages] = useState<MessageInterface[]>([]);

    // useState message gruop by date to filtered
    const [messageGroup, setMessageGroup] = useState<MessageGroupInterface>({});

    // is fist message
    const [isFirstMessage, setIsFisrtMessage] = useState<boolean>(false);
    // type message
    const [typeMessage, setTypeMessage] = useState<MessageType>("text");
    // useState message text
    const [messageText,  setMessageText] = useState<string>("");

    // useState last time send message
    const [lastTimeSendMessage, setLastTimeSendMessage] = useState<Date>(new Date());

    const timeOutId = useRef<NodeJS.Timeout | null>(null);

    // method to get messages by chat id
    const getMessages = (chatId: string) => {
        getMessagesById(userId, chatId, (value)=> {

           if(value) {
                let tmp:any[] = Object.values(value);
                setMessageGroup(groupMessageByDate(tmp));
                setMessages(tmp);
           } else {
                setMessages([]);
                setMessageGroup({});
                setIsFisrtMessage(true);
           }
           executeScroll();
        });
    }

    // method to group messages by date
    const groupMessageByDate = (data: MessageInterface[]) => {
        // this gives an object with dates as keys
        const groupsData =  data.reduce((groups:any, item) => {
            var date = formatISO(new Date(item["created"]), { representation: 'date' });
            var group = groups[date] || (groups[date] = []);
            group.push(item);
            return groups;
        }, { });
        return groupsData;
    }

    // method to scroll boytom
    const executeScroll = useCallback(
        debounce((toPercent: number = 0.8)=>{ 
            if (chatRef && chatRef.current) {
                chatRef.current?.scrollTo({
                    top: chatRef.current.scrollHeight/toPercent,
                    behavior: 'smooth',
                });
            }
        }, 500),[]);

        
    // handle send message 
    const handleSendMessageClick = async () =>  {
        if((messageText).trim().length === 0) {
            return;
        }

        debounceNotification();
        saveMessage();
    }

    // method to save message and send push notification
    const saveMessage  = async () => {
        setMessageText('');
        let tmpMessage: MessageInterface = {
            userId: userId,
            typeMessage: typeMessage,
            userName: userName,
            content: messageText.trim(),
            created: new Date().toString(),
        }
       
        try {
            await pushMessage(chatId, tmpMessage);
            const response = await sendPushNotifications(chatId, `${userName}: ${messageText.trim()}`, userId);
           
        } catch (error) {
            console.log("🚀 ~ file: Chat.tsx:96 ~ handleSendMessageClick ~ error:", error)
            toast.error("Hubo un error al enviar el mensaje, por favor intente mas tarde");
        }  
    }

    // debounce whatsapp notification
    const debounceNotification = () => {
        let distanceDate = formatDistanceToNowStrict(lastTimeSendMessage, {unit: 'minute'});
        let numMin = Number(distanceDate.split(" ")[0]);
        // console.log("🚀 ~ file: Chat.tsx:147 ~ debounceNotification ~ numMin:", numMin)
        if(numMin === 0 || numMin > 20) {
            // console.log("send whatsapp notification")
            setLastTimeSendMessage(new Date()); 
            if(timeOutId.current) {
                clearTimeout(timeOutId.current);
            }
            timeOutId.current = setTimeout(() => {
                sendWhatsappNotification();
            }, 28000); 
        }        
   }
   
    // handle message text change
    const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(typeMessage !== "text") {
            setTypeMessage("text");
        }
        setMessageText(e.target.value);
    }

    const handleEmojiSelect = (emoji: string) => {
        setMessageText((prev) => prev + " " + emoji);
    }

    // method to update attachments
    const uploadAttachment = async (file_: any) => {
        try {
            const responseFile:any = await StoreService.UploadFile(file_, chatId+`_chat/attachments/`+file_.name);
            if(responseFile) {
                let attachData: AttachChat = {
                    userId: userId,
                    fileName: file_.name,
                    fileUrl: responseFile,
                    fileIcon: GetAttachIcon(file_.name, responseFile),
                    createdAt: new Date().toString(),
                    updatedAt: new Date().toString(),
                }  
                let tmpMessage: MessageInterface = {
                    userId: userId,
                    typeMessage: typeMessage,
                    userName: userName,
                    attach: attachData,
                    created: new Date().toString(),
        
                }
                pushMessage(chatId, tmpMessage);
                setMessageText('');
            }
        } catch (error) {
            // console.log("🚀 ~ file: InputChat.tsx:58 ~ uploadAttachment ~ error:", error)
            toast.error("Hubo un error al subir el archivo")
        }
        
    }

    // method to seach in messages 
    const searchTextInMessage = (text: string) => {
        const filtered = (messages.filter(m => (m.content?.toLocaleUpperCase().includes(text.toLocaleUpperCase())) ||
        m.attach?.fileName.toUpperCase().includes(text.toUpperCase()) ));   
        setMessageGroup(groupMessageByDate(filtered));
    }

    const markAsReadMessage = async () => {
        // console.log("🚀 ~ file: Chat.tsx:158 ~ markAsReadMessage ~ markAsReadMessage")
        try {
            await markAsUnread(chatId, members);
        } catch (error) {
            console.log("🚀 ~ file: Chat.tsx:162 ~ markAsReadMessage ~ error:", error)
            
        }
    }

    // method to masrk as read chat
    const markAsUnread = async (id: string, members: MemberChat[]) => {
        await UpdateChat(id, {
            members: members.map((m) => m.userId === userId ? {
                ...m,
                numMessagesUnread: 0,
            } : m)
        });

    }     
    // useEffect(() => {
    //     return debounceNotification.cancel();
    //   }, [debounceNotification]);

    // catch when chat id changed
    useEffect(() => {
         if(chatId && chatId.length>0 ) {
            getMessages(chatId);
        }
    }, [chatId]);

    useEffect(() => {
     if(searchText.length>0) {
        searchTextInMessage(searchText);
     } else {
        if(messages.length !==Object.keys(messageGroup) .length) {
            setMessageGroup(groupMessageByDate(messages));
        }
     }
    }, [searchText]);    

    return (
        <div className="chat">
            <div role='chatRole' 
                className={isGroup ? "chat-container box-shadow-card" : "chat-personal box-shadow-card"} 
                onClick={()=> messagesUnRead > 0 ? markAsReadMessage() : {}}> 
                <div className="chat-message" >
                    {  Object.keys(messageGroup) && Object.keys(messageGroup).length > 0 ? 
                        <div className="chat-list-message" ref={chatRef}>
                            { Object.keys(messageGroup).map((keylabel, idx) => {
                                    return (
                                        <MessageChat 
                                            key={idx} 
                                            messages={messageGroup[keylabel]} 
                                            labelDate={keylabel}
                                            userId={userId} 
                                            isGroup={isGroup}
                                        />
                                    )
                                }) 
                            }
                        </div> : 
                        <div className="paragraph-header chat-nothing">
                            {/* {chatId.length === 0 ? "Inicia una nueva conversación con una agencia" : isFirstMessage ? "Sin mensajes" : ""} */}
                            {chatId.length === 0 ? "Inicia una nueva conversación con una agencia" : isFirstMessage ? "" : ""}
                        </div>
                    }
                </div>
            {chatId.length>0 && <InputChat 
                    chatId={chatId}
                    userId={userId}
                    disabledInput={chatId.length===0} 
                    value={messageText} 
                    onChange={handleMessageChange} 
                    onClickSend={handleSendMessageClick}
                    setTypeMessage={setTypeMessage}
                    handleFileClick={uploadAttachment}
                    handleEmojiClick={handleEmojiSelect}
                />}
            </div>
        </div>
    )
}