import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type CreditCardProps = React.HTMLAttributes<HTMLDivElement> & {};

export const CreditCard: React.FC<CreditCardProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/credit_card.svg'} alt='Alarm' />
        </div>
    )
}