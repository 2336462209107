import React, { useEffect, useState } from "react"; 

import { toast } from "react-toastify";

/** importando componentes */
import { TextField } from "../../";
import { Button } from "../../";
import { ButtonIcon } from "../../ButtonIcon/ButtonIcon";
import { QuotationTransportOrigin } from "../../QuotationTransportOrigin/QuotationOriginTransport";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

/** importando modals */
import { Modal } from '../../Modal/Modal/Modal';

// importando iconos
import { ArkabiaOp } from "../../../icons/outline/ArkabiaOp";

/** importando css */
import './ModalQuoteSupplier.css';
import { ImportTimelineStages } from "../../../@types/timelineStagesTypes";
import { QuotationDataError } from "../../../interfaces/QuotationDataError";
import { RequestQuoteInterface } from "../../../interfaces/RequestQuote";

import { BoatGray } from "../../../icons/outline/BoatGray";
import { BoatWhite } from "../../../icons/outline/BoatWhite";
import { PlaneGray } from "../../../icons/solid/PlaneGray";
import { PlaneWhite } from "../../../icons/solid/PlaneWhite";
import { InfoCircle } from "../../../icons/outline/InfoCircle";

import { Checkbox } from "../../Checkbox/Checkbox";
import { Hover } from "../../Hover/Hover";
import { ValidateField } from "../../../utils/ValidateField";
import { CreateRequestQuote, UpdateRequestQuote } from "../../../Services/RequestQuote.service";
import { OperationInterface } from "../../../interfaces/Operation";
import { CreateOperation } from "../../../Services/Operation.service";
import { OPERATIONS, PROVIDER, REQUEST_QUOTE } from "../../../Consts/BaseUrl";
import { ToastWarning } from "../../ToastWarning/ToastWarning";

interface ModalQuoteSupplierProps {
    showModal: boolean; // estado boolean que cotrola el modal 
    setShowModal: (e: boolean) => void; // medoto para modificar la variable showModal
    editModal?: boolean; // estado boolean que cotrola la edicion modal
    phase?: ImportTimelineStages | number;
    quotatioErrors: QuotationDataError;
    setQuotationErrors?: React.Dispatch<React.SetStateAction<QuotationDataError>>;
    showButtonReturn?: boolean; // muestra el boton de retorno
    requestQuote: RequestQuoteInterface;
    setRequestQuote?: React.Dispatch<React.SetStateAction<RequestQuoteInterface>>;
    handleButtonReturn?: () => void; //funcion del boton de retorno
    companyId: string;
    companyName: string;
    serviceId: string;
    serviceRating: number;
    serviceType: string;
    serviceTypeLabel: string;
    numServiceComment: number;
}

export const ModalQuoteSupplier: React.FC<ModalQuoteSupplierProps> = ({
    showModal,
    setShowModal,
    phase=1,
    editModal=false, 
    quotatioErrors,
    setQuotationErrors=()=>{},
    showButtonReturn=false,
    requestQuote,
    setRequestQuote=()=>{},
    handleButtonReturn= ()=>{},
    companyId = '',
    companyName = '',
    serviceId = '',
    serviceRating = 0,
    serviceType = '',
    serviceTypeLabel = '',
    numServiceComment = 0,
    ...props
}) => {
    const [step, setStep] = useState<number>(editModal?2:1);
    const [title, setTitle] = useState<string>('¡Empecemos!'); // titulo del modal
    const [subTitle, setSubTitle] = useState<string>('Dale un nombre a tu cotización'); // subtitulo del modal
    const [load, setLoad] = useState<boolean>(false);
    
    const navigation = useNavigate();
    const location = useLocation();

    const [isHover, setIsHover] = useState(false);

    const handleMouseOver = () => {
        setIsHover(true);
    };
    
     const handleMouseOut = () => {
        setIsHover(false);
    };


    /** declarando useState */
    const [countWord, setCoundWord] = useState<number>(0);

    /** metodo para modificar el titulo de la cotizacion */
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if(value.length<=40) {
            setRequestQuote((prev)=>({...prev, title: value}));
            // setQuoteTitle(e.target.value);
            setCoundWord((value.length));
        }
    }

    const clearFields = () => {
        setLoad(false);
        setRequestQuote({
            title: "",
            number: 0,
            user: {
              id: '',
              name: '',
              typeIt: '',
              itNumber: 0,
              numOperations: 0,
              phone: '',
              rating: 0,
            },
            companyName: '',
            serviceId: '',
            serviceTypeId: '',
            serviceTypeLabel: '',
            status: 'published',
            statusProvider: 'No leída',
            active: true,
            customs: {
              regime: 'Importación definitiva',
              incoterm: 'FOB',
              value: 0,
              coin: '',
              insurance: false,
              merchandisePermits: {
                dataSheet: [],
                proforma: [],
                packingList: [],
              },
              exwDirection: '',
              descriptionOperation: ''
            },
            transport: {
              type: 'sea',
              origin: {
                city: '',
                country: '',
              },
              destination: {
                city: '',
                country: '',
              },
              typeContainer: {
                lcl: false,
                fcl: false,
                lclAir: false
              }, 
            },
            phaseDraft: 1,
            operationId: '',
            numServiceComments: 0,
            numServiceRating: 0,
            observation: '',
            category: 'Importación',
            createdAt: new Date(),
            updatedAt: new Date(),
            quoteGenerated: true
        });
    }

    const saveData = async () => {
        try {
            const response:any = await CreateRequestQuote(requestQuote);
            // navigation()
            if(response) {
                let operation: OperationInterface = {
                    title: requestQuote.title,
                    active: true,
                    currency: 'USD $',
                    numOperation: 0,
                    orderId: '',
                    orders: [],
                    paid: false,
                    price: 0,
                    requestQuoteId: '',
                    requestQuoteType: 'agenciamiento-de-aduana',
                    requestQuotes: [{
                        id: response.id,
                        active: true,
                        city: '',
                        companyName: companyName,
                        companyId: companyId,
                        serviceId: serviceId,
                        country: {
                            alpha2Code: '',
                            imgFlag: '',
                        },
                        numRequestQuote: 0,
                        quoteGenerated: true,
                        numServiceComments: numServiceComment,
                        observation: '',
                        price: 0,
                        quotationId: '',
                        rating: 0,
                        serviceRating: serviceRating,
                        serviceType: serviceType,
                        serviceTypeLabel: serviceTypeLabel,
                        createdAt: new Date(),
                        updatedAt: new Date()
                    }],
                    status: {
                        requestQuote: true,
                        order: false,
                    },
                    type: 'Importación',
                    userId: requestQuote.user.id,
                    createdAt: new Date(),
                    updatedAt: new Date()
                } 
                
                const responseOperation = await CreateOperation(operation);

                if(responseOperation) {
                    const responseRQ = await UpdateRequestQuote({
                        id: response.id,
                        operationId: responseOperation.id,
                        numServiceComments: numServiceComment,
                        serviceId: serviceId,
                        numServiceRating: serviceRating,
                        serviceTypeId: serviceType,
                        serviceTypeLabel: serviceTypeLabel,
                        companyId: companyId,
                        companyName: companyName,
                        category: 'Importación',
                        active: true,
                        updatedAt: new Date(),
                        createdAt: new Date()
                    });
                    clearFields();
                    setLoad(false);
                    redirectToQuotation(response.id);
                    
                }
            }
        } catch (error) {
            console.log("🚀 ~ file: ModalQuoteSupplier.tsx:172 ~ handleNextClick ~ error:", error);
        }    
    }

    // metodo apra redireccion a la cotizacion
    const redirectToQuotation = (requestQuoteId: string) => {
        let urlLocation = (location.pathname).split('/');
        let url: string = '';

        // if(urlLocation.length === 2 && urlLocation[1] === PROVIDER) {
        //     url += OPERATIONS+"/";
        // }
        navigation(`/${PROVIDER}/${OPERATIONS}/${url}${REQUEST_QUOTE}/${requestQuoteId}`);
    }

    const handleNextClick = async () => {
        let error = validatedFields();
        if(!error) {
            setLoad(true);
            if(step===1) {
                setSubTitle('Cotización');
                setTitle(requestQuote.title);
                setStep(2);
            } else {
                if(!toast.isActive('confirm')) {
                    toast(
                        <ToastWarning
                            title = "¿Estos son los datos correctos? La plantilla no será modicable."
                            textButtonCancel = "No"
                            textButtonAccept = "Si"
                            onClickCancel={()=>setLoad(false)}
                            onClickAccept={()=>saveData()}
                        />, {
                            hideProgressBar: true,
                            autoClose: 7000,
                            toastId: 'confirm'
                        }
                    );
                }                
            }
            setLoad(false);
        }
    }

    const handleBack = () => {
        setStep(1);
        setLoad(false);
    }

    const handleTransportationTypeChange = (value: "air" | "sea") => {
        setRequestQuote((prev:any) => ({
            ...prev,
            transport: {
                ...prev.transport,
                type: value
            }
        }));
    }

    const handleIsChecked = (value: boolean) => {
        setRequestQuote((prev: any) => ({
            ...prev,
            customs: {
                ...prev.customs,
                insurance: value
            }
        }));
    }

    const handleIsCheckedLCL = () => {
        setRequestQuote((prev: any) => ({
            ...prev,
            transport: {
                ...prev.transport,
                typeContainer: {
                    ...prev.transport.typeContainer,
                    lcl: !requestQuote.transport?.typeContainer?.lcl
                }
            }
        }));
    }

    const handleIsCheckedFCL = () => {
        setRequestQuote((prev: any) => ({
            ...prev,
            transport: {
                ...prev.transport,
                typeContainer: {
                    ...prev.transport.typeContainer,
                    fcl: !requestQuote.transport?.typeContainer?.fcl
                }
            }
        }));
    }

    const handleIsCheckedLCLAir = () => {
        setRequestQuote((prev: any) => ({
            ...prev,
            transport: {
                ...prev.transport,
                typeContainer: {
                    ...prev.transport.typeContainer,
                    lclAir: !requestQuote.transport?.typeContainer?.lclAir
                }
            }
        }));
    }

    const validatedFields = () => {
        let error = false; 
        if(step===1) {
            error = ValidateField(requestQuote.title, 'required');
            setQuotationErrors((prev) => ({
                ...prev,
                title: {
                    status: ValidateField(requestQuote.title, 'required'),
                    message: 'Ingrese un título para la operación'
                }
            }))
        } else if(step===2) {

            error = ValidateField(requestQuote.transport?.type, 'required') || !(requestQuote.transport?.typeContainer?.fcl || requestQuote.transport?.typeContainer?.lcl || requestQuote.transport?.typeContainer?.lclAir )
            || ValidateField(requestQuote.customs?.insurance, 'boolean');

            setQuotationErrors((prev:any) => ({
                ...prev,
               transportation: {
                    ...prev.transportation,
                    typeContainer: {
                        status: requestQuote.transport?.type === 'sea' ? !(requestQuote.transport?.typeContainer?.fcl || requestQuote.transport?.typeContainer?.lcl) : !(requestQuote.transport?.typeContainer?.lclAir),
                        message: 'Seleccione un contenedor'
                    }
               },
               customs: {
                ...prev.customs,
                insurance: {
                    status: ValidateField(requestQuote.customs?.insurance, 'boolean'),
                    message: 'Seleccione si desea seguro'
                }
               }
            }))
        }
        return error
    }

    const handleCloseClick = () => {
        clearFields();
    }

    useEffect(() => {
        setRequestQuote((prev) => ({
            ...prev,
            companyId: companyId,
            companyName: companyName,
            serviceId: serviceId,
            serviceTypeId: serviceType,
            serviceTypeLabel: serviceTypeLabel,
            numServiceComments: numServiceComment,
            numServiceRating: serviceRating,
        }));
    }, [])

    return(
        <div role="modal-quoteSupplier" className="modalQuoteSupplier-container">
            <Modal disableToast title={title} subTitle={subTitle} show={showModal} onClose={handleCloseClick} setShow={setShowModal} icon={step>1 ?<ArkabiaOp />: null}>
                {step === 1 ? 
                    <>
                        <div className="modalQuoteSupplier-content">
                            <TextField 
                                value={requestQuote.title} 
                                title="" 
                                placeholder="Ej: Carros de juguete para navidad" 
                                onChange={handleTitleChange} 
                                role='text-modal-quoteSupplier'
                                type='text'
                                error={quotatioErrors?.title} 
                            />
                            <div className="smalltext modalQuote--number"> {countWord} / 40 caracteres</div>
                        </div>
                        <div className="modalQuoteSupplier--button">
                            {showButtonReturn && <Button content="Volver" color="black-25" onClick={handleButtonReturn}/>}
                            <Button content={`${load ? 'Cargando ...': 'Siguiente'}`} onClick={!load?()=>handleNextClick():()=>{}} role="btn-modalSupplier"/>
                        </div>
                </> : 
                (step === 2) && 
                <div className="modalQuoteSupplier-inputOriginDestiny-column">
                    <div className="modalQuoteSupplier-inputOriginDestiny-content">
                        <div className="paragraph-header">Medio de transporte</div>
                        <div className="modalQuoteSupplier-inputOriginDestiny-row-button">
                            <ButtonIcon 
                                color={requestQuote.transport?.type==='air' ? 'yellow': 'black-25'} 
                                onClick={()=>handleTransportationTypeChange("air")} 
                                content="Aéreo" Icon={requestQuote.transport?.type==='air'?<PlaneGray />:<PlaneWhite />} 
                                size="large" className={requestQuote.transport?.type==='air' ? "inputOriginDestiny-btn-color":''}/>
                                
                            <ButtonIcon color={requestQuote.transport?.type==='sea' ? 'yellow': 'black-25'} 
                                onClick={()=>{handleTransportationTypeChange('sea');}} content="Marítimo" 
                                Icon={requestQuote.transport?.type==='sea'?<BoatGray />: <BoatWhite />} 
                                size="large" className={requestQuote.transport?.type==='sea' ? "inputOriginDestiny-btn-color":''}/>
                            {/* <ButtonIcon color={type==='land' ? 'yellow': 'black-25'} onClick={()=>setType('land')} content="Terrestre" Icon={type==='land'?<CarGray />: <CarWhite />} size="large" className={type==='land' ? "inputOriginDestiny-btn-color":''}/> */}
                        </div>
                        {(quotatioErrors.transportation.type.status) && <div className="text-error tinytext-header">{quotatioErrors.transportation.type.message}</div>}
                    </div>

                    <div className="modalQuoteSupplier-inputOriginDestiny-content-min">
                        <div className="paragraph-header">Forma de envío</div>
                    </div>

                    <div className="modalQuoteSupplier-inputOriginDestiny-content_row modalQuoteSupplier-inputOriginDestiny-content_row_">
                        <div className="modalQuoteSupplier-inputOriginDestiny-row">
                            <Checkbox checked={requestQuote.transport?.type === 'sea' ? requestQuote.transport?.typeContainer?.lcl : requestQuote.transport?.typeContainer?.lclAir} setChecked={()=>requestQuote.transport?.type === 'sea' ? handleIsCheckedLCL() : handleIsCheckedLCLAir()} />
                            <div className="modalQuoteSupplier-inputOriginDestiny-info">
                                <div className="paragraph-header">Contenedor compartido</div>
                                <div className="smalltext">LCL</div>
                            </div>
                        </div>

                       { requestQuote.transport?.type === 'sea' && <div className="modalQuoteSupplier-inputOriginDestiny-row">
                            <Checkbox checked={requestQuote.transport?.typeContainer?.fcl} setChecked={()=>handleIsCheckedFCL()} />
                            <div className="modalQuoteSupplier-inputOriginDestiny-info">
                                <div className="paragraph-header">Contenedor completo</div>
                                <div className="smalltext">FCL</div>
                            </div>
                        </div>}
                    </div>
                    {(quotatioErrors.transportation.typeContainer.status) && <div className="text-error tinytext-header">{quotatioErrors.transportation.typeContainer.message}</div>}

                    <div className="modalQuoteSupplier-inputOriginDestiny-content_row modalQuoteSupplier--ensure">
                        <div className="paragraph-header modalQuoteSupplier-inputOriginDestiny-row_min">
                            ¿Desea seguro?
                            <Hover
                                title="¿Qué es seguro?"
                                content="Es una garantía de indemnización para tus mercancías en caso ocurra algún siniestro durante su transporte internacional."
                                show={isHover}
                                setShow={setIsHover}
                            >
                                <InfoCircle 
                                    className="insurance--icon"
                                    onMouseOver={handleMouseOver} 
                                    onMouseOut={handleMouseOut}
                                />
                            </Hover>
                        </div>

                        <div className="modalQuoteSupplier-inputOriginDestiny-row_min modalQuoteSupplier-inputOriginDestiny-row_min_">
                            <div className="modalQuoteSupplier-inputOriginDestiny-row_min">
                                <Checkbox checked={requestQuote.customs?.insurance===true} setChecked={()=>handleIsChecked(true)} />
                                <div className="paragraph-header">Si, deseo</div>
                            </div>
                            <div className="modalQuoteSupplier-inputOriginDestiny-row_min">
                                <Checkbox checked={requestQuote.customs?.insurance===false} setChecked={()=>handleIsChecked(false)} />
                                <div className="paragraph-header">No, deseo</div>
                            </div>
                        </div>
                        {(quotatioErrors.customs.insurance.status) && <div className="text-error tinytext-header">{quotatioErrors.customs.insurance.message}</div>}
                    </div>

                    <div className="modalQuoteSupplier-quotationTransportOrigin-footer">
                        <Button role="btn-modalSupplier" content="Volver" color='black-25' onClick={handleBack} />
                        <Button content={`${!load ? 'Siguiente' : 'Cargando'}`} color='black-75' onClick={handleNextClick} />
                    </div>
                </div>}
            </Modal>
        </div>
    )
}