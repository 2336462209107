import React from "react";
import { Button } from "../Button/Button";
import { Modal } from "../Modal/Modal/Modal";

import './PaySuccessful.css';
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

interface PaySuccessfulProps {
    show: boolean;
    companyName: string;
    amount: number;
    setShow: (e: boolean) => void;
    handleBack: () => void;
}

export const PaySuccessful: React.FC<PaySuccessfulProps> = ({
    show,
    companyName,
    amount,
    setShow,
    handleBack
}) => {
    return (
       <Modal disableToast title="Confirma tu compra" show={show} setShow={setShow}>
            <div className="paySuccessful-container">
                <img className="paySuccessful-icon " src={STORAGE_ASSETS+"/images/png/check_yellow.png"} />
                <div className="paragraph paySuccessful-colorText ">Gracias por confiar en {companyName}, se ha cargado USD $ {amount} a tu tarjeta.</div>
            </div>
            <div className="paySuccessful-button">
                <Button 
                    content='Volver'
                    size="normal"
                    color='black-25'
                    onClick={handleBack}
                />
            </div>
       </Modal>
    )
}