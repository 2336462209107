import React, { useState } from "react";

// Importando componentes
import { CargoStatus } from "../CargoStatus/CargoStatus";
import { DashboardElementsRow } from "../DashboardElementsRow/DashboardElementsRow";
import { Badge } from "../Badge/Badge";
import { HeaderElementsMobile } from "../DashboardElementsMobile/HeaderElementsMobile/HeaderElementsMobile";

// Importando iconos
import { ArrowUp } from "../../icons/outline/ArrowUp";
import { ArrowDown } from "../../icons/solid/ArrowDown";
import { ArkabiaOp } from "../../icons/outline/ArkabiaOp";

// Importando types
import { ServiceChargeStatus } from "../../@types/serviceChargeStatus";

// Importando estilos
import './DashboardElementsSupplier.css';
import { ContentQuote } from "../ContentQuote/ContentQuote";
import { GetCurrency } from "../../utils/GetCurrency";

interface DashboardElementsSupplierProps {
    id: string; // id de la solicitud de cotizacion
    openDashboard?: boolean; // contrala si esta abierto el collapse
    title: string; // titulo
    badge?:boolean;
    statusProvider: string; // estado de la cotizacion proveedor
    cargoStatus: ServiceChargeStatus;
    companyName: string,
    photoURL: string,
    number:string;
    date: string;
    header: any [];
    quote?:boolean;
    elementsRow: any[], // Es un array dentro de otro array
    handleClickRow: () => void;
    numberOrder?: number,
    clientName: string,
    type: string;
    handleClickStatus: (id: string, e: string) => void;
}

export const DashboardElementsSupplier: React.FC<DashboardElementsSupplierProps> = ({
    id,
    openDashboard = false,
    title,
    badge = false,
    cargoStatus,
    companyName,
    photoURL,
    number,
    date,
    header,
    handleClickRow,
    elementsRow,
    quote = false,
    statusProvider,
    numberOrder=0,
    clientName,
    type,
    handleClickStatus,

}) => {

    const [expanded, setExpanded] = useState(openDashboard);
    
    return (
        <>
            <div className="dashboardElements-supplier box-shadow-card">
                <div  className={`dashboardElements-supplier-header ${!expanded && "dashboardElements-supplier-header__border"}`}>
                    <div className="dashboardElements-supplier-content-left" onClick={()=> setExpanded(!expanded)}>
                        <ArkabiaOp/>
                        <div className="dashboardElements-supplier-title">
                            <div className="smalltext-header dashboardElements-supplier__title">{title}</div>
                            <div className="smalltext dashboardElements-supplier__detail">{type}</div>
                        </div>
                        <div className="dashboardElements-supplier-explain">
                            {expanded?<ArrowUp /> :<ArrowDown />}
                        </div>
                    </div>
                    
                    <div className="dashboardElements-supplier-content-right">
                        {quote ? 
                        <ContentQuote 
                            requestId={id}
                            statusLabel={statusProvider} 
                            number={numberOrder}
                            handleClickStatus={handleClickStatus}
                            onClick={()=> setExpanded(expanded ? expanded : !expanded)}
                        /> :
                        <>
                            { badge ? null :<CargoStatus phase={cargoStatus} date={date} onClick={()=> setExpanded(!expanded)}/> }
                        </>}
                        
                            <div className="tinytext-header dashboardElements-date-text" onClick={()=> setExpanded(!expanded)}>{date}</div>
                            { badge ? <Badge number={number} /> : null}
                        {expanded?<ArrowUp onClick={()=> setExpanded(!expanded)}/> :<ArrowDown onClick={()=> setExpanded(!expanded)}/>}
                    </div> 
                </div>

                {/** Contenido al expandir */}
                { expanded && <div className="dashboardElements-supplier-content">
                    <div className="dashboardElements-supplier-content-header">
                        { header.map((val, idx) => (
                            <div key={idx} className="paragraph dashboardElements-supplier-content-title">{val}</div>
                          ))}
                    </div>
        
                    <DashboardElementsRow handleClick={handleClickRow}
                                          elementsRow={elementsRow} 
                                          title={clientName}/> 

                    {/** version mobile */}
                    <>
                        <div className="dashboardElements-supplier-content-header-mobile">
                            <HeaderElementsMobile 
                                // nameUser = {clientName}
                                aduana = {clientName}
                                // user = {photoURL} 
                            />
                        </div>
                        
                        <div className="dashboardElements-supplier-content-body-mobile" onClick={handleClickRow}>
                            <div className="dashboardElements-supplier-content-body-mobile-container">
                                <div className="smalltext-header">Último mensaje</div>
                                <div className="dashboardElements-supplier-content-body-mobile-row__">
                                    <div className="smalltext dashboardElements-supplier-content-body-mobile-text">{elementsRow[0][2].detail}</div>
                                    {true &&
                                    <div className="dashboardElements-supplier-content-body-mobile-alert"></div>}
                                </div>
                                    </div>
                            <div className="dashboardElements-supplier-content-body-mobile-container">
                                <div className="smalltext-header">Cliente</div>
                                <div className="smalltext">{clientName.length>0 ? clientName : '-'}</div>
                            </div>

                            <div className="dashboardElements-supplier-content-body-mobile__row">
                                <div className="dashboardElements-supplier-content-body-mobile-container">
                                    <div className="smalltext-header">Origen</div>
                                    <div className="smalltext dashboardElements-supplier-content-body-mobile-text__">{elementsRow[0][0].detail}</div>
                                </div>
                                <div className="dashboardElements-supplier-content-body-mobile-container">
                                    <div className="smalltext-header">Medio</div>
                                    <div className="smalltext dashboardElements-supplier-content-body-mobile-text__">{elementsRow[0][0].subDetail}</div>
                                </div>
                                <div className="dashboardElements-supplier-content-body-mobile-container">
                                    <div className="smalltext-header">Valor</div>
                                    <div className="smalltext dashboardElements-supplier-content-body-mobile-text__">{elementsRow[0][1].detail}</div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>

                }
            </div>
        </>
    )
}