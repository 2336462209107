import React, { useEffect, useState } from "react";


import "./SuscriptionPage.css";
import { Suscription } from "../../../../components/Suscription/Suscription";
import { AdminPaid } from "../../../../components/AdminPaid/AdminPaid";
import { useNavigate } from "react-router-dom";
import { CHANGE_PLAN, OPERATIONS, PLAN } from "../../../../Consts/BaseUrl";
import { useSelector } from "react-redux";
import { subscriptionInterface } from "../../../../interfaces/SubscriptionInterface";
import { GetSubscriptionById } from "../../../../Services/Subscription.service";
import { toast } from "react-toastify";
import { Loader } from "../../../../components/Loader/Loader";

interface SuscriptionPageProps {} 

export const SuscriptionPage: React.FC<SuscriptionPageProps> = () => {

  const navigation = useNavigate();

  const { userDetail } = useSelector((state: any) => state.user);

  const [activeComponent, setActiveComponent] = useState('default');


  const [loading, setLoading] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<subscriptionInterface>();

  const handleAdPaid = () => {
    // if(scheduleOperation && scheduleOperation.type === "cancelSubscription") {
    //   toast.error("No puedes administrar la forma de pago, ya que tienes una cancelación de suscripción programada");
    // } else {
      setActiveComponent('adminPaid');
    // }
  }

  const handleChangePlan = () => {
    navigation(`/${CHANGE_PLAN}`, { replace: true })
  }


  // method to get the subscription of the user
  const getSubscriptionByUser = async () => {
    try {
      setLoading(true);
      const response = await GetSubscriptionById(userDetail.subscriptionId);
      if(response) {
        setSubscription(response);
      }
      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ getSubscriptionByUser ~ error:", error);
      setLoading(false);
      toast.error("Error al obtener la suscripción");
    }
  }


  useEffect(() => {
    activeComponent === "default" && getSubscriptionByUser();
  }, [activeComponent]);


  return (
    <div className="suscriptionPage">
      {!loading && subscription  ? (activeComponent === 'default' ? 
        <Suscription
          subscription={subscription}
          handleAdministrar={handleAdPaid}
          handlePlan={handleChangePlan}
        /> :
        <AdminPaid
          subscriptionId={subscription.id || ''}
          handleGoBack={()=>setActiveComponent('default')}
        />
      ): <Loader />}
    </div>
  );
};
