import React from "react";
import { loaderText } from "../../Consts/LoaderText";

import './LoaderText.css'

export const LoaderText: React.FC<{}> = ({...props}) => {
    
    return (
        <div className="loaderText-container">
            <div className="loaderText-animate">
             <div {...props} className="loaderText">
                <div className="loaderText-img">
                    <img src="https://www.arkabia.com/images/lupa.svg" alt="" />
                </div>
                <div className="loaderText-text">
                    <div className="loaderText-colorText small-header">Consejo del día</div>
                    <div className="loaderText-colorText small-subheader">{loaderText[Math.floor(Math.random() * 9)]}</div>
                </div>
            </div>
        </div>
        </div>
    )
}