import React, { useEffect, useState } from "react";

import { PhotoUnit } from "../..";

import "./UploadPhoto.css";

interface UploadPhotoProps {
  title: string;
  subtitle: string;
  setValueImages?:React.Dispatch<React.SetStateAction<any[]>>;
  handleSendImage?: (itemImg: {name: string, url: string})=>void;
  imageValues?: any[] | ArrayBuffer[];
  disabled?: boolean;
  role?: string;
  disabledImages?:boolean;
  deleteImage?: (i:number)=>void
  handleUploadArray?: (itemImg: {name: string, url: string}[]) => void
}

export const UploadPhoto: React.FC<UploadPhotoProps> = ({
  title,
  subtitle,
  imageValues=[],
  disabledImages = false,
  handleSendImage = ()=>{},
  setValueImages=()=>{},
  disabled=false,
  deleteImage=()=>{},
  handleUploadArray=()=>{},
  role
}) => {

  const [loading, setLoading] = useState<boolean>(true)
  const [photoArray, setPhotoArray] = useState<any[]>([
    {
      name: '', 
      url: ''
    },
    {
      name: '', 
      url: ''
    },
    {
      name: '', 
      url: ''
    },
    {
      name: '', 
      url: ''
    },
    {
      name: '', 
      url: ''
    }
  ]);
  
  const handlePhotoArrayChange = async (idx: number, dataImg: string | ArrayBuffer | null, name: string) => {
    if(dataImg!==null) {
      let tmp: any[] = [...photoArray];
      // let index = photoArray.findIndex((t => t.id === (productNumber+1)));
      // console.log("🚀 ~ file: Quotation.tsx ~ line 150 ~ handlePhotoChange ~ tmp", tmp, index)
      let photoTmp = {...tmp[idx]};
      // console.log("🚀 ~ file: UploadPhoto.tsx ~ line 57 ~ handlePhotoArrayChange ~ photoTmp", photoTmp)
      photoTmp.url = dataImg;
      photoTmp.name = name;
      // console.log("🚀 ~ file: UploadPhoto.tsx ~ line 36 ~ handlePhotoArrayChange ~ photoTmp",  photoTmp)
      tmp[idx] = photoTmp;
      // console.log("🚀 ~ file: UploadPhoto.tsx ~ line 62 ~ handlePhotoArrayChange ~ tmp", tmp)
      // console.log(tmp[index]);
      setPhotoArray(tmp);
      // let imagesTmp: any[] = photoArray;
      // imagesTmp[idx] = [...tmp];
      // console.log("🚀 ~ file: UploadPhoto.tsx ~ line 67 ~ handlePhotoArrayChange ~ imagesTmp", imagesTmp)
      // setValueImages((prev)=> imagesTmp);
      handleUploadArray(tmp);
      await handleSendImage(photoTmp);
    } else {
      await deleteImage(idx)
    }
  }

  // cargando array local de fotos
  useEffect(() => {
    setLoading(true)
    if(imageValues.length>0 && imageValues.length<=5) {
      let tmp: any[] = photoArray;
      imageValues.forEach((i, idx) => {
        tmp[idx] = i;
      });
      setPhotoArray(tmp);
    }
    setLoading(false)
  }, []);


  return (
    <div className="uploadphoto box-shadow-card" role={role}>
      <div className="uploadphoto-container">
       {title.length>0 && <h1 className="paragraph-header uploadphoto-title">{title}</h1>}
       {subtitle.length>0 && <p className="paragraph">{subtitle}</p>}
        <div className="uploadphoto-photos">
          {!loading && photoArray.map((item, idx) => (
            <PhotoUnit 
              idx={idx}
              key={idx} 
              disabled={disabled}
              imageSrc={item.url}
              setImageData={handlePhotoArrayChange} 
              role='photo-unit__'/>
          ))}
        </div>
      </div>
    </div>
  );
};
