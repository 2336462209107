import React, { useState } from "react";

/** importando componentes */
import { Dropdown } from "../Dropdown/Dropdown";
import { UploadText } from "../UploadFile/UpdateText/UpdateText";
import { Checkbox } from "../Checkbox/Checkbox";
import { Quotation } from "../ItemInfo/Quotation/Quotation";

/** importando iconos */

import { DocsFileValidation, SpreadSheetFileValidation, PDFFileValidation, ImageFileValidation } from "../../Consts/FilesValidation";

/** importando interfaces */
import { CustomsInterface } from "../../interfaces/CustomsInterface";

// importando utilidades 
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";

/** importando estilos */
import './QuoteCustomsData.css';
import { TextField } from "../TextField/TextField";
import { Textarea } from "../Textarea/Textarea";
import { TextAreaInput } from "../TextAreaInput/TextAreaInput";


interface QuoteCustomsDataProps {
    fileValue: any[];
    handleFileValue: React.Dispatch<React.SetStateAction<any[]>>;
    role?: string
    customs: CustomsInterface | undefined
    isCourier?: boolean
}

export const QuoteCustomsData: React.FC<QuoteCustomsDataProps> = ({
    fileValue,
    handleFileValue,
    role,
    customs = {
        regime: '',
        incoterm: '',
        value: 0,
        coin: '',
        insurance: undefined,
        exwDirection: '',
        merchandisePermits: {
            dataSheet: [],
            proforma: [],
            packingList: [],
        },
        descriptionOperation: '',
    },
    isCourier = false
}) => {

    const [isChecked, setIsChecked] = useState(false);
    return (
        <div className="new-quote-customs-data" role={role}>
            <div className="smalltext-header new-quote-customs-data-title">Aduana</div>

            {/* <div className="new-quote-customs-data-regimen">
                <Dropdown title="Régimen aduanero" value={customs.regime} elements={[customs.regime]} setValue={()=>{}} disabled />
            </div> */}

            <div className="new-quote-customs-data-row__">
                <TextField 
                    title={window.innerWidth< 600 ? "Valor" : "Valor de la mercancía" }
                    placeholder=" " 
                    value={ConverMoneyFormat(customs.value+"")} 
                    disabled 
                />
                <TextField 
                    title="Moneda"
                    placeholder=" " 
                    value={customs.coin} 
                    disabled 
                />
                {!isCourier && <TextField 
                    title="Incoterm"
                    placeholder=" " 
                    value={customs.incoterm}
                    disabled 
                /> }       
            </div>

            { isCourier &&
                <div className="new-quote-customs-data-row__">
                    <TextAreaInput title="Link de compra" value={customs.purchaseLink} />
                </div>
            
            }
            {customs.incoterm === 'EXW' && customs.exwDirection && <div className="new-quote-customs-column__">
                <TextField 
                    title="Dirección de recojo de la mercancía" 
                    placeholder=" " 
                    value={customs.exwDirection} 
                    disable={true} 
                />    
            </div>}

           {!isCourier && <div className="new-quote-customs-column__ new-quote-customs-column__seguro">
                {/* <div className="tinytext new-quote-customs-data-title___"> Importación definitiva </div> */}
                <div className="new-quote-customs-data-row__check">
                    <div className="smalltext-header new-quote-customs-data-title new-quote-customs-data-title__">¿Deseas seguro? </div>
                    <div className="new-quote-customs-data-check">
                        <div className="new-quote-customs-data-center new-quote-customs-data-row__check_">
                            <Checkbox setChecked={setIsChecked} checked={customs.insurance} disabled/>
                            <div className="smalltext new-quote-customs-data-row__check_answer">{window.innerWidth< 600 ? "Sí" : "Sí, deseo"}</div>
                        </div>
                        <div className="new-quote-customs-data-center new-quote-customs-data-row__check_">
                            <Checkbox setChecked={setIsChecked} checked={!customs.insurance} disabled/>
                            <div className="smalltext new-quote-customs-data-row__check_answer">{window.innerWidth< 600 ? "No" : "No, gracias"}</div>
                        </div>
                    </div>
                </div>
            </div>}

            { customs.merchandisePermits?.proforma && customs.merchandisePermits.proforma.length>0 && 
            <div className="new-quote-customs-data-row__ new-quote-customs-data-row__upload">
                { customs.merchandisePermits?.proforma && customs.merchandisePermits.proforma.length>0 && 
                <div className={"new-quote-customs-data-upload "+( isCourier? "new-quote-customs-data-upload--courier" : "")}>
                    <div className="paragraph-header new-quote-customs-data-upload-text">Proforma</div>
                    <div className="new-quote-customs-data-upload--attached">
                        {customs.merchandisePermits.proforma.map((p, idx) => (
                                <UploadText 
                                    key={idx}
                                    fileValue={fileValue}
                                    fileUrl={p.url}
                                    fileName={p.name}
                                    handleFileValue={handleFileValue}
                                    />
                        ))}
                    </div> 
                </div>}
                { customs.merchandisePermits?.packingList && customs.merchandisePermits.packingList.length>0 && 
                <div className="new-quote-customs-data-upload">
                    <div className="paragraph-header new-quote-customs-data-upload-text">Lista de empaque</div>
                    <div className="new-quote-customs-data-upload--attached">
                        {customs.merchandisePermits.packingList.map((d, idx) => (
                                <UploadText 
                                    key={idx}
                                    fileValue={fileValue}
                                    fileUrl={d.url}
                                    fileName={d.name}
                                    handleFileValue={handleFileValue}
                                    />
                            ))}  
                    </div>
                    
                </div>}
                { customs.merchandisePermits?.dataSheet && customs.merchandisePermits.dataSheet.length>0 && 
                <div className={"new-quote-customs-data-upload "+( isCourier? "new-quote-customs-data-upload--courier" : "")}>
                    <div className="paragraph-header new-quote-customs-data-upload-text">Ficha técnica</div>
                    <div className="new-quote-customs-data-upload--attached">
                        {customs.merchandisePermits.dataSheet.map((d, idx) => (
                                <UploadText 
                                    key={idx}
                                    fileValue={fileValue}
                                    fileUrl={d.url}
                                    fileName={d.name}
                                    handleFileValue={handleFileValue}
                                    />
                            ))}  
                    </div>
                </div>}
            </div>}

            { customs.descriptionOperation && customs.descriptionOperation.length>0 && 
                <div className="smalltext-header new-quote-customs-data-title new-quote-customs-data-title_situation">Situación</div>
            }
            { customs.descriptionOperation && customs.descriptionOperation.length>0 && 
            <div className="new-quote-customs-column__">
                <Textarea
                    title="Situación o comentario"
                    value={customs.descriptionOperation}
                    disable={true} 
                />  
            </div>}
            
        </div>
    )
}