import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type ClockBlackProps = React.HTMLAttributes<HTMLDivElement> & {};

export const ClockBlack: React.FC<ClockBlackProps> =({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+"/images/svg/clock_black.svg"} alt="help_circle" />
        </div>
    )
}