import React, { useEffect, useRef, useState } from 'react';

import './EightSeccion.css';
import { CardComment } from '../../CardComment/CardComment';
import { CommentsConsts } from '../../../Consts/CommentsConsts';
import { CarouselInfinitive } from '../../CarouselInfinitive/CarouselInfinitive';
import { useNavigate } from 'react-router-dom';
import { PLAN } from '../../../Consts/BaseUrl';

interface EightSeccionProps {
}

export const EightSeccion: React.FC<EightSeccionProps> = ({

}) => {

    const listRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const handlePlan = () => {
        navigate("/"+PLAN);
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        const list = listRef.current;
        if (!list) return;

        const scrollList = () => {
            if (list.scrollLeft >= list.scrollWidth / 2) {
                list.scrollLeft = 0;
            } else {
                list.scrollBy({ left: 1, behavior: 'smooth' });
            }
        };

        const intervalId = setInterval(scrollList, 20);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="eight-section">
            <div className="eight-section-header">
                <div className='eight-section-text'>4.9/5</div>
                <div className='eight-section-review-start'>
                    {[...Array(5)].map((s, idx) =>
                        <div key={idx} className='eight-section-start'></div>
                    )}
                </div>
                <div className='big-subheader eight-section-white'>basado en 362 reseñas de</div>
                <img className='landing-first-img' src='./images/google-review.png'/>
            </div>
            <div className="eight-btn" onClick={()=>handlePlan()}>
                Probar 
                <span className="eight-btn-resalt">AHORA</span>
            </div>

            {/* <div className='eight-section-list-comment' ref={listRef}>
                {CommentsConsts.concat(CommentsConsts).map((c, idx) =>
                    <CardComment
                        key={idx}
                        commentItem={c}
                    />
                )}
            </div> */}

            <CarouselInfinitive/>
        </div>
    );
};