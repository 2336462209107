import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type PortBlackProps = React.HTMLAttributes<HTMLDivElement> & {};

export const PortBlack: React.FC<PortBlackProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/port_black.svg"} alt="" />
    </div>
  );
};
