import React, {useEffect, useRef, useState} from "react";

import { Outlet, useNavigate, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

/** importando componentes*/
import { Navbar } from "../../../components";
import { Footer } from "../../../components"; 

import { SERVICE, OPERATIONS,PROVIDER, CHAT, DETAIL, AUTH_MODAL, AUTH_LOGIN, AUTH_REGISTER, TOOLS, PLAN, FREQUENTLY_QUESTIONS } from '../../../Consts/BaseUrl';

import './AdminPage.css';
import { Auth, getAuth, onAuthStateChanged } from "firebase/auth";
import { WhatsappWidget } from "../../../components/WhatsappWidget/WhatsappWidget";
import { AppDispatch } from "../../../Store/Store";
import { setAuth } from "../../../Slices/Auth";

import { TabBottom } from "../../../components/TabBottom/TabBottom";
import { HomeBlack } from "../../../icons/solid/Home_Black";
import { HomeWhite } from "../../../icons/outline/HomeWhite";
import { ServiceWhite } from "../../../icons/outline/ServiceWhite";
import { ServiceBlack } from "../../../icons/outline/ServiceBlack";
// import { RequestQuoteInterface } from "../../../interfaces/RequestQuote";
import { GetUserByUID } from "../../../Slices/User";
import { MessageBlack } from "../../../icons/outline/MessageBlack";
import { MessageContentBlack } from "../../../icons/solid/MessageContentBlack";
import { BellBlack } from "../../../icons/solid/BellBlack";
import { BellWhite } from "../../../icons/solid/BellWhite";
// import { ModalQuoteSupplier } from "../../../components/Modal/ModalQuoteSupplier/ModalQuoteSupplier";
// import { QuotationDataError } from "../../../interfaces/QuotationDataError";
// import { Company } from "../../../interfaces/Company";


// Importando utilitarios
// import { ServiceInterface } from "../../../interfaces/Service";
import { GetCompanyByUser } from "../../../Services/Company.service";
import { GetServiceByCompanyId } from "../../../Services/Service.service";

import {fetchToken } from "../../../firebaseConfig/FirebaseConfig";
import { registerTopicService } from "../../../Services/Topic.service";
import { ToastContainer } from "react-toastify";
import { ModalReview } from "../../../components/Modal/ModalReview/ModalReview";
// import { ModalProcessService } from "../../../components/Modal/ModalProcessService/ModalProcessService";
import { ModalAuthNew } from "../../../components/ModalAuthNew/ModalAuthNew";
import { Mail } from "../../../icons/outline/Mail";
import { current } from "@reduxjs/toolkit";
import { Notification } from "../../../icons/outline/Notification";
import { ElevenSeccion } from "../../../components/Landing/ElevenSeccion/ElevenSeccion";

export const AdminPage: React.FC<{}> = () => {

    const navigate = useNavigate()
    const location = useLocation();

    const dispatch = useDispatch<AppDispatch>();

    const {userDetail} = useSelector((state: any) => state.user);

    const {isLoggedIn} = useSelector((state: any) => state.auth );

    const [activePage, setActivePage] = useState(-1);
    const [mobileActive, setMobileActive] = useState<boolean>(false);
    const [showModalWhatsp, setShowModalWhatsp] = useState<boolean>(false);
    // const [showRequestQuote, setShowRequestQuote] = useState<boolean>(false);
    // const [showModalQuoteSupplier, setShowModalQuoteSupplier] = useState<boolean>(false);
    const [showReviewService, setShowReviewService] = useState<boolean>(false);

    const [item, setItems] = useState<{
        title: string,
        event: ()=>void,
    }[]>([
        {   title:"Panel principal", 
            event: ()=>handlePanelClick()},
        {   title:"Servicios", 
            event: ()=>handleService(),
        },
    ]);

    const [itemTabs, setItemsTabs] = useState<{
        img: React.ReactNode,
        imgBlack: React.ReactNode,
        title: string,
        event: ()=>void,
    }[]>([]);
    
    const [user, setUser] = useState(() => {
        const user = getAuth().currentUser;
        return user;
    });

    const [loading, setLoading] = useState<boolean>(true);
    const [showNavbar, setShowNavbar] = useState<boolean>(false);
    const [isChat, setIsChat] = useState<boolean>(false);

    // const [showFooter, setShowFooter] = useState<boolean>(false);
    
    const inputRef = useRef<HTMLDivElement>(null) 

    const handlePanelClick = () => {
        setActivePage(0);
        setMobileActive(false);  
        navigate("/"+OPERATIONS, {replace: true});
        executeScroll();
        
    } 

    const handleService = () => {
        setActivePage(1);
        setMobileActive(false)
        navigate(SERVICE, {replace: true});
        executeScroll();
        
    }

    const handleMessage = () => {
        setActivePage(2);
        setMobileActive(false);
        navigate(CHAT, {replace: true});
        executeScroll();
    }

    const handleNotifications= () => {
        setActivePage(3);
        setMobileActive(false);
    }

    // const handleClickModalSupplier = () => {  
       
    //     setShowModalQuoteSupplier(true);
    //     setActivePage(2);
    // }

    const executeScroll = () => {
        if (inputRef) {
            inputRef.current?.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };
    
    // redux get user by uid
    const getUserById = async (uid: string) => {
        dispatch(GetUserByUID(uid))
        .unwrap()
        .then(async(response) => {
            dispatch(setAuth({
                isLoggedIn: true,
                displayName: response.userName,
                userId: response.id,
                uid: response.uid,
                imgProfile: response.photoUrl
            }));    
            getFCM(response.id);
            await getCompanyByUser(response.id);
        })
        .catch((error)=> {
            setLoading(false);
            console.log("🚀 ~ file: AdminPage.tsx:232 ~ getUserById ~ error:", error);
        })
    }

    // obteniendo la compañia por usuario
    const getCompanyByUser = async (userId: string) => {
        try {
            const response:any = await GetCompanyByUser(userId);
            if(response.length> 0) {
                // setCompanyDetail(response[0]);
                getServiceByCompanyId(response[0].id);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const getServiceByCompanyId = async (companyId: string) => {
        setLoading(false);
        if(!loading) {
            setLoading(true);
            try {
                const response:any = await GetServiceByCompanyId(companyId);
                // setServiceDetail(response[0]);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    }

    // const handleModalWhatsapp = () => {
    //     setShowModalWhatsp(true)
    // }

    // get token from browser and register
    const getFCM = (userId: string) => {
        fetchToken((token) => {
            registerTopicService(token, userId).then((response) => {
                // console.log("🚀 ~ file: AdminPage.tsx:259 ~ registerTopicService ~ response:", response)
            }).catch((error) => {
                console.log("🚀 ~ file: AdminPage.tsx:261 ~ registerTopicService ~ error:", error)
            });
        });
    }

    // method to check if user is in home page
    const checkHomePage = async (userCurrent: any) => {
        if(userCurrent && location.pathname === "/") {
            navigate('/operaciones', {replace: true});
        }
    }

    //method to get current user
    const getCurretnAuthUser = async () => {
        return new Promise((resolve, reject) => {
            try {
                const auth: Auth = getAuth();
                onAuthStateChanged(auth, (userCurrent) => {
                    checkHomePage(userCurrent);
                    resolve(userCurrent);     
                });
            } catch (error) {
                console.log("🚀 ~ file: AdminPage.tsx:466 ~ returnnewPromise ~ error:", error);
                reject(error);
            }
        });
    }

    // method to configure tabs in mobile
    const configureTabs = async (currentUser: any) => { 
        if(currentUser) {
            setItemsTabs([
                {   
                    img: <HomeBlack/> , 
                    imgBlack: <HomeWhite/>,  
                    title:"Panel principal", 
                    event: ()=>{handlePanelClick()}
                },
                {   
                    img: <ServiceBlack/>, 
                    imgBlack: <ServiceWhite/>, 
                    title:"Servicios", 
                    event: ()=>{handleService()}
                },
                {   
                    img: <MessageContentBlack/>, 
                    imgBlack: <Mail/>, 
                    title:"Mensajes", 
                    event: ()=>{handleMessage()}
                },
                {   
                    img: <BellBlack />,
                    imgBlack: <Notification/>, 
                    title:"Notificaciones", 
                    event: ()=>{handleNotifications()}       
                }
            ]);
        } else {
            if(userDetail && userDetail.userType && userDetail.userType.includes('provider')) {
                setItemsTabs([
                    {   
                        img: <HomeBlack/> , 
                        imgBlack: <HomeWhite/>,  
                        title:"Panel principal", 
                        event: ()=>{handlePanelClick()}
                    },
                    {   
                        img: <ServiceBlack/>, 
                        imgBlack: <ServiceWhite/>, 
                        title:"Servicios", 
                        event: ()=>{handleService()}
                    },
                    {   
                        img: <MessageContentBlack/>, 
                        imgBlack: <Mail/>, 
                        title:"Mensajes", 
                        event: ()=>{handleMessage()}
                    },
                    {   
                        img: <BellBlack />,
                        imgBlack: <Notification/>, 
                        title:"Notificaciones", 
                        event: ()=>{handleNotifications()}
                    }
                ])
            } else {
            setItemsTabs([
                    {   
                        img: <HomeBlack/> , 
                        imgBlack: <HomeWhite/>, 
                        title:"Panel principal", 
                        event: ()=>{handlePanelClick()}
                    },
                    {   
                        img: <ServiceBlack/>, 
                        imgBlack: <ServiceWhite/>, 
                        title:"Servicios", 
                        event: ()=>{handleService()}
                    },
                    {   
                        img: <MessageContentBlack/>, 
                        imgBlack: <MessageBlack/>, 
                        title:"Mensajes", 
                        event: ()=>{handleMessage()}},
                    {   
                        img: <BellBlack />,
                        imgBlack: <BellWhite/>, 
                        title:"Notificaciones", 
                        event: ()=>{handleNotifications()}
                    },
                ])
            }
        }
    }

    // method to get active page
    const getActivePage = async () => {
        let urlLocation = (location.pathname).split('/');
        let num = -1;
        switch (urlLocation[1]) {
            case "":
                // setShowFooter(true);
                setShowNavbar(true);
                break;
            case SERVICE:
                
                // setShowFooter(true);
                setShowNavbar(false);
                break;
            case TOOLS:
                
                // setShowFooter(true);
                setShowNavbar(true);
                break;
            case PLAN:
              
                // setShowFooter(true);
                setShowNavbar(true);
                break;
            case FREQUENTLY_QUESTIONS:
             
                // setShowFooter(true);
                setShowNavbar(true);
                break;
            case OPERATIONS:
                num = 0;
                // setShowFooter(false);
                setShowNavbar(false);
                break;
            case PROVIDER:
                // setShowFooter(false);
                switch (urlLocation[2]) {
                    case OPERATIONS:
                        num = 0;
                        break;
                    default:
                        num = 0;
                        break;
                }
                setShowNavbar(false);
                break;
            case CHAT:
                num = 2;
                if(urlLocation[1]===CHAT) {
                    window.innerWidth >= 769 ? setIsChat(true): setIsChat(false)  
                } if(urlLocation[2]===DETAIL) {
                    setIsChat(true);  
                } 
                setShowNavbar(false);
                // setShowFooter(false);
                break;
            default:
                setShowNavbar(false);
        }
        // setActivePage(num);
    }

     useEffect(() => {
        const auth = async() => {
            setLoading(true);
            const userCurrent: any = await getCurretnAuthUser();
            if(userCurrent) {
                setUser(userCurrent);   
                await getUserById(userCurrent.uid); 
            }
            configureTabs(userCurrent);
            setLoading(false);
        } 
        auth();
    }, []);

    useEffect(() => {
       getActivePage();
    }, [location.pathname]);

    useEffect(()=>{
        window.document.body.style.overflow =  mobileActive ? 'hidden' : 'unset';
    },[mobileActive]); 


    return (
        <div className={`admin-container ${(loading || showNavbar) ?  "admin-bg" : "admin-bgUser"}`}>
            <ToastContainer  hideProgressBar limit={5} position='top-right'  autoClose={3500} pauseOnFocusLoss={false} pauseOnHover={false} />
            <Navbar 
                subMenu={item}
                menuActive={activePage} 
                setMenuActive={setActivePage}
                setMobileActive={setMobileActive}
                onClickLognIn={()=>navigate(`?${AUTH_MODAL}=${AUTH_LOGIN}`, {replace: true})}
                onClickRegister={()=>navigate(`/${PLAN}`, {replace: true})}
                isHome={showNavbar}
            />
            {!showNavbar && !isChat &&  itemTabs.length>0 && isLoggedIn && !mobileActive &&
                <TabBottom 
                    subMenu={itemTabs}
                    menuActive={activePage} 
                />
            } 
            <>
            {!loading  ? <div 
                    className="content-outlet"  
                    ref={inputRef} 
                    style={{paddingTop: mobileActive ? '3.8rem' : '0rem' }}
                >
                   <Outlet/> 
                </div>: null}
            </>
            {!loading ?<WhatsappWidget
                phone="51908862598"
                message="Hola soporte técnico de Arkabia, tengo una consulta"
                // onclick={handleModalWhatsapp}
            /> : null }
       

            {showNavbar && 
            // <Footer className={"footer-content" }/>
            <ElevenSeccion/>
            }
            {/* <Auth /> */} 
            <ModalAuthNew />

            <ModalReview
                setShowModal={setShowReviewService}
                showModal={showReviewService}
                isLoggin={isLoggedIn}
            />
        </div>
    )
}