import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type ServiceBlackProps = React.HTMLAttributes<HTMLDivElement> & {};

export const ServiceBlack: React.FC<ServiceBlackProps> =({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+"/images/svg/icono_service_v2.svg"} style={{height: '21px'}} alt="help_circle" />
        </div>
    )
}