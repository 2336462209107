import React, { useEffect, useState } from "react";

/** importando componentes */
import { Modal } from "../Modal/Modal";
import { ButtonIcon } from "../..";
import { TextField } from "../..";
import { Textarea } from "../..";
import { Button } from "../..";
import { Calendar as CalendarMain } from "../../Calendar/Calendar";
import { SelectCategoryEvent } from "../../SelectCategoryEvent/SelectCategoryEvent";
import { ProfileDetail } from "../../ProfileDetail/ProfileDetail";
import { Checkbox } from "../../Checkbox/Checkbox";

/** importando iconos */
import { Calendar } from "../../../icons/outline/Calendar";
import { ArrowDown } from "../../../icons/outline/ArrowDown";

import { Event } from "../../../interfaces/Event";
import { CategoryEvent } from "../../../interfaces/Event";

/** importando estilos */
import './ModalConfirm.css';
import { format } from "date-fns";
import { TranslateMonth } from "../../../utils/translateMonth";
import { User } from "../../../interfaces/User";
import { Loader } from "../../Loader/Loader";

interface ModalConfirmProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handleYes: ()=>void;
    handleNo: ()=>void;
    title: string;
    subTitle: string;
    loading?: boolean;
}

export const ModalConfirm: React.FC<ModalConfirmProps> = ({
    show,
    setShow,
    handleYes,
    handleNo,
    title,
    subTitle = '',
    loading = false
}) => {

    useEffect(()=> {
        show ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    }, [show])

    return (
       <div role='modalConfirmRole' className="modalConfirm">
            <Modal title={title} show={show} setShow={setShow} disableToast>
                {!loading ? <div className="modalConfirm-container">
                    <div className="small-subheader modalConfirm-colorText">
                        {subTitle}
                    </div>
                    <div className="modalConfirm-row modalConfirm-button">
                        <Button content="Si" color="green-2" onClick={()=>handleYes()}/>
                        <Button content="No" color="black-75" onClick={()=>handleNo()}/>
                    </div>
                </div> : <Loader/>}
            </Modal>
       </div>
    )
}