import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type SearchProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Search: React.FC<SearchProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+"/images/svg/search.svg"} alt="search" />
        </div>
    )
}