import React, { useEffect, useState } from "react";

import { CouponIcon } from "../../icons/solid/CouponIcon";

import "./CardSummaryPay.css";
import { TextField } from "../TextField/TextField";
import { Button } from "../Button/Button";
import { PlanInterface } from "../../interfaces/PlansInterface";
import { ErrorInterface } from "../../interfaces/ErrorInterface";
import { PhoneCountry } from "../PhoneCountry/PhoneCountry";
import { PreRegisterErrorInterface, preRegisterInterface } from "../../interfaces/PreRegister";
import { ValidateField } from "../../utils/ValidateField";
import { COUNTRY_FLAG_BASE } from "../../Consts/BaseUrl";
import { GetCountryList } from "../../Services/Country";
import { CreatePreRegister } from "../../Services/PreRegister.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { validateCounCode } from "../../Services/SecondayPlan.service";
import { SecondaryPlanInterface } from "../../interfaces/SecondaryPlanInterface";

interface CardSummaryPayProps {
    planSelected: PlanInterface;
    currency: "USD" | "PEN";
    handlePlanClick: (planId: string, secondaryPlanId: string, currency: string)=>void;
    black?: boolean;
    isChagePlan?: boolean;
}

export const CardSummaryPay: React.FC<CardSummaryPayProps> = ({
    planSelected,
    currency,
    handlePlanClick,
    black,
    isChagePlan = false
}) => {

    const navigate = useNavigate();


    // const [preRegister, setPreRegister] = useState<preRegisterInterface>({
    //     email: "",
    //     // ip: "",
    //     lastName: "",
    //     name: "",
    //     phone: "",
    //     phoneCode: "+51",
    //     phoneflag: COUNTRY_FLAG_BASE+"pe.svg",
    //     planId: ""
    // });

    // const [preRegisterError, setPreregisterError] = useState<PreRegisterErrorInterface>({
    //     email: {
    //         status: false,
    //         message: ''
    //     },
    //     lastName: {
    //         status: false,
    //        message: ''
    //    },
    //     name: {
    //         status: false,
    //     message: ''
    //     },
    //     phone:{
    //         status: false,
    //     message: ''
    //     },
    //     phoneCode: {
    //         status: false,
    //        message: ''
    //    }
    // });

    const [loading, setLoading] = React.useState<boolean>(false);
    const [wasDiscountApplied, setWasDiscountApplied] = React.useState<boolean>(false);
    // add interface for secondary plan
    const [secondaryPlanSelected, setSecondaryPlanSelected] = React.useState<SecondaryPlanInterface>();

    const [loadingCoupon, setLoadingCoupon] = React.useState<boolean>(false);
    const [couponCode, setCouponCode] = React.useState<string>("");
    const [price, setPrice] = React.useState<number>(0);

    // const [countryList, setCountryList] = useState<{country: string; alpha2Code: string, phoneCode: string }[]>([]);


    // method to handle coupon code change
    const handleCouponCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCouponCode(event.target.value);
    }

    // method to handle apply coupon
    const handleApplyCoupon = async () => {
        if(planSelected.priceUsd === 0 || planSelected.pricePen === 0){ 
            toast.error("No se puede aplicar cupón a plan gratuito");
            return;
        }        
        try {
            console.log(couponCode, planSelected.id || "", currency)
            setLoadingCoupon(true);
            const response = await validateCounCode(couponCode, planSelected.id || "", currency);
            // console.log("🚀 ~ handleApplyCoupon ~ response:", response);
            setLoadingCoupon(false);
            if(response.status){
                setSecondaryPlanSelected(response.secondaryPlan);
                setPrice(response.secondaryPlan.price);
                setWasDiscountApplied(true);
            } else {
                toast.error("Cupón inválido");
                setPrice(currency === "USD" ? planSelected.priceUsd : planSelected.pricePen);
                setWasDiscountApplied(false);
            }
        } catch (error) {
            console.log("🚀 ~ handleApplyCoupon ~ error:", error);
            setLoadingCoupon(false);
            toast.error("Hubo un error al aplicar el cupón, por favor intenta nuevamente");
        }
       
    }

    // // method to validate preRegister
    // const validatePreRegister = () => {
    //     let error = false;
    //     error = ValidateField(preRegister?.name, "required") || ValidateField(preRegister?.lastName, "required") ||
    //     ValidateField(preRegister?.email, "email") || ValidateField(preRegister?.phoneCode, "required") ||
    //     ValidateField(preRegister?.phone, "required");

    //     setPreregisterError({
    //         email: {
    //             status: ValidateField(preRegister?.email, "email"),
    //             message: 'Email inválido'
    //         },
    //         lastName: {
    //             status: ValidateField(preRegister?.lastName, "required"),
    //             message: 'Apellidos inválido'
    //         },
    //         name: {
    //             status: ValidateField(preRegister?.name, "required"),
    //             message: 'Nombre inválido'
    //         },
    //         phone: {
    //             status: ValidateField(preRegister?.phone, "required"),
    //             message: 'Número de telèfono inválido'
    //         },
    //         phoneCode: {
    //             status: ValidateField(preRegister?.phoneCode, "required"),
    //             message: 'Códido de país inválido'
    //         }
    //     });

    //     return error;
    // }

    // const handlePreRegisterClick = async () => {
    //     const error = validatePreRegister();
    //     if(!error){
    //         setLoading(true);
    //         console.log("preRegister", preRegister, planSelected.id);
    //         try {
    //             const resposne = await CreatePreRegister({...preRegister, planId: planSelected.id || ""});
    //             toast.success("Registro exitoso");
    //             navigate("/");
    //             setLoading(false);
    //         } catch (error) {
    //             console.log("🚀 ~ handlePreRegisterClick ~ error:", error)
    //             toast.error("Hubo un error al registrar, por favor intenta nuevamente");
    //             setLoading(false);
    //         }

    //     }
    // }

    // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    //     const value = event.target.value;
    //     setPreRegister((prev)=>({
    //         ...prev,
    //         [name]: value
    //     }));
    // }

    // const handleCountryChange = (country: string, alpha2Code: string) => {
    //     setPreRegister((prev)=>({
    //         ...prev,
    //         phoneflag: COUNTRY_FLAG_BASE+(alpha2Code.toLowerCase())+".svg",
    //         phoneCode: country
    //     }));
    // }

    // const handlePhoneCodeChange = (phoneCountry: string) => {
    //     setPreRegister((prev)=>({
    //         ...prev,
    //         phoneCode: phoneCountry
    //     }));
    // }

    const handleStartClick = () => {
        if(!loading && !loadingCoupon) {
            if(planSelected) {
                setLoading(true);
                handlePlanClick(planSelected.id || "", secondaryPlanSelected?.id || "", currency);
                setLoading(false);
            } else {
                toast.error("Selecciona un plan");
            }
        }
    }
    
    const setNewPrice = (price: number) => {
        setPrice(price);
        setWasDiscountApplied(false);
    }


    // // obteniendo lista de paises
    // const getCountries = async () => {
    //     try {
    //         const response: any = await GetCountryList();
    //         setCountryList(response);
    //     } catch (error) {
    //         console.log("🚀 ~ file: SupplierInvoceTranslation.tsx:65 ~ getCountries ~ error", error);
    //     }
    // }

    // useEffect(() => {
    //     getCountries();
    // }, []);

    useEffect(() => {
        if(planSelected){
            setNewPrice(currency === "USD" ? planSelected.priceUsd : planSelected.pricePen);

        }
    }, [planSelected]);


    return (
        <div className={"cardSummaryPay "+(black ? "cardSummaryPay-summary" : "cardSummaryPay-summary-white")}>
            <div className={`small-header ${black ? "cardSummaryPay-summary__title" : "cardSummaryPay-summary__title_black"}`}>Resumen de pedido</div>
            {/* <div className="cardSummaryPay-summary__title small-header">Regístrate y obtén un cupón de descuento</div> */}
            <hr className={black ? "cardSummaryPay-summary__hr" : "cardSummaryPay-summary__hr_white"} />
            {!isChagePlan && <div className="cardSummaryPay-summary__col">
                <div className={`paragraph ${black ? "cardSummaryPay-summary__col-text" : "cardSummaryPay-summary__col-text_black"}`}>Plan {planSelected.title}</div>
                <div className={`paragraph ${black ? "cardSummaryPay-summary__col-text" : "cardSummaryPay-summary__col-text_black"}`}>{`${currency === "USD" ? planSelected.priceUsd : planSelected.pricePen} ${currency}`}</div>
            </div>}
          {!isChagePlan && wasDiscountApplied && secondaryPlanSelected && <div className="cardSummaryPay-summary__col">
                <div className={`paragraph ${black ? "cardSummaryPay-summary__col-text" : "cardSummaryPay-summary__col-text_black"}`}>Descuento ({secondaryPlanSelected.price/(currency === "USD" ? planSelected.priceUsd : planSelected.pricePen)*100}%) </div>
                <div className={`paragraph ${black ? "cardSummaryPay-summary__col-text" : "cardSummaryPay-summary__col-text_black"}`}>{`- ${secondaryPlanSelected.price} ${secondaryPlanSelected.currency}`}</div>
            </div>}
            {!isChagePlan && <div className="cardSummaryPay-summary__row"> 
                <CouponIcon className="cardSummaryPay-summary__row-icon" />
                <div className={`paragraph-header ${black ? "cardSummaryPay-summary__col-text" : "cardSummaryPay-summary__col-text_black"}`}>¡Tengo un cupón!</div>
            </div>  }
           {!isChagePlan && <div className="cardSummaryPay-summary__row">
                <input placeholder="Ingrese cupón" className="cardSummaryPay-input" onChange={(e)=>handleCouponCodeChange(e)} value={couponCode} />
                <Button content={!loadingCoupon ? "Validar" : "Validando"} color="blue-2" size="extra-small" className="cardSummaryPay-couponBtn tinytext-header" onClick={()=>handleApplyCoupon()} />
            </div>}
            {/* <TextField
                title='Nombre'
                placeholder=" "
                value={preRegister?.name}
                onChange={(e:any) =>handleInputChange(e, "name")}
                error={preRegisterError.name}
     
            />
            <TextField
                title='Apellido'
                placeholder=" "
                value={preRegister?.lastName}
                onChange={(e:any) =>handleInputChange(e, "lastName")}
                error={preRegisterError.lastName}
            />
            <TextField
                title='Email'
                placeholder=" "
                value={preRegister?.email}
                onChange={(e:any) =>handleInputChange(e, "email")}
                error={preRegisterError.email}
            />
            <PhoneCountry
                phoneCode={preRegister?.phoneCode}
                phoneFlag={preRegister?.phoneflag}
                phone={preRegister?.phone}
                countryList={countryList}
                handleCountryChange={handleCountryChange} 
                handlePhoneChange={(e)=>handleInputChange(e, "phone")} 
                // phoneCode={signUp.phoneCode ? signUp.phoneCode : ''}
                handlePhoneCodeChange={handlePhoneCodeChange} 
                title=""
                error={{
                    phoneCode: preRegisterError.phoneCode,
                    phone: preRegisterError.phone
                }}
            /> */}
            <div className="cardSummaryPay-summary__total">
                <div className="cardSummaryPay-summary__col cardSummaryPay-summary__total--col">
                    <div className={`paragraph ${black ? "cardSummaryPay-summary__col-text" : "cardSummaryPay-summary__col-text_black"}`}>Subtotal</div>
                    <div className={`paragraph ${black ? "cardSummaryPay-summary__col-text" : "cardSummaryPay-summary__col-text_black"}`}><span> {price } </span> <div className="cardSummaryPay-summary__currency">{currency}</div></div>
                </div> 
                <Button 
                    content={!loading ? "Empezar ahora": "Cargando..."} 
                    color={black ? "white" : "black-75"} 
                    size="large" 
                    className={` small-bold ${black ? "cardSummaryPay-summary__total-button" : "cardSummaryPay-summary__total-button_black"}` }
                    onClick={()=>handleStartClick()} />
            </div>
            {/* <div className='small-boldheader cardSummaryPay-summary__total-button' onClick={()=> !loading ? handlePreRegisterClick() : {}}>
                
            </div> */}
        </div>
    );
};
