import React, { useEffect, useState } from 'react';

import './CardTypeService.css';
import { ServiceTypeInterface } from '../../interfaces/Service';
import { GetAllServicesHover } from '../../Services/ServiceType.service';
import { Button } from '../Button/Button';
import { SelectCard } from '../SelectCard/SelectCard';
import { SituationOptions } from '../../Consts/SituationOptions';
import { useNavigate } from 'react-router-dom';
import { MODAL_REQUEST, OPERATIONS, SERVICE, SHOW_MODAL } from '../../Consts/BaseUrl';
import { WhatsappNumber } from '../../Consts/WhastappNumber';

interface CardTypeServiceProps {
    onClose?: ()=>void;
    hardRedirect?: boolean;
}

export const CardTypeService: React.FC<CardTypeServiceProps> = ({
    hardRedirect = false,
    onClose=()=>{}
}) => {

    // const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();


    // useState service type was selected
    const [serviceType, setServiceType] = useState<ServiceTypeInterface>();
    const [navigateTo, setNavigateTo] = useState<string>("");

    // const [serviceAllType, setServiceAllType] = useState<ServiceTypeInterface[]>([]);

    // const [idxSelected, setIdxSelected] = useState<number>(0)

    // const handleSelect = (item: ServiceTypeInterface) => {
    //     setServiceType(item);
    // }

    const handleClick = () => {
      
        if(navigateTo==="whatsapp") {
            window.open(`https://api.whatsapp.com/send/?phone=${WhatsappNumber}&text=¡Hola! necesito ayuda, es mi primera importación`)
            navigate("/"+OPERATIONS);
        } else {
            navigate(`/${SERVICE}/${navigateTo}${navigateTo === "agenciamiento-de-aduana" ? "?"+SHOW_MODAL+"="+MODAL_REQUEST : "/"}`, {replace: true});
            hardRedirect && window.location.reload();
        }
        onClose();
    }

    // const handleTabClick = (idx: number) => {
    //     setIdxSelected(idx);
    //     setServiceType(serviceAllType[idx])
    // }

    // const getServiceType = async () => {
    //     setLoading(false);
    //     if(!loading) {
    //     try {
    //         setLoading(true);
    //         const response = await GetAllServicesHover();
    //         if(response.length > 0) {
    //             setServiceAllType(response);
    //         }
    //         setLoading(false);
    //         } catch (error) {
    //             setLoading(false);    
    //             console.log(error);
    //         }
    //     }
    // }

    // useEffect(() => {
    //     getServiceType();
    // }, []);

    return (
        <div className='cardTypeService-container'>   
            <div className='cardTypeService-header-title'>
                <div className='cardTypeService-Title submega-encabezado'>
                    ¿Cuál es tu situación?
                </div> 

                <div className='smalltext cardTypeService-text'>Elige un servicio
                    {/* <span className='mainRegisterNew-link big-subheader'> Paso 2 de 2 </span> */}
                </div>
            </div>
            
            <div className='cardTypeService-scroll'>
                <div className='cardTypeService-row'>
                    {/* {serviceAllType.map((s, idx) => (
                        // <div className='cardTypeService-card' key={idx}>
                        <div key={idx} 
                            className={`cardTypeService-card ${serviceType?.id === s.id ? "cardTypeService-card-active" : ""}`} 
                            onClick={()=> handleSelect(s)}>
                            <div className='cardTypeService-header'>
                                <div className='cardTypeService-left'>
                                    <div className="cardTypeService-img">
                                        <img className="cardTypeService-logoMin" src={s?.urlIcon}/>
                                    </div>
                                    <span style={{color:'var(--gray-1)'}} className='small-bold selectServiceType-text-filter'> 
                                        {s?.name}
                                    </span> 
                                </div>
                                <div className='cardTypeService-right'>
                                    <UpBlack/>
                                </div>
                            </div>

                            <div className='cardTypeService-body'>
                                <div className='paragraph'>{s?.description}</div>
                                <div className='cardTypeService-items'>
                                    {s.itemDetail && s.itemDetail.map((detail, idx) => (
                                        <div key={idx} className="cardContentPlan__row__min smalltext" style={{color: '#333333'}}>
                                            <Check_green/>
                                            {detail}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div> 
                    ))} */} 
                    <SelectCard
                        data={SituationOptions}
                        setTitle={setNavigateTo}
                    />

                </div>

                {/* <div className="caruselComment-phaseTwo">
                    { [Array(serviceAllType.length)].map((item, idx) => (
                        <CircleTab 
                            key={idx}
                            status={idx === idxSelected} 
                            handleClick={()=>handleTabClick(idx)} 
                        />
                    ))}
                </div> */}
            </div>

            <Button content='¡Vamos!' color="black-25" size="small" onClick={()=>handleClick()} />
        </div>
    )
}