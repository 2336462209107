import React, { useState } from "react";
import { EventCard } from "../EventCard/EventCard";

import './EventSeccion.css';
import { CardContent } from "../CardContent/CardContent";
import { QuoteIcon } from "../../icons/outline/QuoteIcon";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { Nube } from "../../icons/outline/Nube";
import { CalendarIconBlack } from "../../icons/outline/CalendarIconBlack";
import { EventConsts } from "../../Consts/EventConsts";
import { Loader } from "../Loader/Loader";

interface EventSeccionProps {
}

export const EventSeccion: React.FC<EventSeccionProps> = ({

}) => {

    return (
        <div className="eventSeccion">
            <CardContent className="eventSeccion-cardContent">
                <div className="eventSeccion-column">
                    <div className="eventSeccion-row">
                        <QuoteIcon/>
                        <div className="smalltext-header">Cotizaciones</div>
                    </div>
                    <div className="smalltext eventSeccion-row">
                        <span className="smalltext-header">0</span>
                        de
                        <span className="smalltext-header">3</span>
                        cotizaciones disponibles
                    </div>
                    <div className="smalltext-header eventSeccion-text-blue">¿Más cotizaciones?</div>
                </div> 

                <div className="eventSeccion-column">
                    <div className="eventSeccion-row">
                        <Nube/>
                        <div className="smalltext-header">Almacenamiento</div>
                    </div>
                    <div className="eventSeccion-progress">
                        <ProgressBar bgcolor={"linear-gradient(90deg, #000 0%, rgba(51, 51, 51, 0.70) 100%)"} completed={50}/>
                    </div>
                    <div className="smalltext eventSeccion-row">
                        <span className="smalltext-header">0 GB</span>
                        de
                        <span className="smalltext-header">15 GB</span>
                    </div>
                </div>

                <div className="eventSeccion-row">
                    <CalendarIconBlack/>
                    <div className="smalltext-header">Eventos</div>
                </div>
                
                <div className="eventSeccion-eventcard">
                    { [].length>0 ? [].map((e, idx) => {
                        return (
                            <EventCard
                                key={idx}
                                event={e}
                            />
                        )
                    }): <div className="anyEvent">
                            <div className="paragraph anyEvent-message">No tienes ningún evento.</div> 
                        </div>
                    }
                </div> 

            </CardContent>
        </div>
    )
}