import React from "react";

// importando componentes
import { Modal } from "../Modal/Modal/Modal";
import { TextField } from "../TextField/TextField";
import { Button } from "../Button/Button";

import './ForgotPassword.css';

interface ForgotPasswordProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    handleForgotPassword: () => void;
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({
    show,
    setShow,
    email,
    setEmail,
    handleForgotPassword
}) => {
    return (
        <Modal title="Recupera contraseña - Arkabia" show={show} setShow={setShow} role='modal-login' disableToast> 
            <div className="forgotPassword-container">
                <div className="paragraph forgotPassword-colorText">Si tu correo esta registro en Arkabia te enviaremos un correo electrónico con los pasos para re establecer tu contraseña</div>
                <TextField
                    title='Email'
                    placeholder=" "
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                />
                <div className="forgotPassword-buttons">
                    <Button 
                        content="Cancelar"
                        size="small"
                        onClick={() => {setEmail(''); setShow(false)}}
                    />
                    <Button 
                        content="Recuperar contraseña"
                        size="small"
                        color='blue-2'
                        onClick={()=>handleForgotPassword()}
                    />
                </div>
            </div>
        </Modal>
    )
}