import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";

/** importando componentes */
import { ChatHeader } from "../../../components/ChatHeader/ChatHeader";
import { SearchTextField } from "../../../components/SearchTextField/SearchTextField";
import { Multimedia } from "../../../components/Multimedia/Multimedia";
import { Phase } from '../../../components/Phase/Phase';
import { Chat } from "../../../components/Chat/Chat";
import { ListChat } from "../../../components/ListChat/ListChat";
import { Loader } from "../../../components/Loader/Loader";
import { PhasesImportData, phaseTypeService } from "../../../Consts/phasesImport";
import { MembersChat } from "../../../components/MemberChat/MemberChat";
import { ModalSearchUser } from "../../../components/Modal/ModalSearchUser/ModalSearchUser";
import { Button } from "../../../components";
import { ModalQuote } from "../../../components/Modal/ModalQuote/ModalQuote";

/**importando datos de prueba */
import { UserList } from "../../../testdata/UserList";

// importando constantes
import { DETAIL, OPERATIONS, QUOTATION, PROVIDER, REQUEST_QUOTE, ORDER } from "../../../Consts/BaseUrl";

//importando estilos
import './ChatPage.css';

// importando interfaces
import { RequestQuoteInterface } from "../../../interfaces/RequestQuote";
import { ChatInterface, MemberChat } from "../../../interfaces/ChatInterface";

// importando servicios
import { GetChatsByUserId, UpdateChat } from "../../../Services/Chat.service";
import { GetServiceByCompanyId } from "../../../Services/Service.service";
import { ModalQuoteSupplier } from "../../../components/Modal/ModalQuoteSupplier/ModalQuoteSupplier";
import { QuotationDataError } from "../../../interfaces/QuotationDataError";
import { ServiceInterface } from "../../../interfaces/Service";
import { GetOrderByChatId } from "../../../Services/Order.service";
import { AppDispatch } from "../../../Store/Store";
import { GetUser } from "../../../Slices/User";
import { sendProviderMessageNotification, sendUserMessageNotification } from "../../../Services/Whatsapp.service";
import { GetCompanyByUser } from "../../../Services/Company.service";
import { GetUserById } from "../../../Services/User.service";
import { Company } from "../../../interfaces/Company";
import { ModalNotification } from "../../../components/Modal/ModalNotification/ModalNotification";
import { AttachChat } from "../../../interfaces/MessageInterface";
import { BackArrowBlack } from "../../../icons/outline/BackArrowBlack";
import { ArrowUp } from "../../../icons/outline/ArrowUp";
import { ArrowDown } from "../../../icons/solid/ArrowDown";
import { UpDropdown } from "../../../icons/outline/UpDropdown";
import { DownDropdown } from "../../../icons/outline/DownDropdown";
import { OperationHeaderOptionInterface } from "../../../interfaces/OperationHeader";
import { ModalProcessService } from "../../../components/Modal/ModalProcessService/ModalProcessService";
import { OrderInterface } from "../../../interfaces/OrderInterface";
import { set } from "date-fns";


interface ChatProps {
}

export const ChatPage: React.FC<ChatProps> = () => { 
   
    const dispatch = useDispatch<AppDispatch>();

    // navigation
    let navigation = useNavigate();
    // url location
    const location = useLocation();
    // url seach params
    const [searchParams] = useSearchParams();

    //get userDetail for redux
    const {userDetail} = useSelector((state: any) => state.user);

    // useState loading status
    const [loading, setLoading] = useState<boolean>(false);
    
    
    //UseState list item chat
    const [itemsChat, setItemsChat] = useState<ChatInterface[]>([]);

    /** useState del modal cotizacion */
    const [showModalQuote, setShowModalQuote] = useState<boolean>(false);
    const [showModalQuoteSupplier, setShowModalQuoteSupplier] = useState<boolean>(false);

    const [showArchive, setShowArchive] = useState(false);

    // chat id active
    const [chatActive, setChatActive] = useState<string>('');
    const [chatSelected, setChatSelected] = useState<ChatInterface>();

    const [phase, setPhase] = useState<number>(-1);
    const [serviceType, setServiceType] = useState<string>('');
    const [serviceTypeId, setServiceTypeId] = useState<string>('');

    const [orderDetail, setOrderDetail] = useState<OrderInterface>();
    const [activeSubMenu, setActiveSubmenu] = useState('Imágenes');

    // useState search
    const [searchText, setSearchText] = useState<string>('');

    // useState to show modal add member
    const [modalAddMember, setModalAddMember] = useState<boolean>(false);

    //
    const [activeSearchMessage, setActiveSearchMessage] = useState<boolean>(false);
    const [activeMenuRight, setActiveMenuRight] = useState<boolean>(false);
    const [showHeaderInSearch, setShowHeaderInSearch] = useState<boolean>(false);

    //const is Image or is Document in archive
    const [isDocument, setIsDocument] = useState<AttachChat[]>([]);
    const [isImage, setIsImage] = useState<AttachChat[]>([]);

    /** state cotizacion */
    const [requestQuote, setRequestQuote] = useState<RequestQuoteInterface>({
        id: "",
        title: "",
        number: 0,
        user: {
            id: '',
            name: '',
            typeIt: '',
            itNumber: 0,
            numOperations: 0,
            phone: '',
            rating: 0,
        },
        companyName: '',
        serviceId: '',
        // serviceTypeId: 'agenciamiento-de-aduana',
        // serviceTypeLabel: 'Agenciamiento de aduana',
        status: 'published',
        statusProvider: 'No leída',
        active: true,
        category: 'Importación',
        customs: {
            regime: 'Importación definitiva',
            incoterm: 'FOB',
            value: 0,
            coin: '',
            // insurance: undefined,
            merchandisePermits: {
                dataSheet: [],
                proforma: [],
                packingList: [],
            },
            exwDirection: '',
            descriptionOperation: '',
        },
        transport: {
            type: undefined,
            origin: {
                city: '',
                country: '',
            },
            destination: {
                city: '',
                country: '',
            },
            typeContainer: {
                lcl: false,
                fcl: false,
                lclAir: false
            },      
        },
        phaseDraft: 0,
        operationId: '',
        numServiceComments: 0,
        numServiceRating: 0,
        observation: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        country: {
            alpha2Code: 'PE',
            city: 'Lima',
            imgFlag: 'https://firebasestorage.googleapis.com/v0/b/arkabia-mvp-v1.appspot.com/o/static%2Fimg%2Fflag_of_Peru.png?alt=media&token=d8bfda2c-4c48-419a-b8b9-00a423a24d53'
        }
    });

    // useState company
    const [company, setCompany] = useState<Company>();

    // useState to list menu options header
    const [menuOptionHeader, setMenuOptionHeader] = useState<OperationHeaderOptionInterface[]>([
        {
            name: 'Buscar',
            method: () => (setShowHeaderInSearch(true))
        },
        {
            name: 'Archivos y Doc.',
            method: () => (window.innerWidth > 1155 ? setShowArchive((prev) => !prev) : (setShowArchive(true), setActiveMenuRight(true))),
        },
      
        //   
        // {
        //     name: 'Reportar',
        //     method: () => {},
        // },
        // {
        //     name: 'Descargar chat',
        //     method: () => {},
        // }
    ])

    
    // method to masrk as read chat
    const markAsUnread = async (id: string, members: MemberChat[]) => {
        await UpdateChat(id, {
            members: members.map((m) => m.userId === userDetail.id ? {
                ...m,
                numMessagesUnread: 0,
            } : m)
        });

    }   

    // handle chat item click
    const handleItemChatClick = async (chatId: string) => {
        setLoading(true);
        setIsImage([]);
        setIsDocument([]);
        
        let tmp = itemsChat.find(item => item.id === chatId);
      
        // Marck as read chat
        if(tmp && tmp.members.find((m) => m.userId === userDetail.id)?.numMessagesUnread) {
            await markAsUnread(chatId, tmp.members)
        } 

        // if existing attachments in chat get it
        if(tmp && tmp.attachments.length>3) {
            getDocumentByType(tmp);
        }

        if(window.innerWidth<=768) {
            setLoading(false);
            navigation(`${DETAIL}/${chatId}`);
        } else {
            setChatSelected(tmp);
      
            setLoading(false);
            navigation('?activeChat='+chatId); 
        }
    }

    // Lista todos los chats del usuario
    const getChatsByUserId = async () => {
        if(!loading) {
            setLoading(true);
            try {
                GetChatsByUserId(userDetail.id, (response)=> {
                    setItemsChat(response);
                });
                    
                setLoading(false);
            } catch (error) {   
                console.log("🚀 ~ file: ListChat.tsx:131 ~ getChats ~ error:", error)
                setLoading(false);
            }
        }
    }

    const getOrderByChatId = async (chatId: string) => {
       if(!loading) {
        setLoading(true)
        try {
            const response: any = await GetOrderByChatId(chatId);
            if(response && response.length>0) {
                // setPhase(response[0].activePhase);
                setServiceType(response[0].serviceTypeLabel);
                setServiceTypeId(response[0].serviceTypeId);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("🚀 ~ file: ChatPage.tsx:295 ~ GetOrderByChatId ~ error:", error)
        }
       }
    }

    // method to get phase and service chat of requestQuote or Order
    const getPhaseAndServiceType = async (tmpChat: ChatInterface | undefined) => {
        if(!tmpChat) {
            return 
        }
        if(tmpChat.operationPhase && tmpChat.operationPhase.length>0) {
            if(tmpChat.operationPhase === "order") { 
                // get from order phase
               await getOrderByChatId(tmpChat?.id ? tmpChat.id : '');
         
            } else {
                // phase is 1 => Cotizacion (requestQuote or Quotation)
                // setPhase(1);
                setServiceType("Agenciamiento de aduana");
                setServiceTypeId("agenciamiento-de-aduana");
            }
        }
    }

    // handle seach text change
    const handleSeachChange = useCallback(
        debounce((text)=> {
            setLoading(true)
            setSearchText(text);
            setLoading(false)
        }, 500), [searchText]); 

    // method to show modal add member
    const handleShowModalAddMember = () => {
        setModalAddMember(true);
    }

    // handle click redirection phases
    const handleClickPhase = (phase:number) => {
        setLoading(true);
        if(chatSelected && chatSelected.operationPhase === "order") {
            userDetail.userType.includes('provider') ? 
            // redirecting to provider order 
            navigation(
                `/${PROVIDER}/${OPERATIONS}/${chatSelected && chatSelected.operationId}/${ORDER}/${chatSelected && chatSelected.documentIdPhase}/1`,
                {replace: true}
            )
            : 
            // redirecting to user order 
            navigation(
                `/${OPERATIONS}/${chatSelected && chatSelected.operationId}/${ORDER}/${chatSelected && chatSelected.documentIdPhase}/1`,
                {replace: true}
            )
        } else {
            userDetail.userType.includes('provider') ? 
            // redirecting to provider requestQuote 
            navigation(
                `/${PROVIDER}/${OPERATIONS}/${REQUEST_QUOTE}/${chatSelected && chatSelected.documentIdPhase}`, 
                {replace: true}
            )  
            // redirecting to user requestQuote 
            : navigation(
                `/${OPERATIONS}/${chatSelected && chatSelected.operationId}/${chatSelected && chatSelected.documentIdPhase}/${QUOTATION}/unknown`, 
                {replace: true}
            );
            setLoading(false);
        }
    }

    const handleClickArchive = () => {
        setShowArchive(true);
        setActiveMenuRight(true)
    }

    // list service detail
    // const getServiceByCompany = async (companyId: string | undefined) => {
    //     if(!loading && companyId) {
    //         setLoading(true);
    //         try {
    //             const response:any = await GetServiceByCompanyId(companyId);
    //             const service = response.find((s: ServiceInterface) => s.serviceType === "agenciamiento-de-aduana")
    //             setService(service);
    //             setLoading(false)
                
    //         } catch (error) {
    //             console.log("🚀 ~ file: ChatPage.tsx:234 ~ GetService ~ error:", error)
    //             setLoading(false)
    //         }
    //     }
    // }

    // const loadRequestQuote = (companyId: string, companyName:string) => {
    //    console.log("🚀 ~ file: ChatPage.tsx:457 ~ loadRequestQuote ~ companyId:", companyId, companyName)
    //    if(service) {
    //     setRequestQuote((prev) => ({
    //         ...prev,
    //         // serviceId: service.id ? service.id : '',
    //         // serviceTypeId: service.serviceType,
    //         // serviceTypeLabel: service.serviceTypeLabel,
    //         // numServiceComments: service.numComments,
    //         // numServiceRating: service.rating,
    //         companyName: companyName,
    //         companyId: companyId,
    //         user: {
    //             id: userDetail.id,
    //             itNumber: 0,
    //             name: userDetail.name + " " + userDetail.lastName,
    //             numOperations: userDetail.numOperation ? userDetail.numOperation : 0,
    //             phone: userDetail.phone ? userDetail.phone : "",
    //             rating: userDetail.rating ? userDetail.rating : 0,
    //             typeIt: "",
    //             businessName: ""
    //         }
    //     }))
    //    }
    // } 

    const handleShowModalRQClick = async () => {
        // if(!loading) {
        //     setLoading(true)
        //     const memberProvider = chatSelected?.members.find((m) => (m.userId !== userDetail.id && m.isCompany));
        //     if(memberProvider) {
        //         console.log("🚀 ~ file: ChatPage.tsx:486 ~ handleShowModalRQClick ~ memberProvider:", memberProvider)
        //         loadRequestQuote(memberProvider?.companyId || "", memberProvider?.companyName || "" );
        //         setLoading(false)
        //         setShowModalQuote(true);
        //     }
            

        setShowModalQuote(true);
    }
    
    const handleShowQuotationProviderClick = () => {
        if(!loading) {
            setShowModalQuoteSupplier(true);
        }
    }

    // method to get user detail
    const getUser = (userId: string | undefined) => {
        if(userId) {
            dispatch(GetUser(userId))
        .unwrap()
        .then((response) => {
            setRequestQuote((prev) => ({
                ...prev,
                user: {
                    id: response.id,
                    name: response.name+" "+response.lastName,
                    typeIt: response.typeIt ? response.typeIt : "",
                    itNumber: response.itNumber ? response.itNumber : 0,
                    numOperations: response.numOperation ? response.numOperation : 0,
                    rating: response.rating ? response.rating : 0,
                    phone: (response.phoneCode ? response.phoneCode : "") + "" +response.phone ? response.phone : "",
                },
                usersProvider: [userDetail.id]
            }))
            setLoading(false);
        })
        .catch((error) => {
            console.log(
            "🚀 ~ file: Quote.tsx ~ line 443 ~ getCompany ~ error",
            error
            );
            setLoading(false);
        });
        }
    }

    // method to send whatsapp notification to provider
    const sendProviderWspNotification = async () => {
        try {
            // find all user to send whatsapp notification
            const users = chatSelected ? chatSelected.members.filter((m) => (m.userId !== userDetail.id && !m.isCompany)) : [];
            const userCompany: any[] =  chatSelected ? chatSelected.members.filter((m) => (m.isCompany)) : [];
            // iterate users to send whatsapp notification
            users.length> 0 && users.forEach(async (user) => {
                // get user detail by id
                const respUser: any = await GetUserById(user.userId);
                // send whatsapp notification
                sendUserMessageNotification(respUser.phoneCode+""+respUser.phone, respUser.name + " " + respUser.lastName, userCompany.length > 0 ? userCompany[0].companyName : "")
                .then((wsp) => {
                    // console.log("🚀 ~ file: ChatPage.tsx:490 ~ .then ~ wsp:", wsp);
                }).catch((errorWsp) => {
                    console.log("🚀 ~ file: ChatPage.tsx:492 ~ .then ~ error:", errorWsp);
                });      
            });
            
        } catch (error) {
            console.log("🚀 ~ file: ChatPage.tsx:488 ~ sendProviderWspNotification ~ error:", error);
        }
    }

    // method to send whatsapp notification to user
    const sendUserWspNotification = async () => {
        try {
            // find all user to send whatsapp notification
            const users = chatSelected ? chatSelected.members.filter((m) => (m.userId !== userDetail.id && m.isCompany)) : [];
            // iterate users to send whatsapp notification
            users.length> 0 && users.forEach(async (user) => {
                // get user detail by id
                const respUser: any = await GetUserById(user.userId);
                sendProviderMessageNotification(respUser.phoneCode+""+respUser.phone, userDetail.name + " " + userDetail.lastName, user?.companyName ? user.companyName : '')
                .then((wsp) => {
                    // console.log("🚀 ~ file: ChatPage.tsx:505 ~ .then ~ wsp:", wsp);
                }).catch((errorWsp) => {
                    console.log("🚀 ~ file: ChatPage.tsx: 507 ~ .then ~ error:", errorWsp);
                });
            });
        } catch (error) {
            console.log("🚀 ~ file: ChatPage.tsx:510 ~ sendUserWspNotification ~ error:", error)
        }
    }

    const sendWhatsappNotification =() => {
        try {
            // console.log("enviar a whatsapp")
            userDetail.userType.includes('user') ? sendUserWspNotification() : sendProviderWspNotification();
        } catch (error) {
            console.log("🚀 ~ file: ChatPage.tsx:491 ~ debounce ~ error:", error);
        }
    }

    const getCompanyByUserId = async (id: string) => {
        try {
            const response: any[] = await GetCompanyByUser(id);
            if(response.length>0) {
    
                setCompany(response[0]);
                setRequestQuote((prev) => ({
                    ...prev,
                    companyId: response[0].id,
                    companyName: response[0].name,
                }))
            }
        } catch (error) {
            console.log("🚀 ~ file: ChatPage.tsx:549 ~ getCompanyByUserId ~ error:", error)
            
        }
    }

    const getDocumentByType = (chat: ChatInterface) => {
        let tmpImg: AttachChat[] = [];
        let tmpDoc: AttachChat[] = [];

        chat?.attachments.forEach((a) => {
            let nameSplit = a.fileName.split('.');
            return (
                nameSplit[nameSplit.length-1].toLocaleLowerCase()==='jpg' ||
                nameSplit[nameSplit.length-1].toLocaleLowerCase()==='jpeg' ||
                nameSplit[nameSplit.length-1].toLocaleLowerCase()==='png' ||
                nameSplit[nameSplit.length-1].toLocaleLowerCase()==='gif' ||
                nameSplit[nameSplit.length-1].toLocaleLowerCase()==='ttf' ||
                nameSplit[nameSplit.length-1].toLocaleLowerCase()==='bmp' ||
                nameSplit[nameSplit.length-1].toLocaleLowerCase()==='tiff'
            ) ?
            tmpImg.push(a): tmpDoc.push(a)
        })

        setIsImage(tmpImg);
        setIsDocument(tmpDoc);
    }

    // get chats by user
    useEffect(()=> {
        getChatsByUserId();
        if(userDetail.userType.includes('provider')) {
            getCompanyByUserId(userDetail.id);
        } else {
            setRequestQuote((prev) => ({
                ...prev,
                user: {
                    id: userDetail.id,
                    name: userDetail.name+" "+userDetail.lastName,
                    itNumber: 0,
                    numOperations: 0,
                    phone: userDetail.phone ? userDetail.phone : "",
                    rating: 0,
                    typeIt: ''
                }
            }))
        }

        // exist requestQuote options en mobile header menu
        if(menuOptionHeader.findIndex((m) => m.name === "Solicitar cotización") === -1 && userDetail.userType.includes('user')){
            setMenuOptionHeader((prev) => [...prev,   {
                name: 'Solicitar cotización',
                method: () => (handleShowModalRQClick())
            }]);
        }
    }, [userDetail]);

    // check if existe active chat id in url
    useEffect(()=> {
        let search = searchParams.get("activeChat");
        
        if(search && search.length>0 && itemsChat.length>0) {
            setChatActive(search);
            setChatSelected(itemsChat.find(i => i.id === search));
            // get servive by company or get user info if is provider
            userDetail.userType.includes("user") ? getCompanyByUserId(itemsChat.find(i => i.id === search)?.members.find((m) => m.userId !== userDetail.id && m.isCompany)?.userId || '') :
            getUser(itemsChat.find(i => i.id === search)?.members.find((m) => m.userId !== userDetail.id && !m.isCompany)?.userId);
          
            getPhaseAndServiceType(itemsChat.find(i => i.id === search));
         
        }

    }, [location, itemsChat]);

    return (

      
        <div className="chat-page"> 
            <div className="chat-page-left">
                {userDetail && 
                <ListChat
                    itemChat={itemsChat}
                    chatActive={chatActive}
                    setActiveChat={setChatActive}
                    getChatsByUserId={getChatsByUserId}
                    onClickItem={handleItemChatClick}
                    isProvider={userDetail.userType && userDetail.userType.includes('provider')}
                    companyProvider={company?.id}
                />}
            </div>  

            <div className="chat-page-right">
                <div className={window.innerWidth > 1155 ? "chat-detail" : (!activeMenuRight ? "chat-detail" : "chat-detail-1155")}>
                    { chatSelected !== undefined &&
                    <>
                        {!showHeaderInSearch ? 
                            <ChatHeader 
                                order={chatSelected?.operationName ? chatSelected?.operationName : ""}
                                // operationType={chatSelected?.operationType ? chatSelected?.operationType : ""}
                                teams={chatSelected?.companyName ? [{
                                    teamName: chatSelected?.companyName
                                }] : []}
                                userList={UserList.splice(0,2)}
                                onclickBack={()=>{}}
                                
                                userId={chatSelected ? chatSelected.members.find(m => m.userId !== userDetail.id)?.userId : ''}
                                userImg={chatSelected ? chatSelected.members.find(m => m.userId !== userDetail.id)?.userProfile : ''}
                                userName={chatSelected ? chatSelected.members.find(m => m.userId !== userDetail.id)?.userName : ''}
                                userLastName={chatSelected ? chatSelected.members.find(m => m.userId !== userDetail.id)?.userLastName : ''}

                                isCompany={chatSelected ? chatSelected.members.find(m => m.userId !== userDetail.id)?.isCompany : false}
                                isGroup={chatSelected?.typeChat === 'group'}
                                companyName={chatSelected ? chatSelected.members.find(m => m.userId !== userDetail.id)?.companyName: ''}
                                companyImage={chatSelected ? chatSelected.members.find(m => m.userId !== userDetail.id)?.companyImage: ''}
                            
                                handleSearch={handleSeachChange}
                                members={chatSelected ? chatSelected.members : []}
                                handleAddMemberClick={handleShowModalAddMember}

                                optionMenu={chatSelected ? menuOptionHeader : []}
                            >
                            </ChatHeader>  : 
                            <div className={"chat-right-search-responsive"}>
                                <BackArrowBlack onClick={()=>setShowHeaderInSearch(false)} style={{cursor: 'pointer'}} className="chat-right-search_active-icon-search"/>
                                <SearchTextField 
                                    setTextSearch={(e)=> handleSeachChange(e)}
                                    title='Buscar'
                                    darkIcon
                                    handleClick={()=> !activeSearchMessage ? setActiveSearchMessage(true) : {}}
                                />
                                <div className="chat-right-up-down">
                                    <UpDropdown/>
                                    <DownDropdown/>
                                </div>
                            
                            </div>
                        }
                    </>}
                    <Chat 
                        chatId={chatActive}
                        userId={userDetail.id}
                        userName={userDetail.name+" "+userDetail.lastName}
                        isGroup={chatSelected?.typeChat === "group"}
                        searchText={searchText}
                        sendWhatsappNotification={sendWhatsappNotification}
                        paramHeight={window.innerHeight}
                    />
                </div>

                <div className={window.innerWidth > 1155 ? "chat-detail-right" : (!activeMenuRight ? "chat-detail-right-1155" : "chat-detail-right-responsive")}>
                    {!showArchive || !activeMenuRight ? 
                    <>
                        <div className={"chat-right-search"}>
                            {activeSearchMessage && 
                            <BackArrowBlack onClick={()=>setActiveSearchMessage(false)} style={{cursor: 'pointer'}} className="chat-right-search_active-icon-search"/>}
                            <SearchTextField 
                                setTextSearch={(e)=> handleSeachChange(e)}
                                title='Buscar'
                                darkIcon
                                handleClick={()=> !activeSearchMessage ? setActiveSearchMessage(true) : {}}
                            />
                        </div>
                    
                        <div className="chat-page-options">
                            <div className="chat-page-content-orders-first"> 
                                {(chatSelected && chatSelected.typeChat === "group") && <div className="paragraph-header chat-content-title">
                                    Órdenes
                                </div>}
                                {(chatSelected && chatSelected.typeChat === "group")  && serviceTypeId.length>0 &&
                                <div className="chat-page-content-orders">
                                    <h2 className="smalltext-header chat-content-subTitle">{serviceType && serviceType == 'Agenciamiento de aduana' ? 'Logística y aduanas' : serviceType}</h2>
                                    <Phase 
                                        phases={ serviceTypeId !== 'busqueda-de-proveedor' ? 
                                        phaseTypeService.agenciamiento : 
                                        phaseTypeService.busquedaProveedor}
                                        activePhase={phase}
                                        unlockedPhases={chatSelected.operationPhase && chatSelected.operationPhase === "order" ? 7 : 1}
                                        blackkMode
                                        handleClickPhase={handleClickPhase}
                                    />
                                    {/* <Button content="Contratar" onClick={handleOrder}/> */}
                                </div> }
                            </div>

                            <Multimedia  
                                multimedia={chatSelected?.attachments} 
                                title={'Multimedia' + " " + "(" + "+" +chatSelected?.attachments.length+")"}
                                number={4}
                                notModal
                                handleClickArchive={handleClickArchive}
                            />

                            {(chatSelected && chatSelected.typeChat === "group") && <div className="chat-page-users">
                                <MembersChat
                                    members={chatSelected.members ? chatSelected.members.filter(m => (m.userId !== userDetail.id && m.isCompany)) : []}
                                    canAddMembers={userDetail 
                                        // && userDetail.userType.includes('provider')
                                    }
                                    direction="vertical"    
                                    handleAddMember={handleShowModalAddMember}
                                    isCompany={chatSelected.members ? chatSelected.members.find(m => m.userId !== userDetail.id)?.isCompany : false}
                                />
                                
                            </div>}
                            
                        </div>

                        {chatSelected?.typeChat === 'individual' && userDetail.userType.includes("user") &&
                            <Button 
                                content={userDetail.userType.includes("user") ? 
                                "Solicitar cotización" : "Iniciar cotización" }
                                onClick={()=>userDetail.userType.includes("user") ? 
                                handleShowModalRQClick() : handleShowQuotationProviderClick()}
                            />
                        }
                    </> : 
                        <div className="chat-right-archive">
                            <ModalNotification
                                show={showArchive} 
                                setShow={setShowArchive}
                                setActive={setActiveSubmenu}
                                active={activeSubMenu}
                                subMenu={['Imágenes', 'Documentos']}
                                title={
                                    <div className="chat-modal-archive-title">
                                        <BackArrowBlack 
                                            onClick={()=>(setActiveMenuRight(false), 
                                                setShowArchive(false))} 
                                                style={{cursor: 'pointer'}}
                                        />
                                        <div className="paragraph-header">Archivos y doc</div>
                                    </div>
                                }
                            >
                                {activeSubMenu==='Imágenes' ? 
                                <>
                                    {isImage.length>0 ?<div className="chat-modal-archive-list-imgs">
                                        {isImage.map((i, idx)=> (
                                        <div key={idx} className="chat-modal-archive-imagen">
                                            <img src={i.fileUrl} className="modal-imagenes-content"/> 
                                        </div>
                                        ))}
                                    </div>: <div className="smalltext modal-notification-nothing">No tienes ninguna imagen</div> }
                                </>: 
                                <>
                                    {isDocument.length>0 ?<div className="chat-modal-archive-list">
                                        {isDocument.map((d, idx)=> (
                                            <div key={idx} className="chat-modal-archive-documents">
                                                <img src={d.fileIcon} className="chat-modal-archive-documents-img"/>
                                                <div className="smalltext modal-documents-text">{d.fileName}</div>
                                            </div>
                                        ))}
                                    </div> : <div className="smalltext modal-notification-nothing">No tienes ningún documento</div>}
                                </>}
                            </ModalNotification>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
};