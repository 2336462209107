
import { getStorage, ref, uploadBytes, getDownloadURL, uploadString } from "firebase/storage";

// Create a root reference
const storage = getStorage();

const UploadFile = async (file: File, folderName: string) => {
    const storageRef = ref(storage, `${folderName}/${file.name}`)
    // 'file' comes from the Blob or File API
    return new Promise((resolve, reject) => {
        uploadBytes(storageRef, file)
        .then((snapshot) => {
            // console.log('upload: ', snapshot);
            getDownloadURL(ref(storage, snapshot.ref.fullPath))
            .then((url) => {
                // console.log(url)
                resolve(url)
            })
            .catch((error) => {
                console.log("error al obtener url", error);
                reject(error);
            })
        })
        .catch((error) => {
            console.log(error);
            reject(error);
        })
    })
}

const UploadString = async (file: string | undefined, folderName: string) => {
    const storageRef = ref(storage, folderName)
    if(file) {
        return new Promise((resolve, reject) => {
            uploadString(storageRef, file, 'data_url').then((snapshot) => {
                getDownloadURL(ref(storage, snapshot.ref.fullPath))
                .then((url) => {
                    // console.log(url)
                    resolve(url)
                })
                .catch((error) => {
                    console.log("error al obtener url", error);
                    reject(error);
                })
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
        })
         
    }
}

export default {
    UploadFile,
    UploadString
}

// userId/requestQuote/loquesea.jpg
// userId/requestQuote/requestId/loquesea.jpg =>
// userId/order/orderId/phase_1/loquesea.pdf