import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import './Home.css';

/** importando componentes */
import { useTypingText } from "../../components/useTypingText/useTypingText";

/** Importando Servicios */
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { ModalProcessService } from "../../components/Modal/ModalProcessService/ModalProcessService";
import { PreregisterEmail } from "../../Services/PreregisterEmail";
import { PreregisterEmailInterface } from "../../interfaces/Preregisterinterface";
import { GetIpHost } from "../../Services/GetIp.service";
import { toast } from "react-toastify";
import { AUTH_LOGIN, AUTH_MODAL, AUTH_REGISTER, STORAGE_ASSETS } from "../../Consts/BaseUrl";
import { FirstSeccion } from "../../components/Landing/FirstSeccion/FirstSeccion";
import { SecondSeccion } from "../../components/Landing/SecondSeccion/SecondSeccion";
import { ThridSeccion } from "../../components/Landing/ThridSeccion/ThridSeccion";
import { FourSeccion } from "../../components/Landing/FourSeccion/FourSeccion";
import { FiveSeccion } from "../../components/Landing/FiveSeccion/FiveSeccion";
import { SixSeccion } from "../../components/Landing/SixSeccion/SixSeccion";
import { SevenSeccion } from "../../components/Landing/SevenSeccion/SevenSeccion";
import { EightSeccion } from "../../components/Landing/EightSeccion/EightSeccion";
import { NineSeccion } from "../../components/Landing/NineSeccion/NineSeccion";
import { TenSeccion } from "../../components/Landing/TenSeccion/TenSeccion";
import { TwelveSeccion } from "../../components/Landing/TwelveSeccion/TwelveSeccion";

interface HomeProps {
}
export const Home: React.FC<HomeProps> = () => {

  const navigate = useNavigate();

  
  const [questions, setQuestions] = useState<{
      question: string;
      answer: string;
  }[]>([
      {
          question: "¿Qué es Arkabia?",
          answer: "Es una plataforma en línea que te ayuda a cotizar tus importaciones con distintas agencias de aduana y carga a la vez.",
      }, 
      {
          question: "¿Qué necesito para poder cotizar a través de Arkabia?",
          answer: "Basta con tener la proforma de tu proveedor, la lista de empaque y la ficha técnica para poder cotizar a través de Arkabia.",
      },
      {
          question: "¿Cuál es el tiempo estimado de respuesta?",
          answer: "De 2 a 4 horas, según la calidad de información que el usuario comparta.",
      },
      {
          question: "¿Quién puede ver mis requerimientos?",
          answer: "Sólo tu y las agencias con las que decidas cotizar.",
      },
      {
          question: "¿Es gratis?",
          answer: "Si, Arkabia es completamente gratis.",
      },
      {
          question: "¿Es necesario realizar toda mi importación a través de Arkabia?",
          answer: "No, los pasos que proporciona Arkabia para importar de inicio a fin son únicamente de apoyo para el usuario.",
      },
  ]);


  const { word } = useTypingText(
      [ " China", " EE.UU.", " Brasil", " España", " India", " Canadá"],
      // ["todo el mundo", "todo el mundo"],
      130,
      20
  );

  const [plataformTimer, setPlataformTimer] = useState<any>(null);

  const [loading, setLoading] = useState<boolean>(false);
  
  const [showModalQuote, setShowModalQuote] = useState<boolean>(false);

  const [isLogged, setIsLogged] = useState<boolean>(false);

  const [cursorStatus, setCursorStatus] = useState<number>(0);

  const [animate, setAnimation] = useState<{
    name: string;
    icon: string;
    cursor: string;
    time: number;
  }[]>([{
    name:"Cotiza fácil",
    icon: window.innerWidth < 961 ? STORAGE_ASSETS+"/images/png/penBlack.png" : STORAGE_ASSETS+"/images/svg/heck_yellow.svg",
    cursor:  window.innerWidth < 961 ? STORAGE_ASSETS+"/images/png/penWhite.png" : STORAGE_ASSETS+"/images/svg/check_yellow.svg",
    time: 16000
  },
  {
    name:"Compara rápido" ,
    icon: window.innerWidth < 961 ? STORAGE_ASSETS+"/images/png/compareBlack.png" : STORAGE_ASSETS+"/images/svg/check_yellow.svg",
    cursor: window.innerWidth < 961 ? STORAGE_ASSETS+"/images/png/compareWhite.png" : STORAGE_ASSETS+"/images/svg/check_yellow.svg",
    time: 8000
  }, 
  {
    name:"Reserva tu mejor opción",
    icon: window.innerWidth < 961 ? STORAGE_ASSETS+"/images/png/shopBlack.png" : STORAGE_ASSETS+"/images/svg/check_yellow.svg",
    cursor: window.innerWidth < 961 ? STORAGE_ASSETS+"/images/png/shopWhite.png" : STORAGE_ASSETS+"/images/svg/check_yellow.svg",
    time: 6000
  },
  ])


  const handleClickModal = async (email: string) => {
    if(email.length === 0) {
      navigate(`?${AUTH_MODAL}=${AUTH_REGISTER}`);
    } else {
      try {
        const preRegister: PreregisterEmailInterface = {
          email: email,
          ip: await GetIpHost(), 
          status: "propspect",
          createdAt: new Date(),
          updatedAt: new Date()
        }
        const response = await PreregisterEmail(preRegister);
        if(response) {
          navigate(`?${AUTH_MODAL}=${AUTH_REGISTER}&email=`+email) 
        } else {
          console.log('error al resgistrar email');
        }
      } catch (error) {
        console.log("🚀 ~ handleClickModal ~ error:", error);
        toast.error("Hubo un error al validar el correo electrónico");
      }
    }
  }

  const redirectToLogin = (email: string) => {
    navigate(`?${AUTH_MODAL}=${AUTH_LOGIN}&email=`+email)
  }

  const handleVideo = () => {
    window.open('https://www.youtube.com/watch?v=-pudIpW2ABc')
  }

  // const handleWhatsapp = () => {
  //   window('https://api.whatsapp.com/send/?phone=51905431863&text=${message}&app_absent=0')
  // }

  // useEffect
  useEffect(() => {
      const auth = getAuth();
      onAuthStateChanged(auth, (currentUser) => {
          if(currentUser){
              setIsLogged(true);
              // getUserInfo(currentUser.uid);
              setLoading(false)
          }
      });
  },[])

  // useEffect(() => {
  //   if(userDetail && userDetail.id) {
  //     setRequestQuote((prev:any) => ({
  //         ...prev,
  //         user: {
  //             id: userDetail.id,
  //             itNumber: userDetail.itNumber, 
  //             name: userDetail.name+" "+userDetail.lastName, 
  //             numOperations: 0,
  //             rating: userDetail.rating ? userDetail.rating : 0,
  //             typeIt: userDetail.typeIt,
  //             phone: (userDetail.phoneCode ? userDetail.phoneCode : "+51")+""+(userDetail.phone ? userDetail.phone : ""),
  //         }
  //     }));
  //   }
  // }, [userDetail]);

  useEffect(() => {
      clearTimeout(plataformTimer)
      let new_timer = setTimeout(() => {
          setCursorStatus((prev) => prev < animate.length - 1 ? prev +1 : 0);
      }, animate[cursorStatus].time);

      setPlataformTimer(new_timer)
  }, [cursorStatus]);

  /** No permite que la parte de atras del modal se mueva */
  useEffect(()=>{
      window.document.body.style.overflow =  showModalQuote ? 'hidden' : 'unset';
  },[showModalQuote]);

  return (
    <div className="home">
      <div className="home-background">
        <FirstSeccion/>
        {/* <SecondSeccion/> */}
      </div>
      <ThridSeccion/>
      <FourSeccion/>
      <FiveSeccion/>
      <SixSeccion/>
      <TwelveSeccion/>
      <EightSeccion/>
      <NineSeccion/>
      <TenSeccion/>
      
      {showModalQuote && 
        <ModalProcessService
          fromPage="home"
          serviceTypeId=""
          show={showModalQuote}
          setShow={setShowModalQuote}
        />
      }
    </div>
  )
}
