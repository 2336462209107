import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type BoatBlackProps = React.HTMLAttributes<HTMLDivElement> & {};

export const BoatBlack: React.FC<BoatBlackProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/boat_black.svg"} alt="" style={{width: '27px'}}/>
    </div>
  );
};
