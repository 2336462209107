import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type CalendarProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Calendar: React.FC<CalendarProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/calendar.svg'} alt='Calendar' />
        </div>
    )
}