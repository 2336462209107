import React, { useState } from "react";

/** Importando iconos */
import { ArrowUp } from "../../icons/outline/ArrowUp";
import { ArkabiaOp } from "../../icons/outline/ArkabiaOp";
import { ArrowDown } from "../../icons/solid/ArrowDown";

/** Importando componentes */
import { DashboardElementCargoStatus } from "../DashboardElements/CargoStatus/DashboardElementCargoStatus"; 
import { HeaderElementsMobile } from "../DashboardElementsMobile/HeaderElementsMobile/HeaderElementsMobile";
import { ContentQuote } from "../ContentQuote/ContentQuote";

/** Importando componentes */
import { operationOrders, operationRequestQuotes } from "../../interfaces/Operation";

/** Importando data */
import { ServiceChargeStatus } from "../../@types/serviceChargeStatus";

/** Importando estilos */
import './DashboardElementsSupplierMobile.css';
import { BodyElementsMobile } from "../DashboardElementsMobile/BodyElementsMobile/BodyElementsMobile";
import { OrderInterface } from "../../interfaces/OrderInterface";

interface DashboardElementsMobileProps { 
    id: string;
    date: string;
    quote:boolean;
    order: boolean;
    open?: boolean; 
    quote_?: boolean;
    numberOrder?: number,
    importation: string; 
    statusProvider?: string; // estado de la cotizacion proveedor
    requestQuoteData: operationRequestQuotes[];
    orderData: any[]
    type: string
    handleClickQuote: (e: string) => void;
    handleClickOrder: () => void;
    cargoStatus: ServiceChargeStatus;
    handleClickStatus: (id: string, e: string) => void;
}
export const DashboardElementsSupplierMobile: React.FC<DashboardElementsMobileProps> = ({
    date,
    id,
    open = false,
    cargoStatus, 
    importation,
    statusProvider='',
    type,
    quote = false,
    handleClickQuote,
    handleClickOrder,
    order = false,
    numberOrder=0,
    requestQuoteData,
    orderData,
    handleClickStatus,
    quote_ = false
}) => {

    const [expanded, setExpanded] = useState(open);
    
    return (
        <>
            <div role='dashboardElementsMobileRole' className="dashboardElements-mobile-container">
                <div  className={`dashboardElements-mobile--header ${!expanded && 'dashboardElements-mobile--border'}`}>
                    <div onClick={()=>setExpanded(!expanded)} className="dashboardElements-mobile--header-icons">
                        <div className="dashboardElements-mobile--left">
                                <ArkabiaOp />
                                <div className="dashboardElements-mobile--header-title">
                                    <div className="paragraph-header dashboardElements-colorText dashboardElements-subDetail">{importation}</div>
                                    <div className="tinytext dashboardElements-colorText">{type}</div>
                                    <div className="tinytext-header dashboardElements-mobile--right-date__">{date} </div>
                                </div>
                            
                        </div>
                        <div className="dashboardElements-mobile--right">
                            <div className="tinytext-header dashboardElements-mobile--right-date">{date} </div>
                            {expanded?<ArrowUp onClick={()=>setExpanded(!expanded)}/> :<ArrowDown onClick={()=>setExpanded(!expanded)}/>}
                        </div>
                    </div>
                    <div className="dashboardElements-mobile--header-bottom" >
                        {quote &&
                            <ContentQuote 
                                requestId={id}
                                statusLabel={statusProvider} 
                                number={numberOrder}
                                handleClickStatus={handleClickStatus}
                            /> 
                        }
                    </div>
                    {/* <div className="dashboardElements-mobile--header-bottom" >
                        <DashboardElementCargoStatus phase={cargoStatus} date={date} />
                    </div> */}
                </div>

                { expanded && 
                    <div className="dashboardElements-mobile-content">
                        {/** Listado de cotizaciones muestra varias */}
                        {/* { quote && requestQuoteData.length>0 && requestQuoteData.map((rqd) => (
                            <div key={rqd.id} role='dashboardElementsMobile-Click' className="smalltext dashboardElements-quote" onClick={()=>handleClickQuote(id)}>
                                {"Ver cotizaciones"}
                            </div>
                        ))} */}
                        {/** Listado de ordenes */}
                        { order && orderData.length>0 && orderData.map((od, idx) => (
                            <React.Fragment key={idx}>
                                <HeaderElementsMobile 
                                    // nameUser={od.turnUser.name}
                                    aduana = {od.user.userName.length>0 ? od.user.userName : '-'}
                                    // user = {od.turnUser.urlProfile} 
                                />
                                 
                                <BodyElementsMobile 
                                    // alert = {od.readMessage}
                                    handleClick = {()=>handleClickOrder()}
                                  
                                    msg = {od.lastMessage || '-'}
                                    nrOrder = {od.number}
                                    price= {od.price}
                                    currency={od.currency?od.currency: ''}
                                    transport={od.transport?od.transport: ''}
                                    quote_={quote_}
                                    rating={""}
                                />
                            </React.Fragment>
                        ))}
                    </div>
                }
            </div>
        </>
        )
}