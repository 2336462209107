import React from "react";

import './WhatsappWidget.css';
import { WhatsappNumber } from "../../Consts/WhastappNumber";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

interface WhatsappWidgetProps {
    phone: string;
    message?: string;
    // onclick?: ()=>void
}

export const WhatsappWidget: React.FC<WhatsappWidgetProps> = ({
    phone,
    message='',
    // onclick
}) => {

    const handleClick = () => {
        window.open(`https://api.whatsapp.com/send/?phone=${WhatsappNumber}&text=${message}&app_absent=0`)
    }
    
    return (
        <div className="whatsappWidget" onClick={()=>handleClick()}>
            <div 
                // {`https://wa.me/${phone}?text=${message}`}
                className="whatsapp_float"
                // target="_blank"
                // rel="noopener noreferrer"
            >
                <img src={STORAGE_ASSETS+"/images/svg/whatsapp.svg"} className="whatsapp-icon"></img>
            </div>    
        </div>
    )
}