import React, { useEffect, useState } from "react";


import { Subheader } from "../../Subheader/Subheader";
import { ProductInvoceTranslation } from "../ProductInvoceTranslation";

// importando interfaces
import { InvoiceTranslation, Provider } from "../../../interfaces/InvoiceTranslation";
import { ProductDeclareInterface } from "../../../interfaces/ProductDeclare";

import './TabsProduct.css';
import { toast } from "react-toastify";
// import { GetCountryList } from "../../../Services/Country";
// import { CountryInterface } from "../../../interfaces/Country";
import { Trash } from "../../../icons/outline/Trash";
import StorangeService from "../../../Services/Store.service";


interface TabsProductProps {
    products: ProductDeclareInterface[];
    supplier: Provider[],
    setSupplier: React.Dispatch<React.SetStateAction<InvoiceTranslation>>;
    disabled: boolean; // permite activar y desactivar el texField, para poder editar
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    isNew?: boolean; // es nueva o ya existe en la absbasee de datos
    indexProvider: number; // index provider;
    handleMoveProduct: (id: number, label: string) => void;
    handleAutoSave?: ()=>void;
    countryList: {country: string; alpha2Code: string, phoneCode: string }[];
}

export const TabsProduct: React.FC<TabsProductProps> = ({
    products,
    supplier,
    setSupplier,
    disabled,
    setDisabled,
    isNew,
    indexProvider,
    handleMoveProduct,
    handleAutoSave=()=>{},
    countryList
}) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [addProduct, setAddProduct] = useState<boolean>(false);
    const [productActive, setProducActive] = useState<number>(0);    
    const [providerId, setProviderId] = useState<number>(0);

    const [product, setProduct] = useState<ProductDeclareInterface>({
        id: 0,
        name: '',
        brand: '',
        model: '',
        status: 'Nuevo',
        descriptionMin: '',
        principalFunction: '',
        // amount: undefined,
        // typeAmount: '',
        // countryOrigin: {
        //     name: '',
        //     alpha2Code: '',
        //     imgFlag: '',
        // },
        // countryAcquisition:  {
        //     name: '',
        //     alpha2Code: '',
        //     imgFlag: '',
        // },
        photos: [{
            name: '',
            url: '',
        }, {
            name: '',
            url: '',
        }, {
            name: '',
            url: '',
        }, {
            name: '',
            url: '',
        }, {
            name: '',
            url: '',
        }],
    });

     // handle productos
     const handleNameProductChange = ( id: number, value: string, first: boolean = false,  provideridx?: number) => {
        if(first) {
            setProduct((prev) => {
                return {
                    ...prev,
                    id: id,
                    name: value
                }
            });

        } else {
            setSupplier((prev) => ({
                ...prev,
                provider: prev.provider.map((s, idx) => idx === provideridx ? {...s, product: s.product.map((p, idy) => idy === id ? {...p, name: value}: p )} : s)
            }));
            
        }

        // handleAutoSave();
    }

    const handleBrandProductChange = (id: number, value: string, first: boolean = false, provideridx?: number) => {
        if(first) {
            setProduct((prev) => {
                return {
                    ...prev,
                    id: id,
                    brand: value
                }
            })
        } else {
            setSupplier((prev) => ({
                ...prev,
                provider: prev.provider.map((s, idx) => idx === provideridx ? {...s, product: s.product.map((p, idy) => idy === id ? {...p, brand: value}: p )} : s)
            }));
            // handleAutoSave();
        }
      
    }

    const handleModelProductChange = (id: number, value: string, first: boolean = false, provideridx?: number) => {
        if(first) {
            setProduct((prev) => {
                return {
                    ...prev,
                    id: id,
                    model: value
                }
            })
        } else {
            setSupplier((prev) => ({
                ...prev,
                provider: prev.provider.map((s, idx) => idx === provideridx ? {...s, product: s.product.map((p, idy) => idy === id ? {...p, model: value}: p )} : s)
            }));
            // handleAutoSave();
        }
    }

    const handleStatusProductChange = (id: number, value: string, first: boolean = false, provideridx?: number) => {
        if(first) {
            setProduct((prev) => {
                return {
                    ...prev,
                    id: id,
                    status: value
                }
            })
        } else {
            setSupplier((prev) => ({
                ...prev,
                provider: prev.provider.map((s, idx) => idx === provideridx ? {...s, product: s.product.map((p, idy) => idy === id ? {...p, status: value}: p )} : s)
            }));
        }
    }

    const handleDescripcionProductChange = (id: number, value: string, first: boolean = false, provideridx?: number) => {
        if(first) {
            setProduct((prev) => {
                return {
                    ...prev,
                    descriptionMin: value
                }
            })
        } else {
            setSupplier((prev) => ({
                ...prev,
                provider: prev.provider.map((s, idx) => idx === provideridx ? {...s, product: s.product.map((p, idy) => idy === id ? {...p, descriptionMin: value}: p )} : s)
            }));
            // handleAutoSave();
        }
    }

    const handlePrincipalProductChange = (id: number, value: string, first: boolean = false, provideridx?: number) => {
        if(first) {
            setProduct((prev) => {
                return {
                    ...prev,
                    principalFunction: value
                }
            })
        } else {
            setSupplier((prev) => ({
                ...prev,
                provider: prev.provider.map((s, idx) => idx === provideridx ? {...s, product: s.product.map((p, idy) => idy === id ? {...p, principalFunction: value}: p )} : s)
            }));
            // handleAutoSave();
        }
    }


    const handlePhotoArrayChange = async (id: number, dataImg: {name: string, url: string} , first: boolean, photoIdx: number, provideridx?: number) => {
        try {
        setLoading(true);
        const updatePhoto = await StorangeService.UploadString(dataImg.url, 'product/'+dataImg.name);
       
        if(first) {
            setProduct((prev) => {
                return {
                    ...prev,
                    photos: prev.photos.map((p, idx) => idx === photoIdx ? {
                        name: dataImg.name,
                        url: updatePhoto as string
                    } : p )
                }
            })
        } else {
            setSupplier((prev) => ({
                ...prev,
                provider: supplier.map((s, idx) => idx === provideridx ? {
                    ...s, 
                    product: s.product.map((p, idy) => idy === id ? {
                        ...p, 
                        photos: p.photos.map((ph, idz) => idz ===photoIdx ? {
                            name: dataImg.name,
                            url: updatePhoto as string
                        } : ph )
                    }: p)
                }: s)   
            }));
        }
        setLoading(false);
        } catch (error) {
            console.log("🚀 ~ handlePhotoArrayChange ~ error:", error);
            toast.error("Error al subir la imagen");
            setLoading(false);
        }
        // handleAutoSave();
    }   

    const handleDeleteProductClick = (id: number, provideridx?: number) => {
        setSupplier((prev) => ({
            ...prev,
            provider: supplier.map((s, idx) => idx === provideridx ? {...s, product: s.product.filter((p, idy) => idy !== id )} : s)
        }));
        // handleAutoSave();
    }

    const handleTrashClick = (idex: number) => {
        if (addProduct) {
            setAddProduct(false); setProducActive(0)
        }  else {
            handleDeleteProductClick(idex, indexProvider);
        }
    }

    useEffect(() => {
        setAddProduct(!supplier.some(s => s.product.length>0));
    }, []);
 
    useEffect(() => {
        if(product.name.length>0 && product.brand.length>0 && product.model.length>0 && product.status.length>0 &&
        product.descriptionMin.length>0 && product.principalFunction &&
        product.principalFunction.length>0) {
            setSupplier((prev) => ({
                ...prev,
                provider: prev.provider.map((s, idx) => idx === providerId ? {...s, product: [...s.product, product]} : s)
            }));

            // handleAutoSave();
            setProduct({
                id: product.id+1,
                name: '',
                brand: '',
                model: '',
                status: '',
                descriptionMin: '',
                principalFunction: '',
                photos: [{
                    name: '',
                    url: '',
                }, {
                    name: '',
                    url: '',
                }, {
                    name: '',
                    url: '',
                }, {
                    name: '',
                    url: '',
                }, {
                    name: '',
                    url: '',
                }],
            });
        }
    
    }, [product]);

    return (
        <div className="invoceTranslationSupplier-product">
            <div className={products.length>=5 ?"invoceTranslationSupplier-product-tabs-scroll" : "invoceTranslationSupplier-product-tabs"}>
                {products.length>0 && products.map((_, idx) => (
                    <Subheader key={idx} content={"Producto "+ (idx+1)} active={idx===productActive} handleClick={()=>setProducActive(idx)} />
                ))}
                {addProduct && <Subheader content={"Producto "+ (products.length+1)} active={products.length === productActive} handleClick={()=>setProducActive(products.length)} />}
            </div>
            <div className='invoceTranslationSupplier-product-container box-shadow-card'>
                { products.length>0 && products.map((p, idy) => (
                    productActive === idy && <ProductInvoceTranslation
                        key={idy}
                        number={p.id}
                        id={idy}
                        name={p.name}
                        handleNameChange={handleNameProductChange}
                        brand={p.brand}
                        handleBrandChange={handleBrandProductChange}
                        model={p.model}
                        handleModelChange={handleModelProductChange}
                        status={p.status}
                        handleStatusChange={handleStatusProductChange}
                        description={p.descriptionMin}
                        handleDescription={handleDescripcionProductChange}
                        functionPrincipal={p.principalFunction}
                        handleFunctionPrincipalChange={handlePrincipalProductChange}
                        countryList={countryList}
                        disabled={!disabled}
                        providerId={indexProvider}
                        isNew={isNew}
                        providerList={supplier.map(((s, idz )=> 'Proveedor '+(idz+1) ))}
                        handleMoveProduct={handleMoveProduct}
                        photos={p.photos}
                        handleChangePhoto={handlePhotoArrayChange}
                        loading={loading}
                    />
                ))}
                    
                {(products.length === productActive && addProduct) && 
                    <ProductInvoceTranslation
                        // handleDeleteClick={()=>{setAddProduct(false); setProducActive(0)}}
                        id={0}
                        number={0}
                        name={product.name}
                        handleNameChange={handleNameProductChange}
                        brand={product.brand}
                        handleBrandChange={handleBrandProductChange}
                        model={product.model}
                        handleModelChange={handleModelProductChange}
                        status={product.status}
                        handleStatusChange={handleStatusProductChange}
                        description={product.descriptionMin}
                        handleDescription={handleDescripcionProductChange}
                        functionPrincipal={product.principalFunction}
                        handleFunctionPrincipalChange={handlePrincipalProductChange}
                        
                        first={true}
                        countryList={countryList}
                        disabled={!disabled} 
                        providerId={indexProvider}
                        setProviderId={setProviderId}
                        handleMoveProduct={handleMoveProduct}
                        photos={product.photos}
                        handleChangePhoto={handlePhotoArrayChange}
                        loading={loading}

                    />}
            </div>

            <div className='invoceTranslationSupplier-text smalltext'>
                <span className='smalltext invoceTranslationSupplier-span'>IMPORTANTE: </span> 
                Si el producto no tiene Marca/Modelo, favor indicar: <span className="smalltext-header">Sin Marca o Sin Modelo.</span>
            </div>

            <div className='invoceTranslationSupplier-add-container'  >
                <div className="invoceTranslationSupplier-add-container--item  smalltext-header" 
                        onClick={() =>{addProduct?toast.warn("Llene todo los datos para agregar otro producto"): 
                                    setAddProduct(true); setProducActive(products.length)}}> 
                                    + Agregar otro producto
                </div>

                <div className='productInvoceTranslation-trashIcon'>
                    <Trash role='productInvoceTranslationDeleteButton' onClick={()=>handleTrashClick(productActive)}/>
                </div>
            </div>
        </div>
    )
}