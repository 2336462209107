import React, { useEffect, useState } from "react";

/** importando components */
import { Modal } from '../../Modal/Modal/Modal';
import { ReviewUser } from "../../ReviewUser/ReviewUser";

/** Importando interfaces */
import { reviewUserInterface } from "../../../interfaces/reviewUser";

/** importando css */
import './ModalReviewUser.css';

interface ModalReviewUserProps {
    setShowModal: (e: boolean) => void; // medoto para modificar la variable showModal
    showModal: boolean,
    title: string;
    review: reviewUserInterface[],
    userName: string,
}

export const ModalReviewUser: React.FC<ModalReviewUserProps> = ({
    setShowModal,
    showModal,
    title,
    review,
    userName
})=> { 

    return (
        <Modal disableToast setShow={setShowModal} 
               show={showModal} 
               title={title + " - " + userName} className={"modal-review-user"}>
            { review.length>0 ? review.map((r:reviewUserInterface, idx:number)=>( 
                <ReviewUser key={idx} review={r}  />
            )) : <div className="paragraph" style={{textAlign: 'center'}}>El cliente no cuenta con ninguna operación</div>}
        </Modal>
    )
}