import React, { useCallback, useEffect, useState } from "react";


import './DeliveryOrder.css';

import { CardContent } from "../CardContent/CardContent";
import { Button } from "../Button/Button";
import { PreviewDoc } from "../PreviewDoc/PreviewDoc";
import { DeliveryDetailInterface, DeliveryOrderDetailInterfaceDataError, DeliveryOrderInterface } from "../../interfaces/DeliveryOrderInterface";
import { useSelector } from "react-redux";
import { Textarea } from "../Textarea/Textarea";
import { UploadFileArray } from "../UploadFile/UploadFileArray/UploadFileArray";
import { CompressFile, DocsFileValidation, ImageFileValidation, PDFFileValidation, SpreadSheetFileValidation } from "../../Consts/FilesValidation";
import { toast } from "react-toastify";
import { set } from "date-fns";
import { CreateDeliveryOrder, GetDeliverOrderById, UpdateDeliveryOrder } from "../../Services/DeliveryOrder.service";
import { GetServicePlanById } from "../../Services/ServicePlan.service";
import { ServicePlanInterface } from "../../interfaces/ServicesPlanInterface";
import { UpdateOrder } from "../../Services/Order.service";
import { OrderInterface } from "../../interfaces/OrderInterface";
import StoreService from "../../Services/Store.service";
import { Loader } from "../Loader/Loader";
import { GetLastTimeText } from "../../utils/GetLastTimeText";
import { FormatDate } from "../../utils/formatDate";
import { ValidateField } from "../../utils/ValidateField";
import { DeliveryOrderStatus } from "../../@types/deliveryOrderStatus";

interface DeliveryOrderProps {
    deliveryOrderId: string;
    servicePlanId: string;
    orderId: string
    setOrderDetail: React.Dispatch<React.SetStateAction<OrderInterface>>;
}

export const DeliveryOrder: React.FC<DeliveryOrderProps> = ({
    deliveryOrderId,
    servicePlanId,
    orderId,
    setOrderDetail
}) => {
 
    const { userDetail } = useSelector((state: any) => state.user);

    const [isProvider, setIsProvider] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [countWord, setCoundWord] = useState<number>(0);

    const [numRevision, setNumRevision] = useState<number>(0);


    const [deliveryOrder, setDeliveryOrder] = useState<DeliveryOrderInterface>({
        detailDelivery: [],
        totalRevision: 0,
        numRevision: 0,
        userId: '',
        orderId: '',
    });

    const [newDetail, setNewDetail] = useState<DeliveryDetailInterface>({
        detail: '',
        userId: '',
        attachment: [],
        status: 'pending',
        createdAt: new Date(),
        updatedAt: new Date(),
    });

    const [deliveryOrderDetailError, setDeliveryOrderDetailError] = useState<DeliveryOrderDetailInterfaceDataError>({
        detail: {
            message: '',
            status: false,
        },
        attachment: {
            message: '',
            status: false,
        }
    });

    const handleRequirementsLength = (e:  any) => {
        let value = e.target.innerText;

        if(value && value.length<=300) {
            setCoundWord(value.length);
          
        }
    }

    const handleRequirementsChange = (e: any) => {
        let value = e.target.innerText;

        if(value && value.length<=300) {
            setNewDetail((prev) => ({
                ...prev,
                detail: value,
            }));
        }
    }

    const handleOrderDetailAttachment = async (e:any) => {

        if(e.target.files[0]) { 
            try {
                setLoading(true); 
                const responseFile: any = await StoreService.UploadFile(e.target.files[0], `order/`+orderId+`/deliveryOrder/`+e.target.files[0].name);
               if(responseFile) {
                setNewDetail((prev) =>({
                    ...prev,
                   attachment: [
                    ...prev.attachment,
                    {
                        name: e.target.files[0].name,
                        url: responseFile,
                    }
                 ]
                }))
               }
               setLoading(false);
            } catch (error) {
                console.log("🚀 ~ file: DeliveryOrder.tsx:109 ~ handleOrderDetailAttachment ~ error:", error) 
                toast.info("Por favor suba un archivo");
                setLoading(false);
            }
              
        } else {
            toast.info("Por favor suba un archivo");
        }
    }

    const handlreDeleteOrderDetailAttachment = async (i: number) => {
        setLoading(true);
        toast.success("¡Se elimino con éxito el archivo!");
        setLoading(false);
    }
    
    // Get deliveryOrder by id
    const getDeliveryOrderById = async (deliveryOrderId: string) => {
        if(!loading) {
            try {
                setLoading(true);
                const response: DeliveryOrderInterface = await GetDeliverOrderById(deliveryOrderId);

                setDeliveryOrder(response);
                setLoading(false);
            } catch (error) {
                console.log("🚀 ~ file: OrderDelivery.tsx:75 ~ getDeliveryOrderById ~ error:", error)
                setLoading(false);
                toast.error("¡Hubo un error al cargar los datos, intentelo en unos minutos :v!");
            }
        }
    }

    // Create deliveryOrder
    const createDeliveryOrder = async () => {
        if(!loading && servicePlanId.length>0 && orderId.length>0) {
            try {
                setLoading(true);
                const indexPlan = servicePlanId.split('--')[1];
                const servicePlan: ServicePlanInterface = await GetServicePlanById(servicePlanId.split('--')[0]);
                
                let tmpDeliveryOrder: DeliveryOrderInterface = {
                    detailDelivery: [],
                    totalRevision: Number(servicePlan.numRevision.values[parseInt(indexPlan)].value) || 0,
                    numRevision: 0,
                    userId: userDetail.id,
                    orderId: orderId,
                }
                const response = await CreateDeliveryOrder(tmpDeliveryOrder);

                if(response) {
                    tmpDeliveryOrder.id = response.id;
                    setDeliveryOrder(tmpDeliveryOrder);
                     
                    await UpdateOrder({id: orderId, deliveryOrderId: response.id, updatedAt: new Date()});
  
                    setOrderDetail((prev) => ({
                        ...prev,
                        deliveryOrderId: response.id,
                    }));

                    setNewDetail({
                        detail: '',
                        userId: '',
                        attachment: [],
                        status: 'pending',
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    });
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ file: OrderDelivery.tsx:116 ~ createDeliveryOrder ~ error:", error)
                toast.error("¡Hubo un error al crear los datos, intentelo en unos minutos!");        
            }
        }
    }

    // Update deliveryOrder
    const handleSendDeliveryOrder = async () => {
        const error = validateFields();
        if(!loading && deliveryOrder?.id && !error) {
            try {
                setLoading(true);
                let tmpDeliveryOrder: DeliveryOrderInterface = {
                    ...deliveryOrder,
                    detailDelivery: [
                        ...deliveryOrder.detailDelivery,
                        newDetail
                    ],
                   
                }
                setDeliveryOrder(tmpDeliveryOrder)
                setTimeout(async () => {
                    await UpdateDeliveryOrder(deliveryOrderId, tmpDeliveryOrder);
                }, 700);
                setLoading(false);
                toast.success("Los datos se guardaron con éxito");
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ file: DeliveryOrder.tsx:192 ~ handleSendDeliveryOrder ~ error:", error)
                toast.error("¡Hubo un error al actualizar los datos, intentelo en unos minutos!");
            }
          
        }
    }

    const handleRequestReviewChangeStatus = async (idx: number, status: DeliveryOrderStatus ) => {
        if(!loading && deliveryOrder?.id) {
            try {
                    setLoading(true);
                    let tmpDetailDelivery = deliveryOrder.detailDelivery;
                    tmpDetailDelivery[idx].status = status;
            
                    let tmpDeliveryOrder: DeliveryOrderInterface = {
                        ...deliveryOrder,
                        detailDelivery: tmpDetailDelivery,
                        numRevision: deliveryOrder.detailDelivery.length>0 ? deliveryOrder.numRevision + 1 : 0,
                    }
            
                    setDeliveryOrder(tmpDeliveryOrder);
                    await UpdateDeliveryOrder(deliveryOrderId, tmpDeliveryOrder);
                    setLoading(false);
            } catch (error) {
                console.log("🚀 ~ file: DeliveryOrder.tsx:249 ~ handleRequestReview ~ error:", error)
                setLoading(false); 
            }
        }
    }

    // Function to reject delivery order

    // Function validate fields
    const validateFields = (): boolean => {
        let error = false;

        error = ValidateField(newDetail.detail, "required") ||
        ValidateField(newDetail.attachment, "array");

        setDeliveryOrderDetailError((prev) => ({
            ...prev,
            detail: {
                message: error ? "Por favor ingrese una descripción" : "",
                status: error,
            },
            attachment: {
                message: error ? "Por favor ingrese un archivo" : "",
                status: error,
            }
        }));
        return error;
    } 

    useEffect(() => {
        if(deliveryOrderId.length>0) {
            getDeliveryOrderById(deliveryOrderId)
        } else {
            createDeliveryOrder();
        }
    }, [])

    useEffect(() => {
      setIsProvider(userDetail. userType.find((item: any) => item === 'provider') === 'provider');
    }, [userDetail]);


   
    return (
        <>
            {deliveryOrder && !loading ?
            <div className="orderDelivery">
            { deliveryOrder.detailDelivery.length > 0  && deliveryOrder.detailDelivery.map((d, idx) => (
                <>
                    <CardContent className={`orderDelivery ${d.status === 'rejected' && 'orderDelivery-disabled'}`}>
                        <div className="paragraph-header">Entrega de orden</div>
                        <div className="answer-detail paragraph">
                            {d.detail}
                        </div>

                        <div className="orderDelivery-attachment">
                            { d.attachment.length > 0 && d.attachment.map((a, idx) => (
                                <PreviewDoc 
                                    key={idx} 
                                    name={a.name}
                                    url={a.url}
                                    disabled={d.status === 'rejected'}/>
                            ))} 
                        </div>    

                        <div className="orderDelivery-footer">
                            <div className={"orderDelivery-revision"}>
                                {d.status == 'pending' && !isProvider && idx <= deliveryOrder.totalRevision && 
                                    <Button 
                                    content='Solicitar revisión' 
                                    size="small"
                                    onClick={()=>handleRequestReviewChangeStatus(idx, 'rejected')}
                                />}
                                {idx>=0 &&<div className="smalltext orderDelivery-text">
                                    
                                    {idx <= deliveryOrder.totalRevision ? idx +' '+ 'de '+ deliveryOrder.totalRevision + ' ' +`${deliveryOrder.numRevision>1 ? 'revisiones posibles' : 'revisión posible'}` : 'Sin revisiones' }
                                </div>}
                            </div>

                            <div className="smalltext orderDelivery-text">
                                {FormatDate(Object.prototype.toString.call(d.createdAt) === "[object Date]" ? d.createdAt : new Date(d.createdAt.toDate()))}
                            </div>
                        </div>
                    </CardContent>

                    {!isProvider && d.status === 'pending' &&
                        <div className="customsAgency-provider-button">
                            <Button 
                                content={"Aprobar entrega"} 
                                size="large"
                                onClick={()=>handleRequestReviewChangeStatus(idx, 'approved')}
                            />
                        </div> } 
                </>
                
            ))}

            { deliveryOrder.detailDelivery.length < 1 && !isProvider &&
                <div className="paragraph orderDelivery-message">El proveedor aun no ha entregado la orden </div>
            }

            { isProvider && (deliveryOrder.detailDelivery.length === 0 || deliveryOrder.detailDelivery[deliveryOrder.detailDelivery.length-1].status==='rejected') &&
                <div className="orderDelivery">
                    <CardContent>
                        <div className="paragraph">Entrega de orden</div>
                        <div className="answer-detail paragraph">Envíale una nota u observación a tu cliente sobre tu trabajo.</div>
                        <div className="orderDelivery-detail">
                            <Textarea 
                                title='' 
                                onBlur={(e:any)=>handleRequirementsChange(e)}
                                onKeyUp={(e:any)=>handleRequirementsLength(e)} 
                                value={newDetail.detail} 
                                placeholder='Ej: Carros de juguete para navidad' /> 
                            {deliveryOrderDetailError.detail && <div className='tinytext text-error'>{deliveryOrderDetailError.detail.message}</div>}
                            <div className="smalltext orderDelivery--number"> {countWord} / 1500 caracteres</div>
                        </div> 
                    </CardContent>
                    
                    <UploadFileArray
                        title="Archivos adjuntos"
                        subtitle="Sube aquí los documentos e imágenes a presentar"
                        file={newDetail.attachment || []}
                        handleFileValue={handleOrderDetailAttachment}
                        acceptType={`${CompressFile}, ${DocsFileValidation}, ${SpreadSheetFileValidation}, ${PDFFileValidation}, ${ImageFileValidation}`}
                        handleDelete={handlreDeleteOrderDetailAttachment}
                        isDelete
                        buttonContent="Subir archivo"
                    />
                    {deliveryOrderDetailError.attachment && <div className='tinytext text-error'>{deliveryOrderDetailError.attachment.message}</div>}

                    <div className="customsAgency-provider-button">
                        <Button 
                            content= "Enviar orden" 
                            size="large"
                            onClick= {handleSendDeliveryOrder} 
                        />
                    </div> 
                </div>
            }   
            </div> :
            <Loader/>}
        </>
    )
}