import React from "react";

import './ElementRow.css';

interface ElementRowProps {
    title: string;
    msg?: string;
    content: {
        detail: string,
        subDetail?: string,
        type: 'text' | 'image',
        alert?: boolean
    }[],
    style: object;
}

export const ElementRow: React.FC<ElementRowProps> = ({
    title,
    msg = "",
    content,
    style
}) => {


    return (
        <div className="elementRow-container" style={style} >
            <div className="smalltext elementRow-title elementRow-colorText">{title}</div>
            <div className="elementRow-content">
                <div className="elementRow-content_">
                    {content.length>0 && content.map((c, idx) => {
                        return (
                            <div key={idx} className={"elementRow-row" +(idx===0?' elementRow-middle':'')}>
                                {c.type === 'text'? <><div className="smalltext elementRow-row__detail elementRow-colorText">
                                    <div className="elementRow-text">{c.detail}</div> 
                                        {c.alert && <div className="elementRow-alert">
                                    </div>}
                                    </div> {c.subDetail && <div className="smalltext elementRow-subdetail elementRow-colorText">{c.subDetail}</div>}</>: 
                                <div className="elementRow-image">
                                    <div>
                                        <img src={c.detail} alt="" className="elementRowImg"/> 
                                        <div className="smalltext-header elementRow-colorText">{c.subDetail}</div>
                                    </div>
                                </div>}
                            </div>
                        )
                    })
                    }
                </div>
                {msg && 
                <div className="elementRow-msg-content">
                    <div className="smalltext elementRow-msg elementRow-colorText">{msg}</div>
                </div>}
            </div>
        </div>
    )
}