import React, { useEffect, useState } from "react";
import { ArrowUp } from "../../icons/outline/ArrowUp";

import './FaqCard.css';
import { Up } from "../../icons/solid/Up";
import { ArrowDown_White } from "../../icons/solid/ArrowDown_White";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

interface FaqCardProps {
    question: {
        question: string;
        answer: string
    };
    index: number;
    activeIndex: number;
    setActiveIndex: (index: number) => void;
}

export const FaqCard: React.FC<FaqCardProps> = ({
    question,
    index,
    activeIndex,
    setActiveIndex
}) => {
    
    const isActive = index === activeIndex;
    const [show, setShow] = useState(isActive);

    useEffect(() => {
        setShow(isActive);
    }, [isActive]);

    const handleClick = () => {
        setActiveIndex(index);
    };

    return (
        <div className={` ${(show || isActive)  ? "faqCard-show" : "faqCard-content"}` }>
            <div className="faqCard-content-header" onClick={handleClick}>
                <div className={show ? 
                    "small-bold home-questions-subTitle" : 
                    "paragraph home-questions-subTitle"}>
                        {question.question}
                </div>
                {show ?  
                <img className="home-questions-img-down" src={STORAGE_ASSETS+"/images/png/down-white.png"} /> :
                <Up/>}
            </div>
            {show && 
            <div className="paragraph home-questions-content-answer_">{question.answer}</div>}
        </div>
    )
}