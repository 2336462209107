import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type BackGrayProps = React.HTMLAttributes<HTMLDivElement> & {};

export const BackGray: React.FC<BackGrayProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/back-gray.svg"} alt="" />
    </div>
  );
};
