import React, { useEffect, useState } from "react";

// Importando componentes
import { CargoStatus } from "../CargoStatus/CargoStatus";
import { Row } from "..";
import { DashboardElementsRow } from "../DashboardElementsRow/DashboardElementsRow";
import { Badge } from "../Badge/Badge";

// Importando iconos
import { ArrowUp } from "../../icons/outline/ArrowUp";
import { ArrowDown } from "../../icons/solid/ArrowDown";
import { ArkabiaOp } from "../../icons/outline/ArkabiaOp";

// Importando interfaces
import { operationOrders, operationRequestQuotes } from "../../interfaces/Operation";

// Importando types
import { ServiceChargeStatus } from "../../@types/serviceChargeStatus";

// Importando estilos
import './DashboardElements.css';
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";
import { currencyUtils, GetCurrency } from "../../utils/GetCurrency";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";
import { ContentQuote } from "../ContentQuote/ContentQuote";
import { TableResponsive } from "../TableResponsive/TableResponsive";

interface DashboardElementsProps {
    id: string;
    importation: string; 
    // cargoStatus: ServiceChargeStatus; 
    date: string;
    requestQuoteData: operationRequestQuotes[];
    orderData: operationOrders[]
    handleClickCompare: (e: string) => void;
    handleClickFinishQuote: (e: string) => void;
    handleClickOrder: (operationId: string | undefined, orderId: string | undefined, orderStep: number) => void;
    quote:boolean; // Estado que indica si hay cotizaciones
    order:boolean; // Estado que indica si hay orden
    open?: boolean; // contrala si esta abierto el collapse
    badge?:boolean;
    isCourier?:boolean;
    number:string;
    role?: string;
    currency?: string;
    operationId:string;
    handleClickQuote: (operationId: string | undefined, requestQuoteId: string | undefined, quotationId: string | undefined) => void;
} 

export const DashboardElements: React.FC<DashboardElementsProps> = ({
    id,
    importation,
    operationId,
    date,
    // cargoStatus,
    handleClickCompare,
    handleClickFinishQuote,
    handleClickOrder,
    requestQuoteData,
    orderData,
    open = false,
    quote = false, 
    order = false,
    badge = false,
    isCourier = false,
    number,
    role,
    currency = '',
    handleClickQuote = ()=>{}
}) => {
 
    const [expanded, setExpanded] = useState<boolean>(false);
    const [showCompare, setShowCompare] = useState<boolean>(false);

    const columnsQuote = window.innerWidth > 691 
        ? (badge 
            ? ['Proveedor', 'Orden', 'Importe', 'Cliente', 'Fecha de disputa'] 
            : (isCourier ? ["Courier", "Calificación", "Cotización", "Último mensaje"] : 
                ["Agencia", "Calificación", "Cotización", "Último mensaje"])
          ) 
        : [(isCourier ? "Courier" : "Agencia"), "Calificación", "Cotización"];

    const dataQuote = requestQuoteData.map((r) => ({
        id: r.id,
        quotationId: r.quotationId || 'unknown',
        Proveedor: r.companyName,
        Courier: r.companyName,
        Agencia: r.companyName,
        Orden: "#" + r.numRequestQuote,
        Importe: `${currencyUtils(currency)} ${ConverMoneyFormat(r.price.toString())}`,
        Cliente: r.companyName,
        "Fecha de disputa": r.createdAt,
        Calificación: (
            <div className="rating-container">
                <img src={`${STORAGE_ASSETS}/images/svg/icono_rating.svg`} alt="rating" />
                <span>{(r.serviceRating).toFixed(1)}</span>
            </div>
        ),
        Cotización: r.price === 0 ? 'En proceso' : `${currencyUtils(currency)} ${ConverMoneyFormat(r.price.toString())}`,
        "Último mensaje": window.innerWidth > 690 ? (r.lastMessage || "Todavía no hay ningún mensaje") : ""
    }));

    const columnsOrder = window.innerWidth > 691 
        ? (badge 
            ? ['Proveedor', 'Orden', 'Importe', "Último mensaje"] 
            : (isCourier ? ["Courier", "Calificación", "Cotización", "Último mensaje"] : 
                ["Agencia", "Orden", "Importe total", "Último mensaje"])
          ) 
        : [(isCourier ? "Courier" : "Agencia"), "Orden", "Importe total"];

    const dataOrder = orderData.map((d) => ({
        id: d.id,
        Proveedor: d.companyName,
        Courier: d.companyName,
        Agencia: d.companyName,
        Orden: "#" + d.number,
        Cliente: d.companyName,
        "Importe total": `${currencyUtils(currency)} ${ConverMoneyFormat(d.price.toString())}`,
        "Último mensaje": window.innerWidth > 690 ? (d.lastMessage || "Todavía no hay ningún mensaje") : ""
        // Agrega más campos según sea necesario
    }));

    const handleQuoteItemClick = (requestQuoteId: string | undefined, quotationId: string | undefined) => {
        handleClickQuote(operationId, requestQuoteId, quotationId);
    }

    const handleOrderItemClick = (orderId: string, orderStep: string) => {
        handleClickOrder(operationId, orderId, Number(orderStep));
    }
    
    useEffect(()=> {
        setExpanded(open);
    }, [open]);

    useEffect(() => {
        setShowCompare(requestQuoteData.filter(r => r.price>0).length>=2);
    }, [])


    return (
        <>
            <div className="dashboardElements-container box-shadow-card" role={role}>
                <div onClick={()=> setExpanded(!expanded)} className={`dashboardElements-header ${!expanded && "dashboardElements-header__border"}`} >
                    <div className="dashboardElements-text">
                        <ArkabiaOp />
                        <div className="dashboardElements-title">
                            <div className="smalltext-header dashboardElements__title">{importation}</div>
                            {/* <div className="smalltext dashboardElements__detail">{category}</div> */}
                        </div>
                    </div>
                    <div className="dashboardElements-right">
                        <div className="dashboardElements-date">
                            <div className="tinytext">En cotización</div>
                            {/* { badge ? null : <CargoStatus phase={cargoStatus} role='cargo-status-dashboard'/> } */}
                            {/* {(order && window.innerWidth > 690) && <CargoStatus phase={cargoStatus} role='cargo-status-dashboard'/>} */}
                            <div className="tinytext-header dashboardElements-date-text">{date}</div>
                            { badge ? <Badge number={number} /> : null}
                        {expanded?<ArrowUp /> :<ArrowDown />}
                        </div>
                    </div>
                </div>
                {expanded && <div className="dashboardElements-table">
                    {quote && requestQuoteData.length>0 && 
                        <div className="dashboard-elements-title">
                            <TableResponsive 
                                columns={columnsQuote} 
                                data={dataQuote} 
				                handleClick={handleQuoteItemClick}
                                operationStatus="quote"
                                footer={(requestQuoteData.length>0 ?
                                    (showCompare &&  !isCourier) &&
                                    <div className="smalltext dashboardElements-quote dashboardElementsRow-container" onClick={()=>handleClickCompare(id)} role='btn-dashboardElements'>
                                        Comparar cotizaciones
                                    </div> :
                                    <div className="smalltext dashboardElements-quote" onClick={()=>handleClickFinishQuote(id)} role='btn-dashboardElements'>
                                        Terminar solicitud
                                    </div>)
                                }
                            />
                        </div>
                    }
                    {order && orderData.length>0 && <div className="dashboardElements-title">
                        <div className="dashboard-elements-title">
                            <TableResponsive 
                                columns={columnsOrder} 
                                data={dataOrder} 
                                operationStatus="order"
				                handleClick={handleOrderItemClick}
                            />
                        </div>
                    </div>}
                    
                </div>}
            </div>
        </>
    )
}
