import React, { useRef } from 'react';

import "./CardComment.css";
import { STORAGE_ASSETS } from '../../Consts/BaseUrl';
import { CommentInterface } from '../../interfaces/CommentInterface';
import { VerifyBlue } from '../../icons/solid/Verify_blue';

interface CardCommentProps {
    commentItem: CommentInterface
};


export const CardComment: React.FC<CardCommentProps> = ({
    commentItem
}) => {

  return (
    <div className='card-comment'>
        <div className='paragraph card-comment-text'>{commentItem.comment}</div>
        <div className='card-comment-footer'>
            <img className='card-comment-profile' src={STORAGE_ASSETS+'/images/png/'+commentItem.profile}/>
            <div className='card-comment-column'>
                <div className='tinytext-header card-comment-text card-comment-row'>{commentItem.name}
                    <div className='card-comment-verify'>
                        <VerifyBlue/>
                    </div>
                </div>
                <div className='tinytext card-comment-text'>{commentItem.nickame}</div>
            </div>
        </div>
    </div>
  );
};
