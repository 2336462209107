import React from "react";

import './EventCard.css';
import { UserActive } from "../UserActive/UserActive";
import { EventCardInterface } from "../../interfaces/Event";
import { Dots } from "../../icons/outline/Dots";
import { VerifyBlue } from "../../icons/solid/Verify_blue";
import { VerifyBlack } from "../../icons/solid/VerifyBlack";

interface EventCardProps {
    event: EventCardInterface
}

export const EventCard: React.FC<EventCardProps> = ({
    event
}) => {
    
    return (
        <div className="eventCard-container">
            <div className={"eventCard-marker"}></div>
            <div className="eventCard-body">
                <div className="eventCard-body-content">
                    <div className="smalltext-header eventCard-textColor eventCard-title">{event.title}</div>
                    <div className="eventCard-row">
                        <div className="tinytext eventCard-textColor eventCard-date">{event.startHour + "-" + event.endHour+","}</div>
                        <div className="tinytext eventCard-textColor eventCard-date">{event.date}</div>
                    </div>
                    <div className="eventCard-row">
                        <UserActive 
                            userImg={event.userImg}
                        />
                        <div className="tinytext-header">{event.nameCompany}</div>
                        <div className="eventCard-verify">
                            {event.verify ? <VerifyBlue/> : <VerifyBlack/>}
                        </div>
                    </div>
                </div>
                <div className="eventCard-dots"><Dots/></div>
            </div>                         
        </div>
    )
} 