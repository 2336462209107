import React, { useEffect, useState } from "react";

/** importando modals */
import { Modal } from '../Modal/Modal';

/** importando css */
import "./ModalTyC.css"

/** declarando interface */ 
interface ModalTyCProps {
    showModal: boolean; // estado boolean que cotrola el modal 
    setShowModal: (e: boolean) => void; // medoto para modificar la variable showModal
}

/** componente modal cotizacion
 * renderiza el modal 
 */
export const ModalTyC: React.FC<ModalTyCProps> = ({
    showModal,
    setShowModal,
}) => {

    return (
        <Modal disableToast title={'Términos y Condiciones'} subTitle={''} show={showModal} setShow={setShowModal} className="modal-TyC">
            <div className="modal-tyc-container">
                <div className="modal-tyc-p">
                    <div className="paragraph-header">1 - Arkabia</div>
                    <div className="paragraph">Arkabia es una compañía de tecnología que ofrece servicios vinculados principalmente al comercio electrónico. </div>
                    <div className="paragraph">Los servicios que ofrece Arkabia en 
                        <a className="paragraph-header modal-link" href="https://www.arkabia.com/"> https://www.arkabia.com</a> 
                        y sus aplicaciones móviles (de ahora en más: “Sitio”) están diseñados para formar un ecosistema que permita a las personas vender, 
                        comprar, pagar, enviar productos y realizar otras actividades comerciales con tecnología aplicada (de ahora en más:“Ecosistema”).
                    </div>
                </div>

                <div className="modal-tyc-p">
                    <div className="paragraph-header">2 - Términos y Condiciones</div>
                    <div className="paragraph">Estos términos y condiciones y los anexos que explican los servicios del Ecosistema (de ahora en más: “Términos 
                        y Condiciones”) regulan la relación entre Arkabia y las  personas que usan sus servicios (“Personas Usuarias”). 
                    </div>
                    <div className="paragraph">Las Personas Usuarias aceptan estos Términos y Condiciones desde el momento en que se registran en el Sitio y 
                        usan el Ecosistema.</div>
                    <div className="paragraph">Cuando debamos hacer cambios importantes en nuestros servicios, publicaremos las modificaciones con 10 días 
                    corridos de anticipación para que las Personas Usuarias puedan revisarlas y seguir usando el Ecosistema. En ningún caso afectarán las 
                    operaciones que ya hayan finalizado.</div>
                    <div className="paragraph">Las Personas Usuarias que no tengan obligaciones pendientes con Arkabia o con otras Personas Usuarias, podrán 
                    finalizar la relación con Arkabia cancelando su cuenta.</div>
                </div>

                <div className="modal-tyc-p">
                    <div className="paragraph-header">3 - Capacidad</div>
                    <div className="paragraph">Podrán usar nuestros servicios las personas mayores de edad que tengan capacidad legal para reservar. </div>
                    <div className="paragraph">Quien use el Ecosistema en representación de una empresa deberá tener capacidad para reservar a nombre de ella. 
                    Además, para poder usar la cuenta, la Persona Usuaria debe encontrarse activa. </div>
                </div>

                <div className="modal-tyc-p">
                    <div className="paragraph-header">4 - Registro y Cuenta</div>
                    <div className="paragraph">
                    Quien quiera usar nuestros servicios, deberá completar el formulario de registro con los datos que le sean requeridos. Al completarlo, se 
                    compromete a hacerlo de manera exacta, precisa y verdadera y a mantener sus datos siempre actualizados. La Persona Usuaria será la única 
                    responsable de la certeza de sus datos de registro. Sin perjuicio de la información brindada en el formulario, podremos solicitar y/o consultar 
                    información adicional para corroborar la identidad de la Persona Usuaria. 
                    </div>
                    <div className="paragraph">
                    La cuenta es personal, única e intransferible, es decir que bajo ningún concepto se podrá vender o ceder a otra persona. Se accede a ella 
                    con la clave personal de seguridad que haya elegido y que deberá mantener bajo estricta confidencialidad. Por eso, la Persona Usuaria será 
                    la única responsable por las operaciones que se realicen en su cuenta. En caso de detectar un uso no autorizado de su cuenta, deberá notificar 
                    de forma inmediata y fehaciente a Arkabia a través de sus canales de contacto disponibles en la plataforma. 
                    </div>
                    <div className="paragraph">
                    Podremos rechazar una solicitud de registro o bien cancelar un registro ya aceptado, sin que esto genere derecho a un resarcimiento. No podrán 
                    registrarse nuevamente en el Sitio las Personas Usuarias que hayan sido inhabilitadas previamente. Tampoco podrán registrarse quienes estén 
                    incluidos o relacionados a personas incluidas en listas nacionales o internacionales de sanciones. 
                    </div>
                    <div className="paragraph">
                    Además, en caso de detectar el uso de más de una cuenta, podremos aplicar bloqueos, suspensiones o cualquier otra medida si consideramos que 
                    ese accionar puede perjudicar al resto de las personas que usan el Sitio o a Arkabia, más allá de las sanciones que pudieran corresponder y 
                    determinarse en el momento que se reconoce el acto. 
                    </div>
                </div>

                <div className="modal-tyc-p">
                    <div className="paragraph-header">5 - Privacidad de datos</div>
                    <div className="paragraph">En Arkabia hacemos un uso responsable de la información personal, protegiendo la privacidad de las Personas Usuarias 
                    que nos confiaron sus datos. Por ello, tomamos medidas necesarias para garantizar la seguridad en nuestro Ecosistema.</div>
                    <div className="paragraph">En este sentido, cualquier uso o tratamiento de datos realizado por Arkabia siempre será con el objetivo de mejorar 
                    el Ecosistema de manera continua. Y así generar valor a las Personas Usuarias. </div>
                    <div className="paragraph">Los datos que podríamos recolectar directamente al registrarte o utilizar nuestros servicios se muestran a
                     continuación:</div>
                </div>

                <ul className="paragraph modal-tyc-ul">
                    <li>Nombre</li>
                    <li>Imagen personal (foto de perfil)</li>
                    <li>Número de documento o identificación válida</li>
                    <li>Información de contacto (como número de teléfono, domicilio, dirección de correo electrónico)</li>
                    <li>Datos de cuenta bancaria (número de cuenta, más no códigos o claves secretas)</li>
                    <li>Dispositivos desde donde accedes a la plataforma</li>
                    <li>Dirección IP de internet que utilizas al conectarte a nuestros servicios o al navegar nuestros sitios web. </li>
                    <li>Información transaccional y movimientos dentro de las plataformas de Arkabia (compras, pagos, devoluciones, 
                            preguntas, retiros, transferencias, movimientos de la tarjeta, reclamos, facturación, datos fiscales, cuentas bancarias, mensajería 
                            interna)</li>
                    <li>URL desde la que provienen o acceden seguidamente (estén o no en nuestro sitio web). </li>
                    <li>Páginas visitadas dentro de Arkabia, las interacciones con dichas páginas, las búsquedas realizadas, las publicaciones, 
                            compras o ventas, calificaciones y réplicas ingresadas, reclamos realizados y recibidos, mensajes en los foros, entre otra información 
                            podrá ser almacenada y retenida.</li>
                    <li>Listas de contactos en Arkabia, nombre de clientes e información referida a las operaciones que mantienen con ellos.</li>
                    <li>Vinculaciones entre cuentas y usuarios, con motivo de acciones de prevención del fraude.</li>
                    <li>Datos para gestión de reclamos y juicios (información para la elaboración de documentos, antecedentes y estrategias).</li>
                </ul>

                <div className="paragraph">Asimismo se menciona la información que podríamos recopilar de otras fuentes:</div>
                <ul className="paragraph modal-tyc-ul">
                    <li>Información recopilada para finalidades de prevención de fraude y cumplimiento de regímenes informativos (listas SBS, SUNAT, etc.).</li>
                    <li>Información crediticia, positiva y negativa, que obtenemos de bases o centrales de riesgo crediticio, empresas de telecomunicaciones 
                        y/o de fuentes de acceso público, de conformidad con la legislación aplicable.
                    </li>
                    <li>Datos que se utilizan para la validación de identidad, completar o corregir información, obtenidos de fuentes seguras y confiables, 
                        tales como organismos públicos, proveedores de servicios o aliados comerciales con los que trabajamos.
                    </li>
                </ul>

                <div className="modal-tyc-p">
                    <div className="paragraph-header">6 - Sanciones</div>
                    <div className="paragraph">En caso que la Persona Usuaria incumpliera una ley o los Términos y Condiciones, podremos advertir, suspender, 
                        restringir o inhabilitar temporal o definitivamente su cuenta, sin perjuicio de otras sanciones que se establezcan en las reglas o 
                        determine Arkabia en el momento. 
                    </div>
                </div>

                <div className="modal-tyc-p">
                    <div className="paragraph-header">7 - Responsabilidad</div>
                    <div className="paragraph">Arkabia será responsable por cualquier defecto en la prestación de su servicio, en la medida en que le sea 
                    imputable y con el alcance previsto en las leyes vigentes.  </div>
                </div>

                <div className="modal-tyc-p">
                    <div className="paragraph-header">8 - Tarifas</div>
                    <div className="paragraph">
                        Arkabia podrá cobrar por sus servicios y la Persona Usuaria se compromete a pagarlos a tiempo. Podremos modificar o eliminar las 
                        tarifas en cualquier momento con el debido preaviso establecido 
                        en la cláusula 2 de estos Términos y Condiciones. De la misma manera, podremos modificar o suspender las tarifas temporalmente por 
                        promociones en favor de las Personas Usuarias. 
                    </div>
                    <div className="paragraph">La Persona Usuaria autoriza a Arkabia a retener y/o debitar los fondos existentes y/o futuros de las cuentas 
                    bancarias que haya registrado en ella mediante previo aviso, 
                    para saldar las tarifas impagas o cualquier otra deuda que pudiera tener.</div>
                    <div className="paragraph">Para conocer el detalle de las tarifas de cada servicio, las Personas Usuarias deberán consultar los términos y 
                    condiciones correspondientes que figuran dentro de la 
                    plataforma. Cuando se desea ofrecer un servicio en Arkabia. </div>
                    <div className="paragraph">En todos los casos se emitirá la factura de conformidad con los datos fiscales que las personas tengan cargados 
                    en su cuenta.</div>
                </div>

                <div className="modal-tyc-p">
                    <div className="paragraph-header">9 - Propiedad Intelectual</div>
                    <div className="paragraph">
                        Arkabia y/o sus sociedades relacionadas son propietarias de todos los derechos de propiedad intelectual sobre sus sitios, todo su contenido, 
                        servicios, productos, marcas, nombres comerciales, 
                        logos, diseños, imágenes, frases publicitarias, derechos de autor, dominios, programas de computación, códigos, desarrollos, software, 
                        bases de datos, información, tecnología, patentes y modelos 
                        de utilidad, diseños y modelos industriales, secretos comerciales, entre otros (“Propiedad Intelectual”) y se encuentran protegidos por 
                        leyes nacionales e internacionales.
                    </div>
                    <div className="paragraph">
                        Aunque Arkabia otorga permiso para usar sus productos y servicios conforme a lo previsto en los Términos y Condiciones, esto no implica 
                        una autorización para usar su  Propiedad Intelectual, 
                        excepto consentimiento previo y expreso de Arkabia y/o sus sociedades vinculadas. En cualquier caso, los usuarios vendedores que usen 
                        dichos productos y servicios no podrán utilizar la Propiedad 
                        Intelectual de Arkabia de una manera que cause confusión en el público y deberán llevar a cabo su actividad comercial bajo una marca o 
                        nombre comercial propio y distintivo, que no resulte 
                        confundible con la marca Arkabia y su familia de marcas vinculadas.
                    </div>
                    <div className="paragraph">Está prohibido usar nuestros productos o servicios para fines ilegales, realizar cualquier tipo de ingeniería 
                    inversa u obras derivadas, utilizar herramientas de búsqueda 
                    o de extracción de datos y contenidos de nuestra plataforma para su reutilización y/o crear bases de datos propias que incluyan en todo o en 
                    parte nuestro contenido sin nuestra expresa autorización. 
                    Está también prohibido el uso indebido, sin autorización y/o contrario a la normativa vigente y/o que genere confusión o implique uso 
                    denigratorio y/o que le cause perjuicio, daños o pérdidas a 
                    Arkabia y/o a sus sociedades relacionadas. La utilización de los productos y servicios de Arkabia tampoco implica la autorización para usar 
                    propiedad intelectual de terceros que pueda estar involucrada, 
                    cuyo uso quedará bajo exclusiva responsabilidad del usuario. </div>
                    <div className="paragraph">En caso que una Persona Usuaria o cualquier publicación infrinja la Propiedad Intelectual de Arkabia o de terceros, 
                    Arkabia podrá remover dicha publicación total o parcialmente), 
                    sancionar al usuario conforme a lo previsto en estos Términos y Condiciones y ejercer las acciones extrajudiciales y/o judiciales correspondientes.
                    </div>
                </div>

                <div className="modal-tyc-p">
                    <div className="paragraph-header">10 - Indemnidad</div>
                    <div className="paragraph">La Persona Usuaria mantendrá indemne a Arkabia y sus sociedades relacionadas, así como a quienes la dirigen, suceden, 
                    administran, representan y/o trabajan en ellas, por cualquier 
                    reclamo administrativo o judicial iniciado por otras Personas Usuarias, terceros o por cualquier Organismo, relacionado con sus actividades en el 
                    Ecosistema.
                    </div>
                    <div className="paragraph">En virtud de esa responsabilidad, podrán realizar compensaciones, retenciones u otras medidas necesarias para la 
                    reparación de pérdidas, daños y perjuicios, cualquiera 
                    sea su naturaleza.</div>
                </div>

                <div className="modal-tyc-p">
                    <div className="paragraph-header">11 - Jurisdicción y Ley Aplicable</div>
                    <div className="paragraph">La Persona Usuaria mantendrá indemne a Arkabia y sus sociedades relacionadas, así como a quienes la dirigen, suceden, 
                    administran, representan y/o trabajan en ellas, por 
                    cualquier reclamo administrativo o judicial iniciado por otras Personas Usuarias, terceros o por cualquier Organismo, relacionado con sus 
                    actividades en el Ecosistema.
                    </div>
                    <div className="paragraph">Estos Términos y Condiciones se rigen por la ley local. Toda controversia derivada de su aplicación, interpretación, 
                    ejecución o validez será resuelta por los tribunales 
                    nacionales ordinarios competentes, con asiento en la capital, salvo disposición específica de normas de orden público, como por ejemplo, 
                    legislación relativa al Consumidor. Para todos los efectos relacionados con estos Términos y Condiciones y con el uso del sitio, Arkabia del 
                    Peru S.A.C. con RUC 20609603675, establece como domicilio Av. Don Diego de Día 228, Santiago de Surco, Lima, Perú.</div>
                </div>
            </div>
        </Modal>
    )
}
