import React, { useEffect, useState } from "react";

// Importando estilo
import "./Plan.css";
import { PlanInterface } from "../../interfaces/PlansInterface";

interface PlanProps {
    currencyActive: "USD" | "PEN";
    plan: PlanInterface
    onclickPlan: (plan: PlanInterface) => void;
    isPlanSelected: boolean;
    black?: boolean;
}

export const Plan: React.FC<PlanProps> = ({
    currencyActive,
    plan,
    onclickPlan,
    isPlanSelected,
    black = true
}) => {

    return (
    <div 
        className={`plan-content 
            ${isPlanSelected 
                //  !plan.isPopular
                 ? (black ? 'plan-selected-white' :'plan-selected-black') : "" } 
            ${black ? "plan-black" : "plan-white"}`} 
        onClick={()=>onclickPlan(plan)}
    >
            {plan.isPopular && 
        <div className={"plan-popular"}>
            <div className="paragraph-header">
                Más popular
            </div>
        </div>}
        <div className={`plan-gradient ${plan.isPopular && " plan-gradient-popular"}`}>
            <div className="plan">
                <div className="plan-body-first">
                    <div className="plan-header-price">
                        <div className="plan-header">
                            <div className={`small-bold ${black ? "plan-text-white" : "plan-text-black"} plan-text-title`}>
                                {plan.title}
                            </div>
                            <div className="plan-price">
                                <div className={`big-header ${black ? "plan-text-white" : "plan-text-black"}`}>
                                    {currencyActive === "USD" ? plan.priceUsd : plan.pricePen}
                                </div>
                               
                                <div className={`smalltext ${black ? "plan-text-white" : "plan-text-black"} plan-price__text`}>
                                    {`${currencyActive} ${plan.billingFrequency.length>0 ? ' / ' + plan.billingFrequency : ''}`}
                                </div>
                                    {/* <div className="tinytext-header plan-text">{plan.note}</div> */}
                                 
                            </div>
                        </div>
                    </div>

                    <div className="plan-detail-row">
                        <div className={`plan-body smalltext ${black ? "plan-text-white" : "plan-text-black"}`}>
                            {plan.minDescription}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};
