import React, { useEffect, useState } from "react";

/** importando modals */
import { Modal } from '../../Modal/Modal/Modal';

import { Whatsapp } from "../../../icons/solid/Whatsapp";
import { Message } from "../../../icons/outline/Message";
import { Phone } from '../../../icons/outline/Phone'

import { ButtonIcon } from "../../ButtonIcon/ButtonIcon";

/** importando css */
import './ModalSendMessage.css';
import { MessageBlack } from "../../../icons/outline/MessageBlack";
import { PhoneBlack } from "../../../icons/outline/PhoneBlack";
import { WhatsappBlack } from "../../../icons/outline/WhatsappBlack";
interface ModalSendMessageProps {
    setShowModal: (e: boolean) => void; // medoto para modificar la variable showModal
    showModal: boolean,
    title: string;
    agencyName: string;
    direction: string;
    email: string;
    phone: string;
    whatsapp: string;
    message?: string;
    buttons?: React.ReactNode[];
//     contactName: string;
    handleClose?: ()=>void;
}

export const ModalSendMessage: React.FC<ModalSendMessageProps> = ({
    setShowModal,
    showModal,
    // title,
    // agencyName,
    direction,
    email,
    phone,
    whatsapp,
    buttons=[],
    message='',
    handleClose = ()=>{},
    // contactName
})=> { 

    const handleWhatsapp = () => {
        window.open(whatsapp.length>0 ? 'https://api.whatsapp.com/send/?phone='+whatsapp+'&text='+message+'&type=phone_number&app_absent=0': '', '_blank')
    }

    const handleEmail = () => {
        window.open(email.length>0 ? 'mailto:'+email+'?subject=subject text': '', '_blank')
    }

    const handlePhone = () => {
        window.open(phone.length>0 ? 'tel:'+phone+'':'', '_blank')
    }

    return (
        <Modal 
            onClose={handleClose} 
            setShow={setShowModal} 
            show={showModal} 
            title={ 'Contacto'
                // title+agencyName
            } 
            className="modal-sendMessage" 
            disableToast>
            {/* <div className="modal-send-content__row">
                <div className="smalltext-header">Nombre: </div>
                <div className="smalltext">{agencyName}</div>
            </div> */}
            <div className="modal-send-content">
                <div className="modal-send-content_column">
                     {/* <div className="modal-send-content__row__">
                        <div className="smalltext"> La agencia <span className="smalltext-header"> {agencyName}</span> se pondrá pronto en contacto contigo. ¡Éxitos! </div>
                         
                     </div> */}
                    {whatsapp && whatsapp.length>0 && <div className="modal-send-content__row__">
                        <div className="modal-send-content__row___" onClick={handleWhatsapp}>
                            <WhatsappBlack/>
                            {/* <div className="smalltext-header">Whatsapp: </div> */}
                            <div className="paragraph modal-contact-info">{whatsapp}</div>
                        </div>
                        {/* <div className="modal-send-content-button">
                            <ButtonIcon
                                Icon={<Whatsapp className="icon-whatsapp"/>}
                                content="Hablar por whatsapp"
                                color="green-2"
                                size="extra-small"
                                
                            />
                        </div> */}
                    </div>}
                    {email &&<div className="modal-send-content__row__">
                        <div className="modal-send-content__row___" onClick={handleEmail}>
                            <MessageBlack/>
                            {/* <div className="smalltext-header">Correo electrónico: </div> */}
                            <div className="paragraph">{email}</div>
                        </div>
                        {/* <div className="modal-send-content-button">
                            <ButtonIcon
                                Icon={<Message className="icon-message"/>}
                                content="Enviar correo"
                                color="blue-2"
                                size="extra-small"
                                onClick={() => {}}
                            />
                        </div> */}
                    </div>}
                    {phone &&<div className="modal-send-content__row__">
                        <div className="modal-send-content__row___" onClick={handlePhone}>
                            <PhoneBlack/>
                            {/* <div className="smalltext-header">Teléfono: </div> */}
                            <div className="paragraph">{"+51"+ " " + phone}</div>
                        </div>
                        {/* <div className="modal-send-content-button">
                            <ButtonIcon
                                Icon={<Phone className="icon-phone"/>}
                                content="Llamar"
                                color="yellow"
                                size="extra-small"
                                onClick={() => {}}
                            />
                        </div> */}
                    </div>}
                    {direction && direction.length>0 &&<div className="modal-send-content__row__">
                        <div className="smalltext-header">Dirección: </div>
                        <div className="smalltext">{direction}</div>
                    </div>}
                </div>
{/* 
                <div className="modal-send-content_column_">
                    {whatsapp && whatsapp.length>0 && <ButtonIcon
                        Icon={<Whatsapp className="icon-whatsapp"/>}
                        content="Hablar por whatsapp"
                        color="green-2"
                        size="extra-small"
                        onClick={() => {window.open(whatsapp.length>0 ? 'https://api.whatsapp.com/send/?phone='+whatsapp+'&text='+message+'&type=phone_number&app_absent=0': '', '_blank')}}
                    />}

                    {email && email.length>0 && <ButtonIcon
                        Icon={<Message className="icon-message"/>}
                        content="Enviar correo"
                        color="blue-2"
                        size="extra-small"
                        onClick={() => {window.open(email.length>0 ? 'mailto:'+email+'?subject=subject text': '', '_blank')}}
                    />}

                    {phone && phone.length>0 && <ButtonIcon
                        Icon={<Phone className="icon-phone"/>}
                        content="Llamar"
                        color="yellow"
                        size="extra-small"
                        className="btn-phone-color"
                        onClick={() => {window.open(phone.length>0 ? 'tel:'+phone+'':'', '_blank')}}
                    />}
                </div> */}

                {/* <div>Nombre del contacto: {contactName}</div> */}
                {/* {buttons && buttons.length>0  && <div className="modalSendMessage-buttons">{buttons.map((b) => b)}</div>}  */}
            </div>
        </Modal>
    )
}