import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type HelpCircleWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const HelpCircleWhite: React.FC<HelpCircleWhiteProps> =({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+"/images/svg/help-white.svg"} alt="help_circle" />
        </div>
    )
}