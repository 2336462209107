import React, { useCallback, useEffect, useState } from "react";

/** importando components */
import { Modal } from '../../Modal/Modal/Modal';

/** Importando interfaces */
import { review, reviewUserInterface } from "../../../interfaces/reviewUser";

/** importando css */
import './ModalReview.css';
import { UserActive } from "../../UserActive/UserActive";
import { TextField } from "../../TextField/TextField";
import { Button } from "../../Button/Button";
import { Rating } from "../../Rating/Rating";
import { MedalRating } from "../../../icons/outline/MedalRating";
import { RatingMarker } from "../../RatingMarker/RatingMarker";
import { RatingSlider } from "../../RatingSlider/RatingSlider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "../../Loader/Loader";
import { ServiceInterface } from "../../../interfaces/Service";
import { GetServiceDetail } from "../../../Services/Service.service";
import { ReviewInterface } from "../../../interfaces/ReviewServiceInterface";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../Store/Store";
import { SemanticClassificationFormat } from "typescript";
import { RatingService } from "../../RatingService/RatingService";
import { ReviewServiceError } from "../../../interfaces/ReviewServiceError";
import { ValidateField } from "../../../utils/ValidateField";
import { ReviewUnknownInterface } from "../../../interfaces/ReviewUknow";
import { CreateReviewUknow } from "../../../Services/ReviewUknow.service";
import { toast } from "react-toastify";


interface ModalReviewProps {
    setShowModal: (e: boolean) => void; // medoto para modificar la variable showModal
    showModal: boolean,
    isLoggin: boolean
}

export const ModalReview: React.FC<ModalReviewProps> = ({
    setShowModal,
    showModal,
    isLoggin = false
})=> { 

    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const {userDetail} = useSelector((state: any) => state.user);

    const [loading, setLoading] = useState<boolean>(false);
    const [reviewServiceError, setReviewServiceError] = useState<ReviewServiceError>({
        name: {
            message: "",
            status: false,
        },
        lastName: {
            message: "",
            status: false,
        },
        business: {
            message: "",
            status: false,
        },
        position: {
            message: "",
            status: false,
        },
        photoURL: {
            message: "",
            status: false,
        },
        rating: {
            message: "",
            status: false,
        },
        commet: {
            message: "",
            status: false,
        },
    });

    const [countWord, setCoundWord] = useState<number>(0);
    const [review, setReview] = useState<ReviewUnknownInterface>({
        comment: "",
        serviceId: "",
        companyId: "",
        rating: 0,
        user: {
            userId: '',
            name: "",
            lastName: "",
            urlPhoto: "",
            business: "",
            position: "",
        },
        createdAt: new Date(),
    });
    const [next, setNext] = useState<number>(0);
    const [service, setService] = useState<ServiceInterface>();

    const validateTextFields = (): boolean =>  {
        let error = false;
        if(next === 1) {
            error = 
            ValidateField(review.user.name, 'required') || 
            ValidateField(review.user.lastName, 'required') ||
            ValidateField(review.user.business, 'required') ||
            ValidateField(review.user.position, 'required');
            // ValidateField(review.user.urlPhoto, 'required');

            setReviewServiceError((prev) => ({
                ...prev,
                // photoURL: {
                //     message: 'Ingrese una foto',
                //     status: ValidateField(review.user.urlPhoto, 'required')
                // },
                name: {
                    message: 'Ingrese su nombre',
                    status: ValidateField(review.user.name, 'required')
                },
                lastName: {
                    message: 'Ingrese su apellido',
                    status: ValidateField(review.user.lastName, 'required')
                },
                business: {
                    message: 'Ingrese su empresa',
                    status: ValidateField(review.user.business, 'required')
                },
                position: {
                    message: 'Ingrese su cargo',
                    status: ValidateField(review.user.position, 'required')
                },
            }));
        } else if (next === 2) {
            error = 
            ValidateField(review.comment, 'required') ||
            ValidateField(review.rating, 'number');

            setReviewServiceError((prev) => ({
                ...prev,
                rating: {
                    status: ValidateField(review.rating, 'number'),
                    message: 'Ingrese su calificación'
                }, 
                commet: {
                    status: ValidateField(review.comment, 'required'),
                    message: 'Ingrese su comentario',
                }
            }));
        }
        return error;
    }

     // method to change title
     const handleCommentChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if(value.length<=90) {
            setReview((prev:any) =>({...prev, comment:value}));
            // setQuoteTitle(e.target.value);
            setCoundWord((value.length));
        } 
    }, [setReview]);


    // declaration of method to change rating
    const handleRating = (value: number) => {
        setReview((prev) => ({
                ...prev,
                rating: value
            }));
    }

    // declaration of method to change name
    const handleName = (value: string) => {
        setReview((prev) => ({
                ...prev,
                user: {
                    ...prev.user,
                    name: value
                }
            }));
    }

    // declaration of method to change lastName
    const handleLastName = (value: string) => {
        setReview((prev) => ({
            ...prev,
            user: {
                ...prev.user,
                lastName: value
            }
        }));
    }

    // declaration of method to change business
    const handleBusiness= (value: string) => {
        setReview((prev) => ({
            ...prev,
            user: {
                ...prev.user,
                business: value
            }
        }));
    }

    // declaration of method to change position
    const handlePosition= (value: string) => {
        setReview((prev) => ({
            ...prev,
            user: {
                ...prev.user,
                position: value
            }
        }));
    }

    // declaration of method to change photo
    const handlePhoto= (value: string) => {
        setReview((prev) => ({
            ...prev,
            user: {
                ...prev.user,
                urlPhoto: value
            }
        }));
    }
    
    const handleSend = async () => {
        if(!validateTextFields() && next === 2) {
            try {
                setLoading(true);
                const response = await CreateReviewUknow(review);
                // console.log("🚀 ~ file: ModalReview.tsx:236 ~ handleSend ~ review:", review)
                setLoading(false);
                toast.success('Reseña enviada con éxito');
                setShowModal(false);
                navigate('/', {replace: true});
            } catch (error) {
                console.log("🚀 ~ file: ModalReview.tsx:250 ~ handleSend ~ error:", error)
                setLoading(false);
                toast.error('hubo un error al enviar la reseña, intentelo en unos minutos');
            }

        }
    }

    // declaration of change phase next
    const handlePhaseNext = () => {
        if(!validateTextFields() && next < 2) {
            setNext((prev) => prev + 1);
        }
    }

    // declaration of change phase prev
    const handlePhasePrev = () => {
        if(next>0) {
            setNext((prev) => prev -1);
        } 
    }

    // function to call service
    const getService = async (serviceId: string) => {
       if(serviceId.length>0) {
            try {
                setLoading(true);
                const response:any = await GetServiceDetail(serviceId);
                setService(response);     
                setReview((prev) => ({
                    ...prev,
                    serviceId: response.id,
                    companyId: response.companyId,
                }));    
                setLoading(false);
            } catch ( error) {
                console.log(error)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        if(isLoggin) {
            setReview((prev)=> ({
                ...prev,
                user: {
                    userId: userDetail?.id,
                    name: userDetail?.name,
                    lastName: userDetail?.lastName,
                    urlPhoto: userDetail?.photoURL || '',
                    business: userDetail?.business || '',
                    position: userDetail?.position || '',
                } 
            }));
        }
        setNext(isLoggin ? 2 : 1);
   
    }, [isLoggin, userDetail]);

    useEffect(()=>{
        showModal ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    },[showModal]);

    useEffect(() => {
        if(searchParams.get('showReview') && searchParams.get('showReview') === 'true' &&
        searchParams.get('service') && searchParams.get('service') !== '') {
            getService(searchParams.get('service') || '');
            setShowModal(true);
        }
    }, [searchParams]);

    return (
        <>
         { showModal && 
            <Modal 
                disableToast 
                setShow={setShowModal} 
                show={showModal} 
                className={"modalreview"}>
                    {!loading && service ? 
                    <>
                     <div className="modalreview-content">
                        <div className="modalreview-detail">
                            {next===1 && <div className="paragraph modalreview-title">Sube una foto</div>}
                            <div className="modalreview-profile">
                                { next===1 ? 
                                    <UserActive 
                                        userId={''}
                                        userImg={review.user.urlPhoto.length>0 ? review.user.urlPhoto : 'https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg'}
                                        isConnected={false}
                                        name={''}
                                        lastname={''}
                                        loadUser={()=>{}} 
                                        edit={next === 1}
                                        onChangePhoto={handlePhoto}
                                        error={reviewServiceError.photoURL}
                                    /> : 
                                    <img 
                                        src={service?.companyLogo} 
                                        style={{width:"252.35px", height:"72px"}}
                                    />
                                }

                                { next === 2 &&
                                    <div className="modalreview-rating">
                                        <div className="paragraph-header modalreview-rating-title">
                                            ¿Qué te pareció el servicio de <span className="modalreview-rating-blue">@{service?.companyName}</span>?
                                        </div>
                                        <div className="modal-review-rating-content">
                                            <MedalRating 
                                                className="modalreview-img"
                                            />
                                            <div className="modal-review-ranting-slider">
                                                <RatingService
                                                    textInicial="Pésimo"
                                                    textFinal="Excelente"
                                                    value={review.rating}
                                                    setRating={handleRating}
                                                    error={reviewServiceError.rating}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            
                        </div>
    
                    
                        <div className="modalreview-detail">
                            <div className="paragraph modalreview-title">{next === 1 ? "Ingresa tus datos" : "Deja un comentario en el perfil de la agencia"}</div>
                            
                            { next === 2 ?
                                <div className="modalreview-detail__">
                                    <TextField 
                                        title= "El servicio me pareció..."
                                        value={review.comment}
                                        type='text'
                                        onChange={handleCommentChange} 
                                        placeholder="" 
                                        error={reviewServiceError?.commet}
                                        />
                                    <div className="smalltext modalreview--number"> {countWord} / 90 caracteres</div>
    
                                </div>
                                :
                                <>
                                    <div className="modalreview-detail-row">
                                        <TextField 
                                            title= "Nombre" 
                                            value={review.user.name}
                                            onChange={(e:any) =>handleName(e.target.value)} 
                                            placeholder=" "
                                            error={reviewServiceError.name}
                                            />
    
                                        <TextField 
                                            title= "Apellido"
                                            value={review.user.lastName}
                                            onChange={(e:any) => handleLastName(e.target.value)} 
                                            placeholder=" " 
                                            error={reviewServiceError.lastName}
                                            />
                                    </div>
    
                                    <div className="modalreview-detail-row">
                                        <TextField 
                                            title= "Empresa"
                                            value={review.user.business}
                                            onChange={(e:any) => handleBusiness(e.target.value)} 
                                            error={reviewServiceError.business}
                                            placeholder=" " />
    
                                        <TextField 
                                            title= "Cargo"
                                            value={review.user.position}
                                            onChange={(e:any) => handlePosition(e.target.value)} 
                                            error={reviewServiceError.position}
                                            placeholder=" " />
                                    </div>
                                </>
                            }
                        </div> 
                    </div>
    
                    <div className={!isLoggin && next=== 2 ? "modalreview-footer-two" : "modalreview-footer"}>
                        {!isLoggin && next === 2 &&  <Button content="Atrás" color="black-25" onClick={()=> handlePhasePrev()} />}
                        <Button content={next === 1 ? "Siguiente" : "Enviar"} onClick={()=>{next === 1 ? handlePhaseNext() : handleSend()}}/>
                    </div>
                    </>
                        : 
                    <Loader/>
                    }
            </Modal>
         }
        </>
    )
}