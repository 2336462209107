import { collection, getDocs, query, where } from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";

// obtiene serviciosKeyboard  activos de la base de datos
export const GetAllServicesKeyboardsActive = async() => {
    const q = query(collection(DB, "serviceKeywords"), where("status", "==", true));
    const response = await getDocs(q);
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}