import React, { useCallback, useEffect, useRef, useState } from "react";

/** import componentes */
import { CustomsRegime } from "../CustomsRegime/CustomsRegime";
import { MerchandiseValue } from "../MerchandiseValue/MerchandiseValue";
import { Insurance } from "../Insurance/Insurance";
import { QuotationPermitsComponent } from "../QuotationPermitsComponent/QuotationPermitsComponent";
import { Onboarding } from "../Onboarding/Onboarding";

/** importando estilos  */
import './QuoteCustoms.css';
import { CustomsError } from "../../interfaces/CustomsError";

import { CustomsInterface } from "../../interfaces/CustomsInterface";
import { RequestQuoteInterface } from "../../interfaces/RequestQuote";
import StoreService from "../../Services/Store.service";
import { toast } from "react-toastify";
import { UpdateRequestQuote } from "../../Services/RequestQuote.service";
import debounce from "lodash.debounce";
import { TypeTransport } from "../../@types/TypeTransport";
import { Billing } from "../Billing/Billing";

interface QuoteCustomsProps {
    requestQuoteId: string;
    customs: CustomsInterface;
    role?: string;
    error?: CustomsError;
    setRequestQuote: React.Dispatch<React.SetStateAction<RequestQuoteInterface>>;
    typeTransport: TypeTransport;
    serviceTypeId: string;
}

export const QuoteCustoms: React.FC<QuoteCustomsProps> = ({
    requestQuoteId,
    customs,
    setRequestQuote,
    role,
    error={
        regime: {
            status: false,
            message: ''
        },
        incoterm: {
            status: false,
            message: ''
        },
        value: {
            status: false,
            message: ''
        },
        coin: {
            status: false,
            message: ''
        },
        insurance: {
            status: false,
            message: ''
        },
        purchaseLink: {
            status: false,
            message: ''
        },
    },
    typeTransport,
    serviceTypeId
}) => {

    // ref from parent div
    const refDiv = useRef<HTMLDivElement>(null);

    // handle value change
    const handleMerchandisevalueChange = (value: string) => {
        setRequestQuote((prev)=> ({
            ...prev,
            customs: {
                ...prev.customs,
                value: Number(value)
            }
        }));
    }

    // handle coin change
    const handleCoinChange = (value: string) => {
        setRequestQuote((prev)=> ({
            ...prev,
            customs: {
                ...prev.customs,
                coin: value
            }
        }));
    }

    // handle incoterm change
    const handleChangeIncoterm = (value: string) => {
        setRequestQuote((prev)=> ({
            ...prev,
            customs: {
                ...prev.customs,
                incoterm: value
            }
        }));
    }

    // handle proforma upload 
    const handleProformaChange = async (e: any) => {
       if(e.target.files[0]) {
         try {
           const responseFile = await StoreService.UploadFile(e.target.files[0], requestQuoteId+`/requestQuote/customs/proforma/`+e.target.files[0].name);
           setRequestQuote((prev: any) => ({
               ...prev,
               customs: {
                   ...prev.customs,
                   merchandisePermits: {
                       ...prev.customs?.merchandisePermits,
                       proforma: [
                           ...prev.customs?.merchandisePermits?.proforma,
                           {
                               name: e.target.files[0].name,
                               url: responseFile,
                             },
                       ]
                   }
               }
           }));
         } catch (error) {
           console.log("🚀 ~ file: QuotationPermitsComponent.tsx:56 ~ handleProformaChange ~ error", error);
           toast.error("Hubo un error al subir el archivo, intentelo en unos minutos");
          
         }
       }

    }

    // handle proforma delete
    const handleDeleteProforma = async (idx: number) => {
        if(customs?.merchandisePermits) {
            try {
                let tmpMerchandisePermits:any = {...customs?.merchandisePermits,
                    proforma: customs?.merchandisePermits?.proforma.filter((_, i) => i !== idx)
                };
                setRequestQuote((prev: any)=>({
                    ...prev,
                    customs: {
                        ...prev.customs,
                        merchandisePermits: tmpMerchandisePermits,
                    }
                }));
                const response = await UpdateRequestQuote({
                    id: requestQuoteId,
                    customs: {
                        ...customs,
                        merchandisePermits: tmpMerchandisePermits,
                    }});
                // console.log("🚀 ~ file: QuoteCustoms.tsx:168 ~ handleDeleteProforma ~ response:", response)
               
            } catch (error) {
                console.log("🚀 ~ file: QuoteCustoms.tsx:171 ~ handleDeleteProforma ~ error:", error)

            }
        }
 
    }

    // handle dataSheet upload
    const handleDataSheetChange = async (e: any) => {
        if(e.target.files[0]) {
            try {
                const responseFile = await StoreService.UploadFile(e.target.files[0], requestQuoteId+`/requestQuote/customs/dataSheet/`+e.target.files[0].name);
                setRequestQuote((prev: any) => ({
                    ...prev,
                    customs: {
                        ...prev.customs,
                        merchandisePermits: {
                            ...prev.customs?.merchandisePermits,
                            dataSheet: [
                                ...prev.customs?.merchandisePermits?.dataSheet,
                                {
                                    name: e.target.files[0].name,
                                    url: responseFile,
                                    },
                            ]
                        }
                    }
                }));
            } catch (error) {
                console.log("🚀 ~ file: QuoteCustoms.tsx:200 ~ handleDataSheetChange ~ error:", error)
                toast.error("Hubo un error al subir el archivo, intentelo en unos minutos");
           }
       }

    }

    // handle delete dataSheet
    const handleDeleteDataSheet = async (idx: number) => {
        if(customs.merchandisePermits) {
            try {
                let tmpMerchandisePermits:any = {...customs.merchandisePermits,
                    dataSheet: customs.merchandisePermits?.dataSheet.filter((_, i) => i !== idx)
                };
                setRequestQuote((prev: any)=>({
                    ...prev,
                    customs: {
                        ...prev.customs,
                        merchandisePermits: tmpMerchandisePermits,
                    }
                }));
                const response = await UpdateRequestQuote({
                    id: requestQuoteId,
                    customs: {
                        ...customs,
                        merchandisePermits: tmpMerchandisePermits,
                    }});
            } catch (error) {
                console.log("🚀 ~ file: QuoteCustoms.tsx:230 ~ handleDeleteDataSheet ~ error:", error)
            }
        }
 
    }

    // handle packing list upload
    const handlePackingListChange = async (e: any) => {

        if(e.target.files[0]) {
            try {
                const responseFile = await StoreService.UploadFile(e.target.files[0], requestQuoteId+`/requestQuote/customs/packingList/`+e.target.files[0].name);
                setRequestQuote((prev: any) => ({
                    ...prev,
                    customs: {
                        ...prev.customs,
                        merchandisePermits: {
                            ...prev.customs?.merchandisePermits,
                            packingList: [
                                ...prev.customs?.merchandisePermits?.packingList,
                                {
                                    name: e.target.files[0].name,
                                    url: responseFile,
                                    },
                            ]
                        }
                    }
                }));
            } catch (error) {
                console.log("🚀 ~ file: QuoteCustoms.tsx:200 ~ handleDataSheetChange ~ error:", error)
                toast.error("Hubo un error al subir el archivo, intentelo en unos minutos");
            }
        }

    }

    // handle delete packing list
    const handlePackingListDelete = async (idx: number) => {
        if(customs.merchandisePermits) {
            try {
                let tmpMerchandisePermits:any = {...customs.merchandisePermits,
                    packingList: customs.merchandisePermits?.packingList.filter((_, i) => i !== idx)
                };
                setRequestQuote((prev: any)=>({
                    ...prev,
                    customs: {
                        ...prev.customs,
                        merchandisePermits: tmpMerchandisePermits,
                    }
                }));
                const response = await UpdateRequestQuote({
                    id: requestQuoteId,
                    customs: {
                        ...customs,
                        merchandisePermits: tmpMerchandisePermits,
                    }});
            } catch (error) {
                console.log("🚀 ~ file: QuoteCustoms.tsx:230 ~ handleDeleteDataSheet ~ error:", error)
            }
        }
    
    }

    // handle change description operation
    const handleDescriptionChange = (value: string) => {
        setRequestQuote((prev) => ({
            ...prev,
            customs: {
                ...prev.customs,
                descriptionOperation: value
            }
        }))
    }

    // handle purchase change
    const handlePurchaseChange = (value: string) => {
        setRequestQuote((prev)=> ({
            ...prev,
            customs: {
                ...prev.customs,
                purchaseLink: value
            }
        }));
    }

    return (
        <div ref={refDiv} className={`quoteCustoms-container quoteCustoms-container__scroll`} role={role}>
            {/* <CustomsRegime 
                value='Importación definitiva'
                setValue={setRegimeCustoms}
                elements={CustomsRegimeLabel}
                detail={detailRegime}
                disabled
                error={error.regime}
            /> */}
            {(serviceTypeId === 'agenciamiento-de-aduana' || serviceTypeId === 'courier') && 
                <>
                    <MerchandiseValue 
                        intercom={customs.incoterm}
                        setIntercom={(e: any)=>handleChangeIncoterm(e)}
                        merchandise={customs.value}
                        setMerchandise={(e: any)=>handleMerchandisevalueChange(e)}
                        coin={customs.coin}
                        setCoin={(e: any)=> handleCoinChange(e)}
                        purchaseLink={customs.purchaseLink}
                        setPurchaseLink={(e: any)=>handlePurchaseChange(e)}
                        error={{
                            intercom: error.incoterm,
                            value: error.value,
                            coin: error.coin,
                            purchaseLink: error.purchaseLink
                        }}
                        typeTransport={typeTransport}
                        serviceTypeId={serviceTypeId}
                        setRequestQuote={setRequestQuote}
                        customs={customs}
                        // exwDirection={customs.exwDirection}
                        // setExwDirection={(e: any)=>handleExwDirectionchange(e)}
                    />
                
                    <QuotationPermitsComponent 
                        proforma={customs.merchandisePermits?.proforma ? customs.merchandisePermits.proforma : []}
                        handleProformaChange={handleProformaChange}
                        handleDeleteProforma={handleDeleteProforma}
                        dataSheet={customs.merchandisePermits?.dataSheet ? customs.merchandisePermits.dataSheet : []}
                        handleDataSheetChange={handleDataSheetChange}
                        handleDeleteDataSheet={handleDeleteDataSheet}
                        packingList={customs.merchandisePermits?.packingList ? customs.merchandisePermits.packingList : []}
                        handlePackingListChange={handlePackingListChange}
                        handlePackingListDelete={handlePackingListDelete}     
                        descriptionOperation={customs.descriptionOperation}
                        setDescriptionOperation={handleDescriptionChange}   
                        serviceTypeId={serviceTypeId}
                    />
                </>
            }
        </div>
    )
}