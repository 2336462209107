import React, { useRef, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonIcon, Textarea } from "..";
import { ArrowDown } from "../../icons/outline/ArrowDown";
import { ArrowUp } from "../../icons/outline/ArrowUp";
import { CloudUp } from "../../icons/outline/CloudUp";
import { ProductList } from "../ProductList/ProductList";
import { UploadText } from "../UploadFile/UpdateText/UpdateText";

// importando constante 
import { DocsFileValidation, ImageFileValidation, PDFFileValidation, SpreadSheetFileValidation } from "../../Consts/FilesValidation";

// importando estilos
import "./QuotationPermitsComponent.css";
import { UploadFileArray } from "../UploadFile/UploadFileArray/UploadFileArray";
import { Loader } from "../Loader/Loader";
import { toast } from "react-toastify";
import StoreService from "../../Services/Store.service";
import { InfoCircle } from "../../icons/outline/InfoCircle";
import { Hover } from "../Hover/Hover";
import { InfoCircleGray } from "../../icons/outline/InfoCircleGray";
import { Tiktok } from "../../icons/outline/Tiktok";
import { Memo } from "../../icons/solid/Memo";
import { PaperClick } from "../../icons/solid/PaperClick";
import { HoverBody } from "../HoverBody/HoverBody";

interface QuotationPermitsComponentProps {
  proforma: {
    name: string;
    url: string;
  }[];
  handleProformaChange: (e: any)=>void;
  handleDeleteProforma: (idx: number) => void;
  dataSheet: {
    name: string;
    url: string;
  }[];
  handleDataSheetChange: (e: any)=>void;
  handleDeleteDataSheet: (idx: number)=>void;
  packingList: {
    name: string;
    url: string;
  }[];
  handlePackingListChange: (e: any)=>void;
  handlePackingListDelete: (idx: number)=>void;
  descriptionOperation: string
  setDescriptionOperation: (e: string) => void;
  serviceTypeId: string;
}

//Recibir como parametros el valor de archivo con su respectivo manejador
//Y el setter del arreglo de productos
export const QuotationPermitsComponent: React.FC<QuotationPermitsComponentProps> =({ 
  proforma=[],
  handleProformaChange,
  handleDataSheetChange,
  dataSheet=[],
  handleDeleteProforma,
  handleDeleteDataSheet,
  packingList=[],
  handlePackingListChange,
  handlePackingListDelete,
  descriptionOperation,
  setDescriptionOperation,
  serviceTypeId,
}) => {

  const refQuote = useRef<HTMLDivElement>(null);
  const refPacking = useRef<HTMLDivElement>(null);
  const refHelp = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const [isHoverQuote, setIsHoverQuote] = useState(false);
  const [isHoverPacking, setIsHoverPacking] = useState(false);
  const [isHoverHelp, setIsHoverHelp] = useState(false);
  // const [cancelMouseOut, setCancelMouseOut] = useState<boolean>(false);
  
  const [cancelMouseOutQuote, setCancelMouseOutQuote] = useState<boolean>(false);
  const [cancelMouseOutPacking, setCancelMouseOutPacking] = useState<boolean>(false);
  const [cancelMouseOuthelp, setCancelMouseOutHelp] = useState<boolean>(false);


  const [countWord, setCoundWord] = useState<number>(0);

  /** HandleQuote */
  const handleQuoteMouseOver = () => {
    setIsHoverQuote(true);
  };

  const handleQuoteMouseOut = () => {
    setIsHoverQuote(false);
  };

  /** Handle Packing */
  const handlePackingMouseOver = () => {
    setIsHoverPacking(true);
  };
  
  const handlehandlePackingMouseOut = () => {
    setIsHoverPacking(false);
  };

   /** Handle Help */
  const handleHelpMouseOver = () => {
    setIsHoverHelp(true);
  };
  
  const handleHelpMouseOut = () => {
    setIsHoverHelp(false);
  };

  const handleProforma = async (e:any) => {
    setLoading(true);
    await handleProformaChange(e);
    setLoading(false);
  }

  const handlePackingList = async (e:any) => {
    setLoading(true);
    await handlePackingListChange(e);
    setLoading(false);
  }


  const handleDataSheet = async (e:any) => {
    setLoading(true);
    await handleDataSheetChange(e);
    setLoading(false);
  }

  const handleNoteChange = (e:any) => {
    let value = e.target.innerText;
    if(value.length<=150) {
      setCoundWord(value.length);
    } 
  }



  const handleClickQuote = () => {
    setCancelMouseOutQuote(true);
    setIsHoverQuote(true);
  }

  const handleClickPacking = () => {
    setCancelMouseOutPacking(true);
    setIsHoverPacking(true);
  }

  const handleClickHelp = () => {
    setCancelMouseOutHelp(true);
    setIsHoverHelp(true);
  }

  const handleCloseAllHover = () => {
    setCancelMouseOutQuote(false);
    setIsHoverQuote(false);

    setCancelMouseOutPacking(false);
    setIsHoverPacking(false);

    setCancelMouseOutHelp(false);
    setIsHoverHelp(false);
  }

  useEffect(() => {
    setCoundWord(descriptionOperation.length);
  }, [])

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if(cancelMouseOutQuote
          && refQuote.current && !refQuote.current.contains(e.target)) {
            handleCloseAllHover();
            
          }
        }

    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  
  }, [cancelMouseOutQuote]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if(cancelMouseOutPacking
          && refPacking.current && !refPacking.current.contains(e.target)) {
            handleCloseAllHover();
            
          }
        }

    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  
  }, [cancelMouseOutPacking]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if(cancelMouseOuthelp
          && refHelp.current && !refHelp.current.contains(e.target)) {
            handleCloseAllHover();
            
          }
        }

    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  
  }, [cancelMouseOuthelp]);

  return (
    <div role='quotationPermitsComponentRole' className="quotationPermitsComponent">   
      <div className="quotationPermitsComponent-title paragraph-header">
        <PaperClick/>
        Documentos <span className="quotationPermitsComponent-title--span">(Opcional)</span></div>
        <div className="quotationPermitsComponent-container">
        {!loading ? <>
          <div className="quotationPermitsComponent-attach">
          {/* <ProductList products={productDetails} setProductDetails={setProductDetails} /> */}
            <div ref={refQuote} className="quotationPermitsComponent-attach-row">
              <Hover
                title="Proforma del proveedor"
                content={
                    <HoverBody
                      description="Es el documento que demuestra el valor de tu compra"
                      href="https://www.tiktok.com/@arkabia_oficial/video/7237902243545107718"
                    />
                  }
                show={isHoverQuote}
                setShow={setIsHoverQuote}
              >
                <InfoCircle 
                    className="insurance--icon"
                    onMouseOver={handleQuoteMouseOver} 
                    onMouseOut={!cancelMouseOutQuote ? handleQuoteMouseOut : ()=>{}}
                    onClick={()=> window.innerHeight>720 ? handleClickQuote() : {}}
                />
              </Hover>

              <div className="smalltext-header quotationPermitsComponent-colorText">Proforma o {`${serviceTypeId === 'courier' ? "factura" : "cotización"}`}</div>
            </div>
            <div className="quotationPermitsComponent-attach-container">
              <UploadFileArray file={proforma} buttonContent='Subir archivo' handleFileValue={handleProforma} title='' subtitle="" handleDelete={handleDeleteProforma} />
            </div>
        </div> 


       {serviceTypeId === 'agenciamiento-de-aduana' && <div  className="quotationPermitsComponent-attach">
          {/* <ProductList products={productDetails} setProductDetails={setProductDetails} /> */}

            <div ref={refPacking} className="quotationPermitsComponent-attach-row">
              <Hover
                title="Lista de empaque"
                content={
                  <HoverBody
                    description="Es el documento donde tu proveedor enlista todas las mercancías que te está vendiendo."
                    href="https://www.tiktok.com/@arkabia_oficial/video/7237902243545107718"
                  />
                }
                show={isHoverPacking}
                setShow={setIsHoverPacking}
              >
                <InfoCircle 
                    className="insurance--icon"
                    onMouseOver={handlePackingMouseOver} 
                    onMouseOut={!cancelMouseOutPacking ? handlehandlePackingMouseOut : ()=>{}}
                    onClick={()=>window.innerHeight>720 ? handleClickPacking() : {}}
                />
              </Hover>

              <div className="smalltext-header quotationPermitsComponent-colorText">Lista de empaque</div>
            </div>
            <div className="quotationPermitsComponent-attach-container">
              <UploadFileArray file={packingList} buttonContent='Subir archivo' handleFileValue={handlePackingList} title='' subtitle="" handleDelete={handlePackingListDelete} />
            </div>
        </div>}


        <div className="quotationPermitsComponent-attach">
          {/* <ProductList products={productDetails} setProductDetails={setProductDetails} /> */}
          <div ref={refHelp} className="quotationPermitsComponent-attach-row">

            <Hover
              title={`${serviceTypeId === 'courier' ? "Foto del producto" : "Ficha técnica"}`}
              content={
                <HoverBody
                  description="Es el documento  que describe las características principales de tus mercancías"
                  href="https://www.tiktok.com/@arkabia_oficial/video/7241957758382329093"
                />
              }
              show={isHoverHelp}
              setShow={setIsHoverHelp}
            >
              <InfoCircle 
                  className="insurance--icon"
                  onMouseOver={handleHelpMouseOver} 
                  onMouseOut={!cancelMouseOuthelp ? handleHelpMouseOut : ()=>{}}
                  onClick={()=> window.innerHeight>720 ? handleClickHelp() : {}}
              />
            </Hover>

            <div className="smalltext-header quotationPermitsComponent-colorText">{`${serviceTypeId === 'courier' ? "Foto del producto" : "Ficha técnica"}`}</div>
          </div>
          <div className="quotationPermitsComponent-attach-container">
            <UploadFileArray file={dataSheet} buttonContent='Subir archivo' handleFileValue={handleDataSheet} title='' subtitle=""  handleDelete={handleDeleteDataSheet}/>
          </div>
        </div>


        </>: <Loader  />}
        </div>
        <div className="quotationPermitsComponent-title paragraph-header">
          <Memo/>
          Mensaje
          <span className="quotationPermitsComponent-title--span">(Opcional)</span></div>
        <div className="quotationPermitsComponent-message">
          <div className="quotationPermitsComponent-message-note">
            <Textarea  
              title=""
              value={descriptionOperation} 
              onBlur={(e) => setDescriptionOperation(e.target.innerHTML)} 
              onKeyUp={(e)=>handleNoteChange(e)}
              />
            <div className="smalltext quoter-title--number"> {countWord} / 150 caracteres</div>
          </div>
          
          <div className="quotationPermitsComponent-note-list">
            <div className="paragraph-header">¿Qué datos podría pedirte la agencia?</div>
            <ul>
              <li className="paragraph">Nombre comercial de tus productos, cantidad y valor</li>
              <li className="paragraph">Partida arancelaria</li>
              <li className="paragraph">Servicio requerido</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
