import React, { useState, useEffect } from "react";

// import components
import { Dots } from "../../../icons/outline/Dots";
import { ConverMoneyFormat } from "../../../utils/ConvertDecimal";
import { Button } from "../../Button/Button";
import { Rating } from "../../Rating/Rating";

// import css
import './QuotationCard.css';

// props interfaces
interface QuotationCardProps {
    title: String; // title agency
    quotationDate: string, // date to quotation 
    subtitle: String, // tipe of service to quotation
    agency: { 
        id: string;
        companyName: string;
        serviceRating: number;
        serviceType: string;
        serviceTypeLabel: string;
        price: number;
        quotationId: string
    }[], // agency going to rendered
    add: boolean, // status add more quotations
    steps: boolean, // status tu show numero of steps
    step?: number; // number of step
    titleStep?: string; // title of step
    setAddCompany: React.Dispatch<React.SetStateAction<boolean>>; // set status add more company to quotation
    setCompare:React.Dispatch<React.SetStateAction<boolean>>; // set status compare quotations
    onClickCancel: () => void //
    handleSendClick: ()=> void; // metodo para enviar cotizacion
    handleQuotationClick: (e: number, rq: number) => void; // metodo para ver una cotizacion
    handleClickToken: () => void; // metodo que muestra el modal de los tokens
    handleClickCompare: () => void;
    setShowMinOptions: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveTab?: React.Dispatch<React.SetStateAction<number>>; // metodo para cambiar el esto del tab
    setIsActiveTab?: React.Dispatch<React.SetStateAction<boolean>>;
    courier?: boolean;
    showAddButtom?: boolean;
}


/** component Quoatation card
 * component to will be rendered ever quotation from campanys an will be compare it
 */
export const QuotationCard: React.FC<QuotationCardProps> = ( {
    title, 
    quotationDate,
    subtitle,
    agency,
    add,
    steps = true,
    step = 1,
    titleStep= 'Cotización',
    setAddCompany,
    setCompare,
    onClickCancel,
    handleSendClick,
    handleQuotationClick,
    handleClickToken,
    handleClickCompare,
    setActiveTab = () => {},
    setShowMinOptions,
    setIsActiveTab=()=>{},
    courier = false,
    showAddButtom = false,
    ...props
}) => {

    const [compareActive, setCompareActive] = useState(false);

    const handleAddAgencyClick = ()=> {
        setIsActiveTab(false);
        setActiveTab(1); 
        setAddCompany(true); 
        setShowMinOptions(true); 
    }

    useEffect(() => {
        let num: number = 0;
        agency.filter(a=> a.price ? num++ : 0);
        setCompareActive(num > 1);
    }, [agency]);

    return (
        <div className='quotationCard' role='quotation-card'> 
            <div className='quotationCard-container'>
                {/* <div className={'quotationCard-header'+(!steps?' quotationCard-bottom': '')}>
                    <div className='quotationCard-header-left'>
                        <h1 className='smalltext-header quotationCard-colorText' role='quotation-card-title'>{title}</h1>
                        <p className='smalltext quotationCard-colorText' role='quotation-card-subtitle'>{subtitle}</p>
                    </div>                    
                    <div className='quotationCard-header-rigth'>
                        <p className='tinytext quotationCard-colorText' role='quotation-card-date'>{quotationDate}</p>
                      
                    </div> 
                </div> */}
               {steps && <div className='quotationCard-body'>
                    <div className='quotationCard-number paragraph-header' role='quotation-card-step'>{step}</div>
                    <p className='small-bold quotationCard-colorText' role='quotation-card-title-step'>{titleStep}</p>
                </div>}
                <div className='quotation-company'>
                    <div className='company-row__ smalltext-header quotationCard-colorText company-header-values'>
                        <div className='smalltext-header company-row-items_' style={{width: "24rem"}} >{courier ? "Courier" : "Agencia"}</div>
                        <div className='smalltext-header company-row-items_' style={{width: "15rem"}}>Calificación</div>
                        <div className='smalltext-header company-row-items_'>Presupuesto</div>
                    </div>
                    {agency.length>0 && agency.map((a:any) => {
                        return ( 
                            //onClick={()=> a.price>0 ? handleQuotationClick(a.quotationId, a.id): {}}
                            <div key={a.id} onClick={()=>handleQuotationClick(a.quotationId, a.id)} className='company-row company-row-values smalltext quotationCard-colorText' role='btn-agency-quotation-card'>
                                <div className='smalltext company-row-items' style={{width: "24rem"}} >{a.companyName}</div> 
                                <div className='company-row-items company-row-items-rating' style={{width: "15rem"}}> <div className='company-row-items-rantin'>
                                    <Rating rating={(a.serviceRating).toFixed(1)}/> </div></div>
                                <div className='smalltext company-row-items company-row-items-rating'> {!a.active ? a.observation : (a.price === 0 || a.price === undefined ? 'En Proceso': "USD $ "+ConverMoneyFormat(a.price.toString()))}</div>
                            </div>
                        )
                    }) }
                </div>
                <div className='quotationCard-num paragraph' style={{marginTop: '20px'}} >
                       <span className="ordersQuotationPage-btn--number ">{agency.length}</span> de <span className="ordersQuotationPage-btn--number">3</span> seleccionados
                    </div>
                <div className='quotationCard-buttons paragraph-header' >
                    { showAddButtom && <>
                        {!add ? agency.length<3 && 
                            <Button
                                content={courier ? 'Agregar a más couriers' : 'Agregar a más agencias de aduana' }
                                disabled={false}
                                onClick={()=>handleAddAgencyClick()}
                                role='btn-add-quotation-card'
                            /> : <div className='quotationCard-btns'>
                                <Button
                                    content='Cancelar'
                                    color='black-25' 
                                    size='large'
                                    disabled={false}
                                    onClick={onClickCancel}
                                    role='btn-cancel-quotation-card' />
                                <Button
                                    content='Enviar' 
                                    disabled={false}
                                    color='blue-2'
                                    size='large'
                                    onClick={() => {handleSendClick();}}
                                    role='btn-send-quotation-card'/>
                            </div>
                        }
                    </>}
                   
                    {/* { agency.length===3 && !compare?<div className='quotationCard-more'>
                            <Button
                            content='¿Más Tokens?'
                            color='yellow'
                            onClick={handleClickToken}
                            role='btn-more-token-quotation-card'/>
                        <div className='quotationCard-num tinytext-header'>
                            ({agency.length} de 3 Cotización disponibles)
                        </div>
                    </div>:null} */}
                    {compareActive && <div className='comapare-quotation-action'>
                        <Button
                            content='Comparar cotizaciones'
                            color='black-75'
                            onClick={()=> {handleClickCompare()}}
                            role='btn-compare-quotation-card'/>
                        {/*<Button
                            content='¿Más Tokens?'
                            color='yellow'
                            onClick={handleClickToken}
                role='btn-more-token-quotation-card-1'/>*/} 
                    </div>}
                </div>
            </div>
        </div>
    )
}
