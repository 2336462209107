import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { DB } from "../firebaseConfig/FirebaseConfig";
import { PlanInterface } from "../interfaces/PlansInterface";

export const GetPlansArkabia = async(): Promise<PlanInterface[]> => {
    const q = query(collection(DB, "plan"), orderBy("priceUsd", "asc"));
    const response = await getDocs(q);
    let data:PlanInterface[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()} as PlanInterface))
    }
    return data;
}

// method to get the plan by id
export const GetPlanById = async(id:string): Promise<PlanInterface> => {
    const response = await getDoc(doc(DB, 'plan', id));
    return new Promise((resolve, reject) => {
        if(response.data()) {
            const data: PlanInterface = response.data() as PlanInterface;
            resolve(data);
        } else {
            reject(new ErrorEvent('not-fount', {message: 'not-fount'}))
        }
    });
}