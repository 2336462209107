import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type AttachedProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Attached: React.FC<AttachedProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src={STORAGE_ASSETS+'/images/svg/attach.svg'} alt='Attached' />
        </div>
    )
}