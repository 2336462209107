import React, { useState } from "react";
import { GetCurrency } from "../../../utils/GetCurrency";

/** Importando estilos */
import './BodyElementsMobile.css';
import { STORAGE_ASSETS } from "../../../Consts/BaseUrl";

interface BodyElementsMobileProps { 
    handleClick: () => void;
    msg: string,
    nrOrder: string | number,
    price: number,
    currency: string;
    transport: string;
    rating: string;
    quote_?: boolean;
    agencia?: string
    alert ?: boolean
}
export const BodyElementsMobile: React.FC<BodyElementsMobileProps> = ({
    handleClick,
    msg,
    nrOrder,
    price,
    currency,
    transport,
    rating,
    quote_=false,
    agencia,
    alert = false
}) => {

    return (
        <div role='bodyElementsMobileRole' className="bodyElements-mobile" onClick={handleClick}>
            <div className="bodyElements-mobile-content-msg">
                <div className="smalltext-header bodyElements-mobile-title">Último mensaje</div>
                <div className="bodyElements-mobile-content-title">
                    <div className="smalltext bodyElements-mobile-subtitle">
                        {msg}
                    </div>
                    { alert ? <div className="bodyElements-mobile-alert"></div> : null}
                </div>
             </div>
            <div className="bodyElements-mobile-content-table">
                {quote_ ?<div className="bodyElements-mobile-content-order">
                    <div className="smalltext-header bodyElements-mobile-title">Órden</div>
                    <div className="smalltext bodyElements-mobile-subtitle">{'#'+nrOrder}</div>
                </div>: 
                <div className="bodyElements-mobile-content-order">
                    <div className="smalltext-header bodyElements-mobile-title">Calificación</div>
                    <div className="bodyElements-mobile-subtitle-content">
                        <img src={STORAGE_ASSETS+'/images/svg/medalbackgroundYellow.svg'}/>
                        <div className="smalltext bodyElements-mobile-subtitle">{rating}</div>
                    </div>
                </div>}
                <div className="bodyElements-mobile-content-price">
                    <div className="smalltext-header bodyElements-mobile-title">Presupuesto</div>
                    <div className="smalltext bodyElements-mobile-subtitle">{GetCurrency(currency)+' '+price}</div>
                </div>
            </div>
        </div>
    )
}