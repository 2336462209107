import React, { useState } from 'react';

import './SixSeccion.css';
import { STORAGE_ASSETS } from '../../../Consts/BaseUrl';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import GaleryScroll from '../../GaleryScroll/GaleryScroll';

interface SixSeccionProps {
}

export const SixSeccion: React.FC<SixSeccionProps> = ({

}) => {

    return (
        <div className="six-section">
            <div className="six-section-header">
                <div className='six-section-text'>
                    Nuestros usuarios  
                    <b className='six-section-text-grandient'> aman </b>
                    Arkabia
                </div>
            </div>

            <div className='six-seccion-history'>
                <div className='six-section-history-left'>
                    <GaleryScroll 
                        images={[
                            {
                                href:'https://www.instagram.com/reel/C_UQ8SWxgX0/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
                                src: STORAGE_ASSETS+'/images/png/six-01.webp'
                            },
                            {
                                href:'https://www.instagram.com/reel/C8UtCcrx0XG/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
                                src: STORAGE_ASSETS+'/images/png/six-02.webp'
                            },
                            {
                                href:'https://www.instagram.com/reel/CzUIuNeAEg7/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
                                src: STORAGE_ASSETS+'/images/png/six-03.webp'
                            },
                        ]}
                    />
                </div>
                <div className='six-section-history-right'>
                    <div className='big-header six-section-title'>
                        Estadísticas de éxito con Arkabia
                    </div>
                    <div className='six-section-history-right_row'>
                        <div className='six-section-progress'>
                            <ProgressCircle 
                                strokeWidth={8}
                                sqSize={70}
                                percentage={90}
                                textPercentage={96}/>
                        </div>
                        <div className='paragraph six-section-white'>Dijeron que sus costos de importación bajaron</div>
                    </div>
                    <div className='six-section-history-right_row'>
                        <div className='six-section-progress'>
                            <ProgressCircle 
                                strokeWidth={8}
                                sqSize={70}
                                percentage={88}
                                textPercentage={94}/>
                        </div>
                        <div className='paragraph six-section-white'>Notaron que su nivel de estrés se redujo</div>
                    </div>
                    <div className='six-section-history-right_row'>
                        <div className='six-section-progress'>
                            <ProgressCircle 
                                strokeWidth={8}
                                sqSize={70}
                                percentage={86}
                                textPercentage={90}/>
                        </div>
                        <div className='paragraph six-section-white'>Vieron un aumento en la rentabilidad de su negocio</div>
                    </div>
                    <div className='tinytext six-section-white'>
                        * Resultados obtenidos según usuarios de Arkabia. 
                        Para obtener mayor información ver reseñas.
                    </div>
                    <div className='smalltext six-section-white six-section-review'>
                        <div className='six-section-review-start'>
                            {[...Array(5)].map((s, idx) =>
                                <div key={idx} className='six-section-start'></div>
                            )}
                        </div>
                        <div>
                            <span className='smalltext-header six-section-white'>4.9/5 </span> 
                            basado en 
                            <span className='smalltext-headersix-section-white '> 362 </span> 
                            reseñas
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
};