import React from "react";
import { STORAGE_ASSETS } from "../../Consts/BaseUrl";

type EmojiProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Emoji: React.FC<EmojiProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src={STORAGE_ASSETS+"/images/svg/emoji.svg"} alt="" />
    </div>
  );
};
