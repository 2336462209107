import React, {useEffect, useRef, useState} from "react";

import './FrequentlyQuestions.css';
import { FaqCard } from "../../../components/FaqCard/FaqCard";
import { Questions } from "../../../Consts/Questions";
import { GroupWhatsapp } from "../../../icons/solid/Group_whatsapp";

export const FrequentlyQuestions: React.FC<{}> = () => {
    
    const [activeIndex, setActiveIndex] = useState(0); // Inicializa con el primer elemento activo

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="frequentlyQuestions">
            <div className="frequentlyQuestions-text frequentlyQuestions-text-white">Preguntas Frecuentes</div>
            <div className="frequentlyQuestions-faq">
                {Questions.map((q, idx) => (
                    <FaqCard 
                        key={idx} 
                        question={q} 
                        index={idx}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                    />
                ))}
            </div>
            <div className="frequentlyQuestions-card">
                <div className="frequentlyQuestions-card-text">
                    <GroupWhatsapp/>
                    <div className="paragraph frequentlyQuestions-text-white">
                        <span className="frequentlyQuestions-text-white paragraph-header">¿Tienes alguna pregunta? </span> 
                        Contacta a nuestro equipo para ayudarte
                    </div>
                </div>
                {/* <div className="frequentlyQuestions-card-button">
                    <ButtonIcon 
                        onClick={()=>{}} 
                        content="Tengo una pregunta" 
                        place="left" 
                        Icon={ <WhatsappCircle/>} 
                        color={'black-75'} />
                </div> */}
            </div>
        </div>
    )
}