import React, { useState } from "react";

// importando componentes
import { TextField } from "../..";
import { Modal } from "../../Modal/Modal/Modal";
import { Dropdown } from "../..";
import { Button } from "../..";
import { FooterSignUp } from "../FooterSignUp";
import { TextFieldPassword } from "../../TextField/TextFieldPassword/TextFieldPassword";

// importando interfaces
import { User } from "../../../interfaces/User";
import { RegisterFullData } from "../../../interfaces/RegisterFullData";

// importando css
import './SignUpFullData.css';
import { TypeDocuments } from "../../../Consts/TypeDocuments";
import { ItemVessels } from "../../SearchListVessels/ItemVessels/ItemVessels";
import { PhoneCountry } from "../../PhoneCountry/PhoneCountry";
import { Checkbox } from "../../Checkbox/Checkbox";
import { GobackButton } from "../../Button/GobackButton/GobackButton";
interface SignUpFullDataProps {
    show: boolean;
    setShow: (e: boolean) => void;
    signUp: User;
    password: string;
    setSignUp: React.Dispatch<React.SetStateAction<User>>;
    handleSignUp?: ()=>void;
    errorFullRegister?: RegisterFullData;
    setErrorFullRegister?:  React.Dispatch<React.SetStateAction<RegisterFullData>>;
    terms?: () => void;
    handleLogin?: () => void;
    isProvider: boolean;
    countryList? : {country: string; alpha2Code: string, phoneCode: string }[];
    GoBack?: () => void;
}

export const SignUpFullData: React.FC<SignUpFullDataProps> = ({
    show,
    setShow,
    signUp,
    password,
    setSignUp,
    handleSignUp,
    errorFullRegister,
    setErrorFullRegister,
    handleLogin=()=>{},
    terms=()=>{},
    isProvider,
    countryList=[],
    GoBack
}) => {

    const [isCompany, setIsCompany] = useState<boolean>(false);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSignUp((prev:any) => {
            return {
                ...prev,
                name: e.target.value
            }
        })
    }

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSignUp((prev:any) => {
            return {
                ...prev,
                lastName: e.target.value
            }
        })
    }

    const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSignUp((prev:any) => {
            return {
                ...prev,
                userName: e.target.value
            }
        })
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSignUp((prev:any) => {
            return {
                ...prev,
                password: e.target.value
            }
        })
    }

    
    const handleCountryChange = (country: string, alpha2Code: string) => {
        setSignUp((prev:any) => {
            return {
                ...prev,
                country: {
                    alpha2Code: alpha2Code,
                    imgFlag: `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/flags/4x3/${alpha2Code}.svg`,
                    name: country,
                }
            }
        });
    }

    // const handleCountryChange = (value: string) => {
    //     setSignUp((prev:any) => {
    //         return {
    //             ...prev,
    //             country: value
    //         }
    //     });
    // }

    const handleTypeDocumentChange = (value: string) => {
        setSignUp((prev:any) => {
            return {
                ...prev,
                typeIt: value,
    
            }
        });
    }

    const handleNumberDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSignUp((prev:any) => {
            return {
                ...prev,
                itNumber: e.target.value
            }
        })
    }

    const handleBusinessNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSignUp((prev:any) => {
            return {
                ...prev,
                businessName: e.target.value
            }
        })
    }

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSignUp((prev: any) => {
            return {
                ...prev,
                phone: e.target.value
            }
        })
    }

    const handlePhoneCodeChange = (e: string) => {
        setSignUp((prev: any) => {
            return {
                ...prev,
                phoneCode: e
            }
        })
    }

    return (
        <Modal 
            title="Únete a Arkabia" 
            show={show} 
            setShow={setShow} 
            role='modal-signUpFullData' disableToast 
            icon={<div className="signUpFullData-goBack">
                    <GobackButton onClick={GoBack}/>
                  </div>}>
            <div className="signUpFullData-container">
                <TextField 
                    title='Nombre'
                    placeholder=" "
                    value={signUp.name}
                    onChange={(e:any) => {handleNameChange(e)}}
                    role='signUp-name'
                    error={errorFullRegister?.name}/>
                {/* <TextField 
                    title='Apellido'
                    placeholder=" "
                    value={signUp.lastName}
                    onChange={(e:any) => {handleLastNameChange(e)}}
                    role='signUp-last-name'
                    error={errorFullRegister?.lastName}/> */}
                {/* <TextField 
                    title='Elige un nombre de usuario'
                    placeholder=" "
                    value={signUp.userName}
                    onChange={(e:any) => {handleUserNameChange(e)}}
                    role='signUp-user'
                    error={errorFullRegister?.userName}/> */}
                {/* <Dropdown
                    elements={countryList.map(c => c.country+" "+ c.phoneCode).sort()}
                    setValue={()=>handleCountryChange}
                    title="País"
                    value={signUp.country.name}
                    error={errorFullRegister?.country}/>    */}
                <PhoneCountry 
                    phoneCode={signUp.phoneCode?signUp.phoneCode:''}
                    phoneFlag={signUp.phoneCodeFlag?signUp.phoneCodeFlag:''}
                    phone={signUp.phone?signUp.phone.toString():''}
                    countryList={countryList}
                    handleCountryChange={handleCountryChange} 
                    handlePhoneChange={handlePhoneChange}
                    // phoneCode={signUp.phoneCode ? signUp.phoneCode : ''}
                    handlePhoneCodeChange={handlePhoneCodeChange} 
                    title="País"
                />
               {!isProvider && <div className="signUpFullData-password">
                    <TextFieldPassword
                        title='Elige una contraseña'
                        placeholder=" "
                        type='password'
                        value={password}
                        onChange={(e:any) => {handlePasswordChange(e)}}
                        error={errorFullRegister?.password}
                    />
                    <div className="paragraph" style={{color: 'var(--gray-3)'}}>6 caracteres mínimo, incluyendo números y letras.</div>
                </div>}
                {/* <Dropdown
                    elements={['Perú']}
                    setValue={(e:any)=>handleCountryChange(e)}
                    title="Elige tu país"
                    value={signUp.country.name}
                    error={errorFullRegister?.country}/>
                 */}
                <div className="paragraph-header signUpFullData-row">Crea cuenta de empresa <Checkbox checked={isCompany} setChecked={()=>{setIsCompany(!isCompany); handleTypeDocumentChange(!isCompany?'RUC': '')}} /></div>
                {/* <div className="paragraph-header">Empresa <span style={{color: 'var(--gray-3)'}}>(Opcional)</span></div> */}
                { isCompany && <>
                    {/* <Dropdown
                        elements={['RUC']}
                        setValue={(e:any)=>handleTypeDocumentChange(e)}
                        title="Identificación fiscal"
                        value={signUp.typeIt?signUp.typeIt:'RUC'}
                        error={errorFullRegister?.typeIt}/> */}
                    <TextField 
                        title={'Número de '+(signUp.typeIt)}
                        placeholder=" "
                        value={signUp.itNumber}
                        onChange={(e:any) => {handleNumberDocumentChange(e)}}
                        role='signUp-user'
                        type={ signUp.typeIt === 'RUC' || signUp.typeIt === "DNI" ? 'number': 'text'}
                        error={errorFullRegister?.itNumber}/>
                    {signUp.typeIt === 'RUC' && <TextField 
                        title='Razón social'
                        placeholder=" "
                        value={signUp.businessName}
                        onChange={(e:any) => {handleBusinessNameChange(e)}}
                        role='signUp-user'
                        error={errorFullRegister?.businessName}/>}
                </>}
                <Button 
                    content="Registrarme"
                    size="large"
                    color='black-75'
                    onClick={handleSignUp}
                    role='btn-signUp'/>
                <FooterSignUp 
                    role="footer-signUp"
                    handleLogin={()=>handleLogin()}
                    terms={()=>terms()}/>
            </div>
        </Modal>
    )
}