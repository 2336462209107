import React, {useState} from "react";

/** import components */
import { ServiceOptiosTypes,  } from "../../../@types/serviceOptionsTypes";
import { SearchTextField } from "../../SearchTextField/SearchTextField";
import { Services } from "../Services";
import { Dropdown } from "../../Dropdown/Dropdown";

import { ServiceTypeInterface } from "../../../interfaces/Service"

/** import style */
import './ServicesOptions.css';

/** interfaces props */
interface ServicesOptionsProps {
    servicesOptions: ServiceTypeInterface[]; // array of services will be rendered
    ServicesOptionsDataDropdown: string[];
    mobile?: boolean; // modo responsibe;
    serviceTypeActive?: string,
    setTextSearch?: React.Dispatch<React.SetStateAction<string>>;
    onClick: (e:any)=>void;
    title: string;
    userName?: string;
}

// Component to rendered ServiceOptions block for desktop an mobile 
export const ServicesOptions: React.FC<ServicesOptionsProps> = ({
    servicesOptions,
    ServicesOptionsDataDropdown,
    mobile=false,
    serviceTypeActive = "agenciamiento-de-aduana",
    setTextSearch=()=>{},
    onClick= ()=>{},
    title,
    userName=''
}) => {

    return (
        <div role='servicesOptionsRole' className='servicesOptions-main'>
            {/* <SearchTextField 
                setTextSearch={(e)=> setTextSearch(e)}
                title={title}
                darkIcon
            /> */}
            <div className='servicesOptions-title small-bold'>
                {`${userName}` + ' ' + '¿Qué necesitas para tu importación?'}
            </div>
            <div className='servicesOptions-text'>
                
                {/* <div className='servicesOptions-description smalltext-header'>
                    Encuentra a todo el equipo que necesitas para importar un producto.
                </div> */}
                { !mobile ? servicesOptions.map((s:any, idx:number) => {
                        return (
                            <Services 
                                active={s.id === serviceTypeActive}
                                key={idx}
                                name={s.name}
                                urlIcon={s.urlIcon}
                                // onClickService={()=>onClick(s.id)}
                                onClickService={()=>onClick(s.id)}
                                hover={s.hover}/>
                        )
                    }): '' 
                    // <Dropdown elements={ServicesOptionsDataDropdown}
                    //           setValue={()=> {}}
                    //           title={'¿Qué servicio desea?'}
                    //           value={'Agenciamiento de aduana'}
                    //           iconActive={true}
                    //           disabled={false}/>
                 }
                
            </div> 
            
        </div>
    )
}

